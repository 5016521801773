import path_to_whatsapp_icon from "../assets/images/path_to_whatsapp_icon.png";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const WhatsAppIcon = () => {
  const phoneNumber = "9904336000"; 

  const handleWhatsAppClick = () => {
    const link = `https://wa.me/${phoneNumber}`;
    window.open(link, "_blank");
  };

  return (
    <div className="whatsapp-icon">
      <a onClick={handleWhatsAppClick}>
        <img
          style={{ height: "3rem", width: "3rem" }}
          src={path_to_whatsapp_icon}
          alt="WhatsApp Icon"
        />
      </a>
    </div>
  );
};

export default WhatsAppIcon;
