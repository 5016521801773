import React, { useState, useEffect } from "react";
import api from "../../../../api";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { fontGrid } from "@mui/material/styles/cssUtils";
import apiRequest from "../../../../apiCall";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/slices";

const ProductDetailsForm = ({ autoComplete, onValueChange }) => {
  const storedData = localStorage.getItem("adminData") || " {}";
  const adminData = JSON.parse(storedData);
  const AdminId = adminData._id || null;

  const [data, setData] = useState({
    content: "",
  });

  const _data = data;

  const updateData = () => {
    setData((data) => ({
      ...data,
      ..._data,
    }));
  };

  useEffect(() => {
    if (autoComplete) {
      const secBData = JSON.parse(sessionStorage.getItem("shopApi")).sectionB;
      _data.content = secBData;
      updateData();
    } else {
      _data.content = "";
      updateData();
    }
  }, [autoComplete]);

  const dispatch = useDispatch();
  const handleSave = async () => {
    dispatch(setLoading(true));
    // Create a new FormData object to store the data and image
    const params = {
      itemId: JSON.parse(sessionStorage.getItem("shopApi")).itemId,
      productCode: JSON.parse(sessionStorage.getItem("shopApi")).productCode,
      content: _data.content,
      AdminId: AdminId,
    };
    if (_data.content == "") {
      dispatch(setLoading(false));
      window.M.toast({ html: "Please fill the content" });
    } else {
      apiRequest("POST", "shop/sectionB", params, true, true).then((res) => {
        dispatch(setLoading(false));
        window.M.toast({ html: "Data saved successfully" });
        onValueChange(
          JSON.parse(sessionStorage.getItem("shopApi")).productCode
        );
      });
    }
  };
  return (
    <div className="sec" style={{ marginTop: "10vh" }}>
      <h2> Product Details</h2>
      <div className="input-group">
        <label htmlFor="content">Content:</label>
        <ReactQuill
          value={data.content}
          onChange={(value) => setData({ ...data, content: value })}
        />
      </div>

      <button className="save btn" onClick={handleSave}>
        Save
      </button>
    </div>
  );
};

export default ProductDetailsForm;
