import React, { useEffect, useState } from "react";

import SideBar from "../components/sideBar";
import callCreateUserApi, { AdmincreateUser, AdminupdateUser } from "../callApi/createUser";
import { useNavigate, useParams } from "react-router-dom";
import { encrypt } from "../crypto";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices";


const CreateUser = () => {

  const storedData = localStorage.getItem("adminData") || " {}";
  const adminData = JSON.parse(storedData);
  const AdminId = adminData._id || null;

  const { id } = useParams();
  const navigate = useNavigate();

  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);
  const [role, setRole] = useState(null);
  const dispatch = useDispatch();
  const handleSave = async () => {
    if (firstname && lastname && username && password && role) {
      let data = null;

      if (!id) {
        try {
          data = AdmincreateUser({
            firstname,
            lastname,
            username,
            password: encrypt(password),
            role,
            CreatedBy: AdminId
          });
          // console.log(data);
          data = data.data;
        } catch (error) {
          console.log("error creating user", error);
        }
      }
      else {
        try {

          data = AdminupdateUser({
            firstname,
            lastname,
            username,
            password: encrypt(password),
            role,
            _id: id,
            CreatedBy: AdminId
          });
          // console.log(data);
          data = data.data;

        } catch (error) {
          console.log("error updating admin user", error);
        }
      }

      // if (!id) {
      //   data = await api.post("/create-user", {
      //     firstname,
      //     lastname,
      //     username,
      //     password:encrypt(password),
      //     role,
      //   });
      //   console.log(data);
      //   data = data.data;

      // } else {
      //   data = await api.post("/update-admin-user", {
      //     firstname,
      //     lastname,
      //     username,
      //     password:encrypt(password),
      //     role,
      //     _id: id,
      //   });
      //   console.log(data);
      //   data = data.data;

      // }
      if (id) {
        dispatch(setLoading(false));
        window.M.toast({ html: "User updated successfully" });
        navigate('/admin/admin-users');
        window.location.reload();
      } else {
        dispatch(setLoading(false));
        window.M.toast({ html: "User created successfully" });
        navigate('/admin/admin-users');
      }

    } else {
      window.M.toast({ html: "Please fill the all values" });
    }
  };


  useEffect(() => {

    if (id) {
      dispatch(setLoading(true));
      callCreateUserApi(id).then(res => {
        dispatch(setLoading(false));
        const data = res
        setFirstname(data.first_name);
        setLastname(data.last_name);
        setUsername(data.username);
        setPassword(data.password);
        setRole(data.role);
      }
      )
    }

  }, []);

  return (
    <div className="page">
      <SideBar />
      <div className="create-user">
        <main className="card">
          <div className="input-field col s12">
            <input
              id="full-name"
              type="text"
              className="validate"
              onChange={(text) => setFirstname(text.currentTarget.value)}
              value={firstname}
            />
            <label for="full-name">First name</label>
          </div>
          <div className="input-field col s12">
            <input
              id="full-name--"
              type="text"
              className="validate"
              onChange={(text) => setLastname(text.currentTarget.value)}
              value={lastname}
            />
            <label for="full-name--">Last name</label>
          </div>
          <div className="input-field col s12">
            <input
              id="username"
              type="text"
              className="validate"
              onChange={(text) => setUsername(text.currentTarget.value)}
              value={username}
            />
            <label for="username">Username</label>
          </div>
          <div className="input-field col s12">
            <input
              id="password"
              type="text"
              className="validate"
              onChange={(text) => setPassword(text.currentTarget.value)}
              value={password}
            />
            <label for="password">Password</label>
          </div>
          <div className="input-field col s12">
            <select
              id="lol"
              onChange={(text) => setRole(text.currentTarget.value)}
              value={role}
            >
              <option value="" disabled selected>
                Select role
              </option>
              <option>Admin</option>
              <option>Blogger</option>
            </select>
            {/* <label for='lol'>Materialize Select</label> */}
          </div>
          <button className="save btn" onClick={handleSave}>
            Create
          </button>
        </main>
      </div>
    </div>
  );
};

export default CreateUser;
