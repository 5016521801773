import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useFormik } from "formik";
import toast, { Toaster } from "react-hot-toast";
import * as yup from "yup";
import ReactHtmlParser from "react-html-parser";
import images from "../../constants/images";
import Footer from "../../components/footer";
import Header from "../../components/header";
import "../../css/blog.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlogApi } from "../../redux/slices";
import api from "../../api";
import apiRequest from "../../apiCall";
import config from "../Admin/Config.json";
import { showAuth } from "../../redux/slices";
import { setLoading } from "../../redux/slices";

const Blog = ({ post }) => {
  document.title = "Blog - keshroot";

  const location = useLocation();

  const dispatch = useDispatch();

  const { permalink } = useParams();

  const apiData = useSelector((state) => state.blogApi.value);

  useEffect(() => {
    // console.clear();
  }, [apiData]);

  const [isMobile, setIsMobile] = useState(false);
  const [postData, setPostData] = useState(null);
  const [dt, setDt] = useState(null);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    getBodyWidth();
    dispatch(fetchBlogApi());
  }, []);

  const blogPostGet = async () => {
    let res = await api("/user-blog-post/" + permalink);
    res = res.data;
    // if (res.status !== 200) {
    //     window.location = '/blog';
    // };
    res = res.data;
    setPostData(res);
    setDt(new Date(res.created_at));
  };

  useEffect(() => {
    if (permalink && post) {
      blogPostGet();
    }
  }, [location]);

  const data = [];
  const recentPostData = [];

  if (post) {
    window.scrollTo(0, 0);
  }

  for (let i = 0; i < 3; i++) {
    data.push({
      thumbnail: images["blog" + (i + 1)],
      title: `What is Kesh Root Ayurvedic Hair Oil and Its Ingredients?`,
      description: `Kesh Root Ayurvedic Hair Oil is a natural and herbal hair oil that has been
traditionally used for centuries in Ayurvedic medicine to promote healthy hair
growth and prevent hair loss.The word "Kesh" means hair in Sanskrit, and this oil is
specifically designed to nourish and revitalize the hair and scalp.`,
      postedAt: "February 13, 2023",
      postedBy: "Admin keshroot",
    });
  }

  for (let i = 0; i < 3; i++) {
    recentPostData.push({
      thumbnail: images.feature,
      description:
        "vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum",
    });
  }

  const getBodyWidth = () => {
    if (document.body.offsetWidth <= 920) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  window.addEventListener("resize", getBodyWidth);

  return (
    <>
      {apiData && (
        <div className="blog-section">
          <Header activepage="blog" />
          {!post ? (
            <h1 style={{ textAlign: "center" }}>Blog</h1>
          ) : (
            <div style={{ marginTop: 50 }}></div>
          )}
          <div className="blog">
            {!isMobile && (
              <div className="recent-posts">
                <h2>Recent posts</h2>
                {apiData.data
                  .filter((item) => item.status === 'published')
                  .map((item, index) => (
                    <>
                      {index <= 5 && (
                        <Link
                          className="recent-post"
                          to={"/blog/" + item.permalink}
                        ><div className="recent_post_image" style={{ width: '100%', height: '150px' }}>
                            <img src={config.awsMediaPath + item.feature_image} /></div>
                          <p>{item.description}</p>
                        </Link>
                      )}
                    </>
                  ))}
              </div>
            )}
            <div className="blog-posts">
              {!post ? (
                <>
                  {apiData.data
                    .filter((item) => item.status === 'published')
                    .map((item) => (
                      <div className="blog-post">
                        <div className="blog_post_image" >
                          <img src={config.awsMediaPath + item.feature_image} /></div>
                        <h2>{item.title}</h2>
                        <ul>
                          {/* <li>{item.author}</li> */}
                          <li>{item.author}</li>
                        </ul>
                        <p>{item.description}</p>
                        <Link to={"/blog/" + item.permalink}>Read more...!</Link>
                      </div>
                    ))}
                </>
              ) : (
                <>
                  {postData && (
                    <div className="post-view">
                      <h1>{postData.title}</h1>
                      {/* <ul>
                        <li>
                          {months[dt.getMonth()]} {dt.getDate()},{" "}
                          {dt.getFullYear()}
                        </li>
                      </ul> */}
                      <img src={config.awsMediaPath + postData.feature_image} />
                      <div className="content">
                        {ReactHtmlParser(postData.content)}
                      </div>
                      <Comment
                        id={postData._id}
                        permalink={postData.permalink}
                      />
                      {/* <Comment title={postData.title}/> */}
                    </div>
                  )}
                </>
              )}

              {/* 
              {isMobile && (
                <div className="recent-posts" style={{ marginTop: 50 }}>
                  <h2>Recent posts</h2>
                  {recentPostData.map((item) => (
                    <Link
                      className="recent-post"
                      to="/blog/how-bhumija-ayurvedic-hair-oil-helps-in-reducing-gray-hair"
                    >
                      <div className="recent_post_image" style={{width:'100%', height:'100px'}}>
                      <img src={item.thumbnail} /></div>
                      <p>{item.description}</p>
                    </Link>
                  ))}
                </div>
              )} */}
              {isMobile && (
                <div className="recent-posts">
                  <h2>Recent posts</h2>
                  {apiData.data
                    .filter((item) => item.status === 'published')
                    .map((item, index) => (
                      <>
                        {index <= 5 && (
                          <Link
                            className="recent-post"
                            to={"/blog/" + item.permalink}
                          ><div className="recent_post_image" style={{ width: '100%', height: '150px' }}>
                              <img src={config.awsMediaPath + item.feature_image} /></div>
                            <p>{item.description}</p>
                          </Link>
                        )}
                      </>
                    ))}
                </div>
              )}
            </div>
            <Toaster position="top-right" />
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

const Comment = ({ id, permalink }) => {
  const dispatch = useDispatch();
  const [comments, setComments] = useState([]);

  const erroress = () => toast.error("Please Login.");
  const datasave = () => toast.success("Thank You for your valuable Comment!");

  const userData = JSON.parse(localStorage.getItem("userData"));
  const uuid = userData ? userData.uuid : null;
  const email = userData ? userData.email : null;

  const initialValues = {
    firstName: "",
    message: "",
  };

  const validationSchema = yup.object({
    firstName: yup.string().required("Please enter your first name"),
    message: yup.string().required("Please enter your message"),
  });

  const { errors, handleChange, handleSubmit, setValues, resetForm } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (values) => {
      if (!uuid) {
        erroress();
        dispatch(showAuth(true));
        return;
      }
      try {
        dispatch(setLoading(true));
        await api.post("/blogcomment", {
          permalink: permalink,
          blogID: id,
          CustomerId: uuid,
          email: email,
          ...values,
        });

        dispatch(setLoading(false));
        datasave();
        resetForm();
      } catch (error) {
        dispatch(setLoading(false));
        console.error("Error posting comment:", error);
      }
    },
  });

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await api.get(`/blogcommentPermalink/${id}`);
        setComments(response.data);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };

    fetchComments();
  }, [id]);

  return (
    <>
      <div className="comment">
        <section>
          <div className="row">
            <input
              className="rowInputBlog"
              type="text"
              placeholder="Name"
              onChange={handleChange("firstName")}
            />
            {errors.firstName && <div className="err">{errors.firstName}</div>}
          </div>
          {/* <div className="row">
            <input
              type="text"
              placeholder="Email"
              onChange={handleChange("email")}
            />
            {errors.email && <div className="err">{errors.email}</div>}
          </div> */}

          <textarea placeholder="Message" onChange={handleChange("message")} />
        </section>
        {errors.message && <div className="err last">{errors.message}</div>}
        <button onClick={handleSubmit}>Post Comment</button>
      </div>
      <div className="review">
        {comments
          .filter((comment) => comment.Status === "Published")
          .map((comment) => (
            <div key={comment.BlogCommentID}>
              <h4>{comment.firstname}</h4>
              <p>{comment.message}</p>
              <br />
            </div>
          ))}
      </div>
    </>
  );
};

export default Blog;
