import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../api";
import icons from "../../constants/icons";
import "../../css/shop.css";
import { setCartUpdate, showAuth } from "../../redux/slices";
import Products from "./products";
import apiRequest from "../../apiCall";
import config from "../../pages/Admin/Config.json";
import ReactHtmlParser from "react-html-parser";
import StarRatings from "react-star-ratings";
import { setLoading } from "../../redux/slices";

const UserShop = () => {
  document.title = "Shop - keshroot";
  const [product, setProduct] = useState(null);
  const [ItemSize, setSelectedSize] = useState();
  const [ItemPrice, setSelectedPrice] = useState();
  const [UOMValue, setSelectedUOMValue] = useState();
  const [mlIndex, setMlIndex] = useState(0);
  const [sizes, setSizes] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [imageHeight, setImageHeight] = useState(0);
  const [productImages, setProductImages] = useState([]);
  const [isAddedToCart, setIsAddedToCart] = useState(false);
  const selectedSize =
    sizes && mlIndex >= 0 && mlIndex < sizes.length ? sizes[mlIndex] : null;

  const [selectedItem, setSelectedItem] = useState(0);
  const mainImageRef = useRef();
  const dispatch = useDispatch();

  const storedData = localStorage.getItem("userData") || " {}";
  const userData = JSON.parse(storedData);
  const uuid = userData.uuid || null;
  const CustomerId = userData.id || null;


  const navigation = useLocation();
  const { id } = useParams();

  useEffect(() => {
    apiRequest(
      "GET",
      "shop/product-detail/" + id.replaceAll("-", " "),
      null,
      false,
      false
    )
      .then((res) => {
        if (res?.itemId > 0) {
          const fetchedImages = res?.slider?.map(
            (x) => config.awsMediaPath + x
          );
          setProductImages(fetchedImages);
          setProduct(res);
          setSizes(res.units);
          setSelectedItem(0);
          window.scrollTo(0, 0);
          setQuantity(1);
          setCurrentIndex(0);
          setSelectedSize(res.units[0].packSize);
          setSelectedPrice(res.units[0].price);
          setSelectedUOMValue(res.units[0].UOMValue);
          setTab(0);
          setReviewsData([]);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [navigation]);

  useEffect(() => {
    changeImageHeight();
    window.addEventListener("resize", changeImageHeight);
    return () => {
      window.removeEventListener("resize", changeImageHeight);
    };
  }, []);


  const changeImageHeight = () => {
    if (mainImageRef.current) {
      mainImageRef.current.style.height =
        mainImageRef.current.offsetWidth * 1.001 + "px";
      const imgs = document.getElementsByClassName("imgs");
      for (let i = 0; i < imgs.length; i++) {
        imgs[i].style.height = mainImageRef.current.offsetWidth / 5 - 10 + "px";
        imgs[i].style.width = mainImageRef.current.offsetWidth / 6 + "px";
        document.querySelector(".images").style.width =
          mainImageRef.current.offsetWidth / 6 + "px";
      }
    }
  };

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % productImages.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [productImages]);

  const handleUp = () => {
    setCurrentIndex(
      currentIndex > 0 ? currentIndex - 1 : productImages.length - 1
    );
  };

  const handleDown = () => {
    setCurrentIndex((currentIndex + 1) % productImages.length);
  };

  const handleQuantityAdd = () => {
    setQuantity(quantity + 1);
  };

  const handleQuantitySubtract = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  // const price = (product ? product.price : null) * (mlIndex + 1);

  // const errors = () => toast.error("Please Login.");
  const sucsess = () => toast.success("Added to cart");

  const handleAddToCart = async () => {
    if (uuid == null) {
      dispatch(showAuth(true));
    } else if (selectedSize) {
      const { itemId, PTR, packLabel, UOM, price, packSize } = selectedSize;
      const { productCode, heading } = product;
  
      try {
        const response = await api.post(`api/cart/${productCode}/${packLabel}`, {
          heading,
          itemId,
          packLabel,
          ItemSize,
          quantity,
          ItemPrice,
          PTR,
          UOM,
          UOMValue,
          productCode,
          CustomerId,
        });
  
        if (response.data.action === "add") {
          setIsAddedToCart(true);
          sucsess();
          dispatch(setCartUpdate(true));
        } else if (response.data.action === "update") {
          dispatch(setCartUpdate(true));
          toast.success(" Cart Updated");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      // Handle case when no size is selected
    }
  };
  
  const handleImageClick = (index) => {
    setCurrentIndex(index);
  };

  window.addEventListener("resize", changeImageHeight);

  const [tab, setTab] = useState(0);

  const [showReviewForm, setShowReviewForm] = useState(false);
  const [reviewsData, setReviewsData] = useState([]);
  const navigate = useNavigate();

  const fetchReviews = async () => {
    try {
      apiRequest(
        "GET",
        "shopproductreviews/" + product.productCode,
        {},
        false,
        false
      )
        .then((res) => {
          if (res?.data.length > 0) {
            setReviewsData(res?.data);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    } catch (error) {
      console.error("Error fetching reviews:", error);
      // Handle error if necessary
    }
  };

  useEffect(() => {
    if (tab) {
      fetchReviews();
    }
  }, [tab]);

  const toggleReviewForm = () => {
    setShowReviewForm(!showReviewForm);
  };

  const calculateOverallRating = () => {
    if (reviewsData.length === 0) {
      return 0;
    }

    const totalRatings = reviewsData.reduce(
      (sum, review) => sum + parseInt(review.Rating),
      0
    );
    return totalRatings / reviewsData.length;
  };


  return (
    <div className="shop">
      <Header activepage="shop" />
      <div style={{ display: "none" }}>
        <Header isAddedToCart={isAddedToCart} />
      </div>
      <div className="product">
        <section id="ls">
          <div className="images">
            <img src={icons.arrowUp} id="up" onClick={handleUp} />
            <img src={icons.arrowDown} id="down" onClick={handleDown} />
            {productImages.map((image, idx) => (
              <img
                className={idx === currentIndex ? "imgs active" : "imgs"}
                key={idx}
                src={image}
                alt={`img${idx}`}
                onClick={() => handleImageClick(idx)}
              />
            ))}
          </div>
          <img
            src={productImages[currentIndex]}
            className="main-image"
            style={{ height: imageHeight }}
            ref={mainImageRef}
          />
        </section>

        <section id="rs">
          <h2>{product ? product.heading : null}</h2>
          <div className="price">
            <span>
              <h3>
                {sizes && sizes.length > 0
                  ? "₹" + sizes[selectedItem]?.price
                  : ""}
              </h3>
            </span>
            {/* <span id='real-price'>{'₹' + (170 * (mlIndex + 1)) + '.00'}</span> */}
            <span id="discount"></span>
          </div>
          <div className="tax">Tax included.</div>
          <h3>About product</h3>
          <p>{product ? ReactHtmlParser(product.aboutProduct) : null}</p>
          {selectedSize && (
            <div className="size">
              <h3>Unit</h3>
              <br />
              <div className="sizes">
                {sizes.map((item, index) => (
                  <span
                    className={selectedItem === index ? "ml active" : "ml"}
                    onClick={() => {
                      setSelectedItem(index);
                      setSelectedSize(item.packSize);
                      setSelectedPrice(item.price);
                      setSelectedUOMValue(item.UOMValue);
                      setQuantity(1);
                    }}
                  >
                    {item.packLabel}
                  </span>
                ))}
              </div>
            </div>
          )}
          <h3>Quantity</h3>
          <div className="purchase">
            <div className="quantity">
              <span onClick={handleQuantitySubtract}>─</span>
              {quantity}
              <span style={{ fontWeight: "bold" }} onClick={handleQuantityAdd}>
                +
              </span>
            </div>
            <div className="add-to-cart" onClick={handleAddToCart}>
              Add to cart
            </div>
            {/* <Link style={{ textDecoration: "none" }} to="/shop/cart" className="buy-now">
              Buy From Nearby Pharmacy
            </Link> */}
          </div>
          <div className="note-to-cusomer">
            <p>Please note: Keshroot will not be directly sending the product it will be delivered by the nearest pharmacy</p>
          </div>
        </section>
      </div>

      <Products
        excludeId={id}
        redirect={(pathname) => {
          navigate(pathname);
        }}
      />
      <Toaster position="top-right" />

      <div className="tab-view">
        <section className="first">
          <div className="tabs">
            <span
              onClick={() => setTab(0)}
              className={tab === 0 ? "active" : null}
            >
              Product Details
            </span>
            <span
              onClick={() => setTab(1)}
              className={tab === 1 ? "active" : null}
            >
              Reviews
            </span>
            {/* <span onClick={() => setTab(2)} className={tab === 2 ? 'active' : null}>Shipping and Return</span> */}
          </div>
        </section>
        <section>
          <div className="content">
            {tab === 0 && (
              <div>
                <div>{product && ReactHtmlParser(product.productDetails)}</div>
                <div className="images">
                  <div>
                    {product &&
                      product.detailsImages &&
                      product.detailsImages.map((item, index) => (
                        <div className="shop-last-div">
                          <img
                            className="shop-last"
                            key={index}
                            src={config.awsMediaPath + item}
                            alt={`img${index}`}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
            {tab === 1 && (
              <div className="reviews">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                  }}
                >
                  <p>
                    <span style={{ fontWeight: "bold", fontSize: "1.5rem" }}>
                      {calculateOverallRating().toFixed(1)}/5
                    </span>{" "}
                    Overall rating
                  </p>
                  <button className="add-to-cart" onClick={toggleReviewForm}>
                    <span
                      style={{ fontFamily: "Noto Serif", fontSize: "1.15rem" }}
                    >
                      Write Review
                    </span>
                  </button>
                </div>
                {showReviewForm ? (
                  <ReviewForm
                    onClose={() => setShowReviewForm(false)}
                    productId={product.productCode}
                  />
                ) : (
                  Array.isArray(reviewsData) &&
                  reviewsData
                    .filter((item) => item.Status === "Published") 
                    .map((item) => (
                      <div className="review" key={item.ID}>
                        <StarRatings
                          rating={parseInt(item.Rating)} 
                          starRatedColor="#9C4727"
                          numberOfStars={5}
                          name="rating"
                          
                          starDimension={20}
                          starSpacing={1.5}
                        />
                        <h3>
                          {item.FirstName} {item.LastName}
                        </h3>
                        <p>{item.Review}</p>
                      </div>
                    ))
                )}
              </div>
            )}
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

const ReviewForm = ({ onClose, productId }) => {
  const dispatch = useDispatch();
  const [reviewData, setReviewData] = useState({
    reviewText: "",
    rating: 0,
  });
  const errors = () => toast.error("Please Login.");
  const rating = () => toast.error("Please Give rating.");
  const datasave = () => toast.success("Thank You for your valuable Rating!");

  const userData = JSON.parse(localStorage.getItem("userData"));
  const uuid = userData ? userData.uuid : null;
  const email = userData ? userData.email : null;

  const handleReviewTextChange = (event) => {
    const text = event.target.value;
    const wordCount = text.split(/\s+/).length; 
    if (wordCount > 100) {
      toast.error("You have reached the maximum words limit.");
      return;
    } 
    setReviewData({ ...reviewData, reviewText: text });
  };

  const handleRatingChange = (newRating) => {
    setReviewData({ ...reviewData, rating: newRating });
  };

  const submitReview = async () => {
    
    if (reviewData.rating < 1 || reviewData.rating > 5) {
      
      rating();
      return; 
    }

    if (!uuid) {
     
      errors();
      dispatch(showAuth(true));
      return; 
    }

    const reviewPayload = {
      productId: productId,
      reviewText: reviewData.reviewText,
      rating: reviewData.rating,
      uuid: uuid,
      email: email
    };

    try {

      dispatch(setLoading(true));
      // Send the reviewPayload to the backend API using async/await
      await api.post("/shopproductreviews", reviewPayload);

      // Handle the response if needed.
      dispatch(setLoading(false));
      datasave();
      onClose();
    } catch (error) {
      console.error("Error saving review:", error);
      dispatch(setLoading(false));
    }
  };

  return (
    <div className="ReviewShop">
      {/* ... Add your review form elements here ... */}
      <textarea
        type="text"
        placeholder="Write Review"
        value={reviewData.reviewText}
        onChange={handleReviewTextChange}
      />
      <br />
      <StarRatings
        rating={reviewData.rating}
        starRatedColor="#9C4727"
        changeRating={handleRatingChange}
        numberOfStars={5}
        name="rating"
        starDimension={25}
        starSpacing={1.5}
      />
      <br />
      <div style={{ display: "flex" }}>
        <button onClick={submitReview} className="buy-now">
          Post
        </button>
        &nbsp;&nbsp;
        <button onClick={onClose} className="buy-now">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default UserShop;
