// import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import ReactHtmlParser from 'react-html-parser';

// import icons from '../../constants/icons';

// const Avaible = () => {

//     const [data, setData] = useState(null);

//     const reduxdata = useSelector(state => state.homeApi.value);

//     useEffect(() => {
//         if (reduxdata) {
//             setData(reduxdata.sectionF);
//             console.log("fetch",reduxdata.sectionF)
//         };
//     }, [reduxdata]);

//     return (<div className='avaible'>
//         <h2>{data.heading}</h2>
//         <section>
//             {
//                 data.map(item => (
//                     <a href={item.link} target='_blank'>
//                         <img src={item.image} />
//                     </a>
//                 ))
//             }
//         </section>
//     </div>);
// }

// export default Avaible;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import icons from '../../constants/icons';

const Avaible = () => {
  const [data, setData] = useState(null);

  const reduxdata = useSelector((state) => state.homeApi.value);

  useEffect(() => {
    if (reduxdata) {
      setData(reduxdata.sectionF);
    }
  }, [reduxdata]);

  return (data && data.showinwebsite ? (
    
    <div className='avaible'>
      <h2>{data.heading}</h2>
      <section>
        {data.media.map((imageUrl, index) => (


          <a key={index} href={`http://${data.content[index]}`} target='_blank'>
            <img src={imageUrl} alt={`Image ${index + 1}`} />
          </a>
        ))}
      </section>
    </div>
   ) : <></>);
};

export default Avaible;
