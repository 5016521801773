import React, { useEffect, useState } from 'react';
import SideBar from '../components/sideBar';
import MediaPicker from '../components/mediaePicker';

import apiRequest from "../../../apiCall";
import config from "../Config.json";
import { fileUpload } from '../../../fileUpload';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/slices';

const ContactUs = () => {
  const storedData = localStorage.getItem("adminData") || " {}";
  const adminData = JSON.parse(storedData);
  const AdminId = adminData._id || null;

  const [data, setData] = useState({
    title: '',
    description: '',
    featureImage: { status: 'none', image: '', file: null },
    address: '',
    email: '',
    phone: '',
    whatsapp: '',
  });
  const _data = data;

  const dispatch = useDispatch();

  const handleSave = async () => {
    dispatch(setLoading(true));
    if (data.title || data.description || data.address || data.email || data.phone || data.whatsapp && data.featureImage.image != "") {
      if (data.featureImage.status === "updated") {
        fileUpload([data.featureImage]).then(uploadImageRes => {
          if (uploadImageRes?.length > 0) {
            const newUploadedImages = uploadImageRes[0].key;
            saveData(newUploadedImages)
          }
        })
      } else {
        saveData(data.featureImage.imageName)
      }
    } else {
      dispatch(setLoading(false));
      window.M.toast({ html: "Please fill all values" });
    }
  };

  const saveData = (imageUrl) => {
    const params = {
      title: data.title,
      description: data.description,
      media: imageUrl,
      address: data.address,
      email: data.email,
      phone: data.phone,
      whatsapp: data.whatsapp,
      AdminId: AdminId,
    }
    apiRequest('POST', 'contactus', params, true, true).then(res => {
      if (res) {
        dispatch(setLoading(false));
        window.M.toast({ html: "Data saved successfully" });
      } else {
        dispatch(setLoading(false));
        window.M.toast({ html: "Something went wrong" });
      }
    })
  }
  const updateData = () => {
    setData(data => ({
      ...data,
      ..._data,
    }));
  };

  const getApiData = async () => {
    return new Promise((resolve, reject) => {
      dispatch(setLoading(true));
      apiRequest('GET', 'contactus', null, true, true).then(res => {
        dispatch(setLoading(false));
        sessionStorage.setItem('contactusApi', JSON.stringify(res));
        resolve(res);
        // res = res.data;
        _data.title = res.title;
        _data.description = res.description;
        _data.address = res.address;
        _data.email = res.email;
        _data.phone = res.phone;
        _data.whatsapp = res.whatsapp;
        _data.featureImage.image = res.media != "" ? config.awsMediaPath + res.media : "";
        _data.featureImage.imageName = res.media
        updateData();
      })
        .catch(error => {
          dispatch(setLoading(false));
          console.error('Error calling home API:', error);
          reject(error);
        });
    });


  }

  useEffect(() => {
    getApiData();
  }, [])

  return (
    <div className="page contact-us">
      <SideBar />
      <div className="container">
        <div className="right-side">
          <h2>Section - A</h2>
          <div className="sec admin-con">
            <div className="input-field">
              <input
                id="title"
                type="text"
                className="validate"
                value={data.title}
                onChange={titles => {
                  titles = titles.currentTarget.value;
                  _data.title = titles;
                  updateData();
                }}
              />
              <label htmlFor="title">Title</label>
            </div>
          </div>
          <div className="sec admin-con">
            <div className="input-field">
              <textarea
                id="description"
                className="materialize-textarea"
                style={{ height: 100 }}
                value={data.description}
                onChange={descriptions => {
                  descriptions = descriptions.currentTarget.value;
                  _data.description = descriptions;
                  updateData();
                }}
              />
              <label htmlFor="description">Description</label>
            </div>
          </div>
        </div>
        <div className="sec admin-con">
          <h2>Section - B</h2>
          <MediaPicker
            type="image"
            text="Feature Logo"
            onChange={(file, image) => {
              _data.featureImage.file = file;
              _data.featureImage.image = image;
              _data.featureImage.status = 'updated';
              updateData();
            }}
            source={data.featureImage.image}
          />
        </div>
        <div className="sec admin-con">
          <div className="input-field">
            <textarea
              id="address"
              className="materialize-textarea"
              style={{ height: 150 }}
              value={data.address}
              onChange={addresss => {
                addresss = addresss.currentTarget.value;
                _data.address = addresss;
                updateData();
              }}
            />
            <label htmlFor="address">Address</label>
          </div>
          <div className="input-field">
            <input
              id="phone"
              type="text"
              className="validate"
              value={data.phone}
              onChange={phones => {
                phones = phones.currentTarget.value;
                _data.phone = phones;
                updateData();
              }}
            />
            <label htmlFor='phone'>Phone</label>
          </div>
          <div className="input-field">
            <input
              id="whatsapp"
              type="text"
              className="validate"
              value={data.whatsapp}
              onChange={whatsapps => {
                whatsapps = whatsapps.currentTarget.value;
                _data.whatsapp = whatsapps;
                updateData();
              }}
            />
            <label htmlFor='phone'>Whatsapp</label>
          </div>
          <div className='input-field'>
            <input id='email'
              type='text'
              className='validate'
              value={data.email}
              onChange={emails => {
                emails = emails.currentTarget.value;
                _data.email = emails;
                updateData();
              }}
            />
            <label htmlFor='email'>Email</label>
          </div>
        </div>
        <button className="btn save" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
}
export default ContactUs;
