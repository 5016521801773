import React, { useEffect, useState } from "react";

import MediaPicker from "../mediaePicker";

import { callSectionA } from "../../callApi/home";
import { fileUpload } from "../../../../fileUpload";
import config from "../../Config.json";
import { useDispatch } from "react-redux";
// import { setLoading } from "../../../../redux/slices";
const SectionA = () => {
  const storedData = localStorage.getItem("adminData") || " {}";
  const adminData = JSON.parse(storedData);
  const AdminId = adminData._id || null;

  const [data, setData] = useState({
    AdminId: AdminId,
    featureImage: {
      image: "",
      file: "",
      status: "none",
    },
    featureImage1: {
      image: "",
    },
    title: "",
    content: "",
  });
  const [option, setOption] = useState(false);
  const _data = data;

  const updateData = () => {
    setData((data) => ({
      ...data,
      ..._data,
    }));
  };

  const handleImageChange = (file, image) => {
    _data.featureImage.image = image;
    _data.featureImage1.image = image;
    _data.featureImage.file = file;
    _data.featureImage.status = "updated";

    setData((data) => ({
      ...data,
      ..._data,
    }));
  };
  const dispatch = useDispatch();

  const handleSave = async () => {
    if (
      data.title !== "" &&
      data.content !== "" &&
      data.featureImage.image != ""
    ) {
      // dispatch(setLoading(true));
      if (data.featureImage.status === "updated") {
        fileUpload([data.featureImage]).then((uploadImageRes) => {
          if (uploadImageRes?.length > 0) {
            const newUploadedImages = uploadImageRes[0].key;
            saveData(newUploadedImages);
          }
        });
      } else {
        saveData(data.featureImage.image);
      }
    } else {
      window.M.toast({ html: "Please fill all values" });
    }
  };

  const saveData = (imageUrl) => {
    const params = {
      title: data.title,
      content: data.content,
      showinwebsite: option ? 1 : 0,
      images: imageUrl,
      AdminId: AdminId,
    };
    callSectionA(params).then((res) => {
      if (res) {
        // dispatch(setLoading(false));
        window.M.toast({ html: "Data saved successfully" });
      } else {
        // dispatch(setLoading(false));
        window.M.toast({ html: "Something went wrong" });
      }
    });
  };

  useEffect(() => {
    const secAData = JSON.parse(sessionStorage.getItem("homeApi")).sectionA;
    _data.featureImage = { image: secAData.media };
    _data.featureImage1 = { image: config.awsMediaPath + secAData.media };
    _data.title = secAData.heading;
    _data.content = secAData.content;
    setOption(secAData.showinwebsite == 1 ? true : false);
    updateData();
  }, []);

  return (
    <div className="sec section-a">
      <h2>Section - A</h2>
      <main>
        <section>
          <MediaPicker
            text="Feature image"
            type="image"
            onChange={handleImageChange}
            source={data.featureImage1.image}
          />
        </section>
        <section>
          <div className="input-field">
            <input
              id="section-a-title"
              type="text"
              className="validate"
              onChange={(text) => {
                text = text.currentTarget.value;
                _data.title = text;
                setData((data) => ({
                  ...data,
                  ..._data,
                }));
              }}
              value={data.title}
            />
            <label for="section-a-title">Title</label>
          </div>
          <div className="input-field">
            <textarea
              id="section-a-content"
              className="materialize-textarea"
              onChange={(text) => {
                text = text.currentTarget.value;
                _data.content = text;
                setData((data) => ({
                  ...data,
                  ..._data,
                }));
              }}
              value={data.content}
            ></textarea>
            <label for="section-a-content">Content</label>
          </div>
        </section>
      </main>

      <div className="input-field">
        <label>
          <input
            type="checkbox"
            checked={option}
            onChange={() => setOption(!option)}
          />
          <span>Is Active</span>
        </label>
      </div>
      <button className="btn save" onClick={handleSave}>
        Save
      </button>
    </div>
  );
};

export default SectionA;
