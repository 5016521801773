import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import icons from "../../constants/icons";
import config from "../../pages/Admin/Config.json";

const BrandUSPs = () => {
  const [data, setData] = useState(null);

  const reduxdata = useSelector((state) => state.homeApi.value);

  const images = [icons.leave, icons.genetic, icons.medical];

  useEffect(() => {
    if (reduxdata) {
      setData(reduxdata.sectionB);
    }
  }, [reduxdata]);

  return data && data.showinwebsite ? (
    <div className="brand-usps">
      <h2>{data.heading}</h2>
      <div className="brand-usps-cards">
        {data.content.map((item, index) => (
          <section key={index}>
            <div className="brand-usps-image">
            <img src={config.awsMediaPath + data.media[index]} /></div>
            <h3>{item.title}</h3>
            <p>{item.content}</p>
          </section>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default BrandUSPs;
