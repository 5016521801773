import apiRequest from "../../../apiCall";

const callShopCommentApi = async () => {
  return new Promise((resolve, reject) => {
    apiRequest("GET", "shopcomment", null, true, true)
      .then((res) => {
        sessionStorage.setItem("shopcommentApi", JSON.stringify(res));
        resolve(res.data);
      })
      .catch((error) => {
        console.error("Error calling home API:", error);
        reject(error);
      });
  });
};

export default callShopCommentApi;
