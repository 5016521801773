import React, { useState, useEffect } from "react";

import MediaPicker from "../mediaePicker";
import { callSectionC } from "../../callApi/home";
import { fileUpload } from "../../../../fileUpload";
import config from "../../Config.json";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/slices";

const SectionC = () => {
  const storedData = localStorage.getItem("adminData") || " {}";
  const adminData = JSON.parse(storedData);
  const AdminId = adminData._id || null;
  const [data, setData] = useState({
    AdminId: AdminId,
    featureVideo: {
      image: "",
      file: "",
      status: "none",
    },
    title: "",
    content: "",
  });

  const _data = data;
  const [option, setOption] = useState(false);

  const updateData = () => {
    setData((data) => ({
      ...data,
      ..._data,
    }));
  };

  const handleImageChange = (file, image) => {
    _data.featureVideo.image = image;
    _data.featureVideo.file = file;
    _data.featureVideo.status = "updated";

    setData((data) => ({
      ...data,
      ..._data,
    }));
  };

  const dispatch = useDispatch();
  const handleSave = async () => {
    if (data.title !== "" && data.content !== "") {
      dispatch(setLoading(true));
      if (data.featureVideo.status === "updated") {
        fileUpload([data.featureVideo]).then((uploadImageRes) => {
          if (uploadImageRes?.length > 0) {
            const newUploadedImages = uploadImageRes[0].key;
            saveData(newUploadedImages);
          }
        });
      } else {
        saveData(data.featureVideo.image);
      }
    } else {
      window.M.toast({ html: "Please fill all values" });
    }
  };

  const saveData = (imageUrl) => {
    const params = {
      title: data.title,
      content: data.content,
      showinwebsite: option ? 1 : 0,
      media: imageUrl,
      AdminId: AdminId,
    };
    callSectionC(params).then((res) => {
      if (res) {
        dispatch(setLoading(false));
        window.M.toast({ html: "Data saved successfully" });
      } else {
        dispatch(setLoading(false));
        window.M.toast({ html: "Something went wrong" });
      }
    });
  };

  useEffect(() => {
    const secCData = JSON.parse(sessionStorage.getItem("homeApi")).sectionC;
    _data.title = secCData.heading;
    _data.content = secCData.content;
    _data.featureVideo.image = config.awsMediaPath + secCData.media;
    setOption(secCData.showinwebsite == 1 ? true : false);

    updateData();
  }, []);

  return (
    <div className="sec section-a section-c">
      <h2>Section - C</h2>
      <main>
        <section>
          <MediaPicker
            text="Feature video"
            type="video"
            onChange={handleImageChange}
            source={data.featureVideo.image}
          />
        </section>
        <section>
          <div className="input-field">
            <input
              id="section-c-title"
              type="text"
              className="validate"
              onChange={(text) => {
                text = text.currentTarget.value;
                _data.title = text;
                setData((data) => ({
                  ...data,
                  ..._data,
                }));
              }}
              value={data.title}
            />
            <label for="section-c-title">Title</label>
          </div>
          <div className="input-field">
            <textarea
              id="section-c-content"
              className="materialize-textarea"
              onChange={(text) => {
                text = text.currentTarget.value;
                _data.content = text;
                setData((data) => ({
                  ...data,
                  ..._data,
                }));
              }}
              value={data.content}
            ></textarea>
            <label for="section-c-content">Content</label>
          </div>
        </section>
      </main>
      <div className="input-field">
        <label>
          <input
            type="checkbox"
            checked={option}
            onChange={() => setOption(!option)}
          />
          <span>Is Active</span>
        </label>
      </div>

      <button className="btn save" onClick={handleSave}>
        Save
      </button>
    </div>
  );
};

export default SectionC;
