import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import axios, { Axios } from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import callShopApi from "../../callApi/shop";
import apiRequest from "../../../../apiCall";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/slices";

const Autocompletes = ({ onChange, refreshClick }) => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [bindData, setBindData] = useState(false);
  const [list, setList] = useState([]);
  const [id, setId] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoading(true));
    apiRequest("GET", "shopproductapi", null, true, true)
      .then((res) => {
        dispatch(setLoading(false));
        setSuggestions(res);
      })
      .catch(function (error) {
        dispatch(setLoading(false));
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (suggestions.length > 0) {
      setBindData(true);
    }
  }, [suggestions]);

  const submit = () => {
    if (value != "") {
      onChange(suggestions.filter((x) => x.Brand === value)[0].ProductCode);
    }
  };

  let modifiedArr = suggestions.map(function (suggestion) {
    return suggestion.Brand;
  });

  return (
    <>
      <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          id="controllable-states-demo"
          options={modifiedArr}
          sx={{ width: "45%", paddingBottom: "20px" }}
          renderInput={(params) => <TextField {...params} label="Products" />}
        />
        <button id="shop-search" onClick={submit}>
          Search
        </button>
        <button id="shop-search" onClick={refreshClick}>
          Referesh
        </button>
      </div>
    </>
  );
};

export default Autocompletes;
