import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { setLoading, authPage, showAuth } from "../../redux/slices";
import { encrypt } from "../Admin/crypto";
import apiRequest from "../../apiCall";
import toast, { Toaster } from "react-hot-toast";
import api from "../../api";

const Signup = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showSendOTP, setShowSendOTP] = useState(false);

  const errorsz = () => toast.error("Internal Sever Error, Please try again later.");

  const initialValues = {
    firstname: "",
    lastname: "",
    mobile: "",
    password: "",
    email: "",
  };

  const validationSchema = yup.object({
    firstname: yup.string().required("Please enter a First Name"),
    lastname: yup.string().required("Please enter a Last Name"),
    mobile: yup
      .string()
      .required("Please enter a Mobile number")
      .matches(/^\d{10}$/, "Mobile number must be a 10-digit number"),

    password: yup
      .string()
      .required("Please enter a password")
      .min(8, "Please enter at least 8 characters"),
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Please enter an email address"),
    OTP: yup.string().when('showSendOTP', {
      is: true,
      then: yup.string().required("Please enter OTP"),
    }),
  });

  const { values, errors, handleSubmit, handleChange, setFieldError, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      const formData = new FormData();
      const values1 = { ...values, password: encrypt(values.password) };
      formData.append("content", JSON.stringify(values1));
      try {
        dispatch(setLoading(true));
        apiRequest("POST", "signup", formData, false, false).then((res) => {
          if (res.status === 200 &&  res.isPresent === 0 ) {
            dispatch(setLoading(false));
            setShowSendOTP(true);
          } else if (res.status === 200 && res.isPresent === 1 ) {
            dispatch(setLoading(false));
            toast.error("Mobile number is already present. Please Login!");
            dispatch(authPage("login"));
          }else {
            dispatch(setLoading(false));
          }
        });
      } catch (error) {
        if (error.response && error.response.status === 400) {
          dispatch(setLoading(false));
        } else {
          dispatch(setLoading(false));
          setFieldError("field", "message");
        }
      }
    },
  });

  const handleLogout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("userToken");
    setIsLoggedIn(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleOTPSubmit = async () => {
    try {
      dispatch(setLoading(true));
      const otpRes = await api.post("/validateOTP", {
        mobile: values.mobile,
        OTP: values.OTP,
      });

      dispatch(setLoading(false));
      if (otpRes.data.mes === "FOUND") {
        dispatch(setLoading(false));
        toast.success("Your account has been successfully created. Please Login!");
        setTimeout(() => {
          dispatch(showAuth(false));
      }, 2000);
      } else {
        dispatch(setLoading(false));
        toast.error(otpRes.data.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      errorsz();
      console.log("Error validating OTP:", error);
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      if (!showSendOTP) {
        handleSubmit();
      } else {
        handleOTPSubmit();
      }
    }
  };
  const handClose = () => {
    dispatch(showAuth(false));
    dispatch(authPage("login"));
  }

  return isLoggedIn ? (
    <>
      <div className="auth">
        <div className="modal-content">
          <h2>Logout</h2>
          <button onClick={handleLogout}>Logout</button>
        </div>
      </div>
    </>
  ) : (
    <>
      {!showSendOTP ? (
        <div className="auth">
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <button
              style={{
                cursor: "pointer",
                fontSize: "20px",
                width: "31px",
                padding: "0px",
                marginTop: "0px",
                marginLeft: "auto", // Push the button to the right
              }}
              className="delete-cart"
              onClick={handClose}
            >
              <b>X</b>
            </button>
          </div>
          <h2>Sign up</h2>
          <p>Please enter your details</p>
          <input
            type="text"
            placeholder="First Name"
            onKeyDown={handleKeyDown}
            onChange={handleChange("firstname")}
          />
          {errors.firstname && <div className="err">{errors.firstname}</div>}
          <input
            type="text"
            placeholder="Last Name"
            onKeyDown={handleKeyDown}
            onChange={handleChange("lastname")}
          />
          {errors.lastname && <div className="err">{errors.lastname}</div>}
          <input
            type="text"
            placeholder="Mobile Number"
            maxLength={10}
            onKeyDown={handleKeyDown}
            onChange={handleChange("mobile")}
          />
          {errors.mobile && <div className="err">{errors.mobile}</div>}

          <input
            type="text"
            placeholder="Email ID"
            onKeyDown={handleKeyDown}
            onChange={handleChange("email")}
          />
          {errors.email && <div className="err">{errors.email}</div>}
          <input
            type="Password"
            placeholder="Password"
            onChange={handleChange("password")}
            onKeyDown={handleKeyDown}
            maxLength={15}
            minLength={8}
          />
          {errors.password && <div className="err">{errors.password}</div>}
          {errorMessage && <div className="err">{errorMessage}</div>}
          <button onClick={handleSubmit}>Sign up</button>
          <div className="bottom-text">
            Already have an account?{" "}
            <span onClick={() => dispatch(authPage("login"))}>Log in</span>
          </div>
        </div>
      ) : (
        <div className="auth">
          <p> Please enter your OTP</p>
          <input
            type="number"
            placeholder="OTP"
            onChange={(e) => setFieldValue("OTP", e.target.value)}
            onKeyPress={handleEnterKeyPress}
          />
          {errors.OTP && <div className="err">{errors.OTP}</div>}
          <button onClick={handleOTPSubmit}>Submit</button>
        </div>
      )}
      <Toaster position="top-right" />
    </>
  );
};

export default Signup;
