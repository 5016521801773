import React, { useEffect, useRef, useState } from "react";
import { useAsyncError, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import icons from "../../constants/icons";
import ShippingLayout from "../../components/shippingLayout";
import "../../css/checkout.css";
import api from "../../api";
import DeliverySelect from "../../components/deliverySelect";
import Confirmation from "./confirmation";
import toast, { Toaster } from "react-hot-toast";
import apiRequest from "../../apiCall";
import { showAuth } from "../../redux/slices";
import { useDispatch } from "react-redux";

const Checkout = () => {
  document.title = "Checkout - keshroot";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [emailId, setEmailId] = useState("");
  const [phone, setPhone] = useState("");
  const [customerId, setCustomerId] = useState("");

  //   const [savedAddressList, setSavedAddressList] = useState([]);
  //   const [selectedAddress, setSelectedAddress] = useState("");
  const [selectedAddressType, setSelectedAddressType] = useState("");

  const [shippingFirstName, setShippingFirstName] = useState("");
  const [shippingLastName, setShippingLastName] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [shippingState, setShippingState] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  const [shippingArea, setShippingArea] = useState("");
  const [shippingPinCode, setShippingPinCode] = useState("");
  const [shippingCountry, setShippingCountry] = useState("");

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [areaList, setAreaList] = useState([]);

  const [chemistList, setChemistList] = useState([]);
  const [selectedChemist, setSelectedChemist] = useState("");

  const [sameAsShippingAddress, setSameAsShippingAddress] = useState(false);
  const [saveInformation, setSaveInformation] = useState(0);

  const [billingFirstName, setBillingFirstName] = useState("");
  const [billingLastName, setBillingLastName] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [billingState, setBillingState] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingArea, setBillingArea] = useState("");
  const [billingPinCode, setBillingPinCode] = useState("");
  const [billingCountry, setBillingCountry] = useState("");
  const [products, setProducts] = useState([]);

  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [addressTypeError, setAddressTypeError] = useState("");
  const [shippingFirstNameError, setShippingFirstNameError] = useState("");
  const [shippingLastNameError, setShippingLastNameError] = useState("");
  const [shippingAddressError, setShippingAddressError] = useState("");
  const [shippingStateError, setShippingStateError] = useState("");
  const [shippingCityError, setShippingCityError] = useState("");
  const [shippingAreaError, setShippingAreaError] = useState("");
  const [shippingPinCodeError, setShippingPinCodeError] = useState("");
  const [shippingCountryError, setShippingCountryError] = useState("");
  const [chemistError, setChemistError] = useState("");
  const [billingFirstNameError, setBillingFirstNameError] = useState("");
  const [billingLastNameError, setBillingLastNameError] = useState("");
  const [billingAddressError, setBillingAddressError] = useState("");
  const [billingStateError, setBillingStateError] = useState("");
  const [billingCityError, setBillingCityError] = useState("");
  const [billingAreaError, setBillingAreaError] = useState("");
  const [billingPinCodeError, setBillingPinCodeError] = useState("");
  const [billingCountryError, setBillingCountryError] = useState("");
  const [productsError, setProductsError] = useState([]);

  const handleCheckboxChange = (chemistId) => {
    if (selectedChemist === chemistId) {
      // If the same chemist is already selected, unselect it
      setSelectedChemist();
    } else {
      // Otherwise, select the new chemist
      setSelectedChemist(chemistId);
    }
  };
  useEffect(() => {
    const storedData = localStorage.getItem("userData") || " {}";
    const userData = JSON.parse(storedData);

    if (userData && userData.id) {
      getCart(userData);
    } else {
      dispatch(showAuth(true));
      toast.error("Please Try Again Sometime");
    }
  }, []);

  //   const customerAddresses = (userData) => {
  //     apiRequest("GET", `customerAddress/${userData.id}`, null, true, false)
  //       .then((res) => {
  //         setSavedAddressList(res);
  //         setCustomerId(userData.id);
  //         setShippingFirstName(userData.FirstName);
  //         setShippingLastName(userData.LastName);
  //         setEmailId(userData.email);
  //         setPhone(userData.Mobile);
  //         setShippingCountry("1");
  //       })
  //       .catch(function (error) {
  //         console.error(error);
  //       });
  //   };

  const getCountriesData = () => {
    apiRequest("GET", `country`, null, false, false)
      .then((res) => {
        setCountryList(res);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    if (shippingCountry) {
      apiRequest("GET", `states/${shippingCountry}`, null, false, false)
        .then((res) => {
          setStateList(res);
        })
        .catch(function (error) {
          console.error(error);
        });
    } else {
      setStateList([]);
    }
    setShippingState("");
    setCityList([]);
    setShippingCity("");
    setAreaList([]);
    setShippingArea("");
    setChemistList([]);
    setSelectedChemist("");
  }, [shippingCountry]);

  useEffect(() => {
    if (shippingState) {
      apiRequest("GET", `city/${shippingState}`, null, false, false)
        .then((res) => {
          setCityList(res);
        })
        .catch(function (error) {
          console.error(error);
        });
    } else {
      setCityList([]);
    }
    setShippingCity("");
    setAreaList([]);
    setShippingArea("");
    setChemistList([]);
    setSelectedChemist("");
  }, [shippingState]);

  useEffect(() => {
    if (shippingCity) {
      apiRequest("GET", `area/${shippingCity}`, null, false, false)
        .then((res) => {
          setAreaList(res);
        })
        .catch(function (error) {
          console.error(error);
        });
    } else {
      setAreaList([]);
    }
    setShippingArea("");
    setChemistList([]);
    setSelectedChemist("");
  }, [shippingCity]);

  useEffect(() => {
    if (shippingArea) {
      apiRequest(
        "GET",
        `nearbychemist/${shippingCountry}/${shippingState}/${shippingCity}/${shippingArea}`,
        null,
        false,
        false
      )
        .then((res) => {
          setChemistList(res);
        })
        .catch(function (error) {
          console.error(error);
        });
    } else {
      setChemistList([]);
    }
    setSelectedChemist("");
  }, [shippingArea]);

  const getCart = (userData) => {
    apiRequest("GET", `api/cart/${userData.uuid}`, null, false, false)
      .then((res) => {
        if (res.length > 0) {
          setProducts(res);
          //   customerAddresses(userData);
          setCustomerId(userData.id);
          setShippingFirstName(userData.FirstName);
          setShippingLastName(userData.LastName);
          setEmailId(userData.email);
          setPhone(userData.Mobile);
          getCountriesData();
          setShippingCountry("1");
        } else {
          navigate("/shop/cart");
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const orderRecieved = () => toast.success("Your Order Recieved Successfully");

  const validateEmail = () => {
    try {
      yup
        .string()
        .email("Invalid email address")
        .required("Email is required")
        .validateSync(emailId);
      setEmailError("");
      return true;
    } catch (error) {
      setEmailError(error.message);
      return false;
    }
  };

  const validatePhone = () => {
    try {
      yup
        .string()
        .required("Please enter your mobile number")
        .min(10, "Please enter valid 10 digit mobile number")
        .max(10, "Please enter valid 10 digit mobile number")
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Please enter valid phone number"
        )
        .validateSync(phone);
      setPhoneError("");
      return true;
    } catch (error) {
      setPhoneError(error.message);
      return false;
    }
  };

  const validateShippingFirstName = () => {
    try {
      yup
        .string()
        .required("First name is required")
        .validateSync(shippingFirstName);
      setShippingFirstNameError("");
      return true;
    } catch (error) {
      setShippingFirstNameError(error.message);
      return false;
    }
  };

  const validateShippingLastName = () => {
    try {
      yup
        .string()
        .required("Last name is required")
        .validateSync(shippingLastName);
      setShippingLastNameError("");
      return true;
    } catch (error) {
      setShippingLastNameError(error.message);
      return false;
    }
  };

  const validateShippingAddress = () => {
    try {
      yup
        .string()
        .required("Address is required")
        .validateSync(shippingAddress);
      setShippingAddressError("");
      return true;
    } catch (error) {
      setShippingAddressError(error.message);
      return false;
    }
  };

  const validateShippingState = () => {
    try {
      yup.string().required("State is required").validateSync(shippingState);
      setShippingStateError("");
      return true;
    } catch (error) {
      setShippingStateError(error.message);
      return false;
    }
  };

  const validateShippingCity = () => {
    try {
      yup.string().required("City is required").validateSync(shippingCity);
      setShippingCityError("");
      return true;
    } catch (error) {
      setShippingCityError(error.message);
      return false;
    }
  };

  const validateShippingArea = () => {
    try {
      yup.string().required("Area is required").validateSync(shippingArea);
      setShippingAreaError("");
      return true;
    } catch (error) {
      setShippingAreaError(error.message);
      return false;
    }
  };

  const validateAddressType = () => {
    try {
      yup
        .string()
        .required("Address Type is required")
        .validateSync(selectedAddressType);
      setAddressTypeError("");
      return true;
    } catch (error) {
      setAddressTypeError(error.message);
      return false;
    }
  };

  const validateShippingPinCode = () => {
    try {
      yup
        .string()
        .required("Please enter your pincode")
        .min(6, "Please enter valid pincode")
        .max(6, "Please enter valid pincode")
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          "Please enter valid pincode"
        )
        .validateSync(shippingPinCode);
      setShippingPinCodeError("");
      return true;
    } catch (error) {
      setShippingPinCodeError(error.message);
      return false;
    }
  };

  const validateShippingCountry = () => {
    try {
      yup
        .string()
        .required("Country is required")
        .validateSync(shippingCountry);
      setShippingCountryError("");
      return true;
    } catch (error) {
      setShippingCountryError(error.message);
      return false;
    }
  };

  const validateShippingChemist = () => {
    try {
      yup
        .string()
        .required("Chemist is required")
        .validateSync(selectedChemist);
      setChemistError("");
      return true;
    } catch (error) {
      setChemistError(error.message);
      return false;
    }
  };

  // const validateBillingFirstName = () => {
  //   try {
  //     yup
  //       .string()
  //       .required("First Name is required")
  //       .validateSync(billingFirstName);
  //     setBillingFirstNameError("");
  //     return true;
  //   } catch (error) {
  //     setBillingFirstNameError(error.message);
  //     return false;
  //   }
  // };

  // const validateBillingLastName = () => {
  //   try {
  //     yup
  //       .string()
  //       .required("Last Name is required")
  //       .validateSync(billingLastName);
  //     setBillingLastNameError("");
  //     return true;
  //   } catch (error) {
  //     setBillingLastNameError(error.message);
  //     return false;
  //   }
  // };

  // const validateBillingAddress = () => {
  //   try {
  //     yup.string().required("Address is required").validateSync(billingAddress);
  //     setBillingAddressError("");
  //     return true;
  //   } catch (error) {
  //     setBillingAddressError(error.message);
  //     return false;
  //   }
  // };

  // const validateBillingState = () => {
  //   try {
  //     yup.string().required("State is required").validateSync(billingState);
  //     setBillingStateError("");
  //     return true;
  //   } catch (error) {
  //     setBillingStateError(error.message);
  //     return false;
  //   }
  // };

  // const validateBillingCity = () => {
  //   try {
  //     yup.string().required("City is required").validateSync(billingCity);
  //     setBillingCityError("");
  //     return true;
  //   } catch (error) {
  //     setBillingCityError(error.message);
  //     return false;
  //   }
  // };

  // const validateBillingArea = () => {
  //   try {
  //     yup.string().required("Area is required").validateSync(billingArea);
  //     setBillingAreaError("");
  //     return true;
  //   } catch (error) {
  //     setBillingAreaError(error.message);
  //     return false;
  //   }
  // };

  // const validateBillingPinCode = () => {
  //   try {
  //     yup
  //       .string()
  //       .required("Please enter your pincode")
  //       .min(6, "Please enter valid pincode")
  //       .max(6, "Please enter valid pincode")
  //       .matches(
  //         /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  //         "Please enter valid pincode"
  //       )
  //       .validateSync(billingPinCode);
  //     setBillingPinCodeError("");
  //     return true;
  //   } catch (error) {
  //     setBillingPinCodeError(error.message);
  //     return false;
  //   }
  // };

  // const validateBillingCountry = () => {
  //   try {
  //     yup.string().required("Country is required").validateSync(billingCountry);
  //     setBillingCountryError("");
  //     return true;
  //   } catch (error) {
  //     setBillingCountryError(error.message);
  //     return false;
  //   }
  // };

  const generateOrderId = () => {
    const timestamp = Date.now().toString(); // Get the current timestamp
    const randomNum = Math.floor(Math.random() * 10000); // Generate a random number between 0 and 9999
    const orderId = `KE-${timestamp}-${randomNum}`; // Combine the timestamp and random number
    return orderId;
  };

  const validateForm = (isAll) => {
    const common = [
      validateShippingFirstName,
      validateShippingLastName,
      validateShippingAddress,
      validateShippingState,
      validateShippingCity,
      validateShippingArea,
      // validateAddressType,
      validateShippingPinCode,
      validateShippingCountry,
      validateShippingChemist,
    ];
    const validationFunctions = isAll
      ? [
          validateEmail,
          validatePhone,
          ...common,
          // validateBillingFirstName,
          // validateBillingLastName,
          // validateBillingAddress,
          // validateBillingState,
          // validateBillingCity,
          // validateBillingArea,
          // validateBillingPinCode,
          // validateBillingCountry,
        ]
      : common;

    let isValid = true;
    for (const validateFunction of validationFunctions) {
      if (!validateFunction()) {
        isValid = isValid && false;
      }
    }
    return isValid;
  };

  const getTotalCartValue = () => {
    let totalValue = 0;
    products.forEach((item) => {
      totalValue += item.total_price;
    });
    return totalValue;
  };

  const handleSubmit = () => {
    if (!validateForm(true)) {
      toast.error("Please fill form details!!!");
      return;
    }
    const onlineOrderMaster = {
      ChemistList: JSON.stringify(
        chemistList.reduce((acc, x) => {
          if (x.ChemistId != selectedChemist) {
            acc.push(x.ChemistId);
          }
          return acc;
        }, [])
      ),
      ChemistId: selectedChemist,
      CustomerId: customerId,
      OrderNumber: generateOrderId(),
      CustomerEmail: emailId,
      CustomerPhone: phone,
      SiteMasterId: 2,
      OrderAmount: getTotalCartValue(),
      ActualAmount: getTotalCartValue(),
      CustomerRemarks: "",
    };
    const onlineOrderDetails = products.map((x) => {
      return {
        ItemId: +x.ItemID,
        Quantity: x.Quantity,
        PricePerUnit: x.Price,
        TotalPrice: x.total_price,
      };
    });
    const customerDetails = {
      firstName: shippingFirstName,
      lastName: shippingLastName,
      address:
        shippingAddress +
        ", " +
        areaList.filter((x) => x.AreaId == shippingArea)[0].AreaName +
        ", " +
        cityList.filter((x) => x.CityId == shippingCity)[0].CityName +
        ", " +
        stateList.filter((x) => x.StateId == shippingState)[0].StateName +
        ", " +
        countryList.filter((x) => x.CountryId == shippingCountry)[0]
          .CountryName +
        " - " +
        shippingPinCode,
      addressType: "", // selectedAddressType,
      billingAddress:
        billingAddress +
        ", " +
        billingArea +
        ", " +
        billingCity +
        ", " +
        billingState +
        ", " +
        billingCountry +
        " - " +
        billingPinCode,
      billingFirstName: billingFirstName,
      billingLastName: billingLastName,
    };

    const onlineOrderData = {
      onlineOrderMaster,
      onlineOrderDetails,
      customerDetails,
    };

    apiRequest("POST", "saveOnlineOrderData", onlineOrderData, false, false)
      .then((res) => {
        if (res.isSuccess) {
          const storedData = localStorage.getItem("userData") || " {}";
          const userData = JSON.parse(storedData);
          apiRequest(
            "DELETE",
            `api/cart/${userData.uuid}`,
            null,
            false,
            false
          ).then((res) => {
            navigate("/shop/confirmation");
          });
        } else {
          toast.error("Something went wrong. Please Try Again!!!");
        }
      })
      .catch((error) => {
        console.log("Error sending Section-C Data", error);
      });
  };

  return (
    <ShippingLayout className="checkout">
      <h2>Contact Information</h2>
      <div className="row">
        <div className="col">
          <input
            type="text"
            placeholder="Email"
            name="email"
            value={emailId}
            onChange={(e) => {
              setEmailId(e.target.value);
            }}
            onBlur={validateEmail}
          />
          <div className="err">{emailError}</div>
        </div>
        <div className="col">
          <input
            type="number"
            placeholder="Mobile Number"
            name="phone"
            value={phone}
            onChange={(e) => {
              if (e.target.value.length <= 10) {
                setPhone(e.target.value);
              }
            }}
            onBlur={validatePhone}
          />
          <div className="err">{phoneError}</div>
        </div>
      </div>
      <h2 id="sipping-address">Shipping address</h2>
      {/*  <div className="row" style={{ marginTop: 20 }}>
          <div className="col">
          <select
            id="address-type"
            value={selectedAddress}
            onChange={(e) => {
              setSelectedAddress(e.target.value);
            }}
            // onBlur={formik.handleBlur}
          >
            <option value="">Select Saved Address</option>
            {savedAddressList.length > 0 ? (
              savedAddressList.map((addressHistorys) => (
                <option
                  key={addressHistorys.CustomerAddressMasterId}
                  value={addressHistorys.CustomerAddressMasterId}
                >
                  AddressType: {addressHistorys.AddressType}, Address:{" "}
                  {addressHistorys.Address}
                </option>
              ))
            ) : (
              <option value="">No saved addresses</option>
            )}
          </select>
        </div>
        <div className="col">
          <select
            id="address-type"
            name="addressType" // Add the 'name' attribute for Formik to identify the field
            value={selectedAddressType}
            onChange={(e) => {
              setSelectedAddressType(e.target.value);
            }}
            onBlur={validateAddressType}
          >
            <option value="">Select an address type</option>
            <option value="Home">Home</option>
            <option value="Work">Work</option>
            <option value="Other">Other</option>
          </select>
          <div className="err">{addressTypeError}</div>
        </div>
      </div>*/}
      <div className="row" style={{ marginTop: 20 }}>
        <div className="col">
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            value={shippingFirstName}
            onChange={(e) => {
              setShippingFirstName(e.target.value);
            }}
            onBlur={validateShippingFirstName}
          />
          <div className="err">{shippingFirstNameError}</div>
        </div>
        <div className="col">
          <input
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={shippingLastName}
            onChange={(e) => {
              setShippingLastName(e.target.value);
            }}
            onBlur={validateShippingLastName}
          />
          <div className="err">{shippingLastNameError}</div>
        </div>
      </div>
      <input
        type="text"
        placeholder="Address"
        id="address"
        name="address"
        value={shippingAddress}
        onChange={(e) => {
          setShippingAddress(e.target.value);
        }}
        onBlur={validateShippingAddress}
      />
      <div className="err">{shippingAddressError}</div>
      <div className="row" style={{ marginTop: 20 }}>
        <div className="col">
          <select
            id="address-type"
            name="state"
            value={shippingState}
            onChange={(e) => {
              setShippingState(e.target.value);
            }}
            onBlur={validateShippingState}
          >
            <option>State</option>
            {stateList.length > 0
              ? stateList
                  .slice() // Create a shallow copy of the array to avoid mutating the original array
                  .sort((a, b) => a.StateName.localeCompare(b.StateName)) // Sort the array alphabetically
                  .map((state) => (
                    <option key={state.StateId} value={state.StateId}>
                      {state.StateName}
                    </option>
                  ))
              : ""}
          </select>

          <div className="err">{shippingStateError}</div>
        </div>
        <div className="col">
          <select
            id="address-type"
            name="city"
            value={shippingCity}
            onChange={(e) => {
              setShippingCity(e.target.value);
            }}
            onBlur={validateShippingCity}
          >
            <option>City</option>
            {cityList.length > 0
              ? cityList
                  .slice() // Create a shallow copy of the array to avoid mutating the original array
                  .sort((a, b) => a.CityName.localeCompare(b.CityName)) // Sort the array alphabetically
                  .map((city) => (
                    <option key={city.CityId} value={city.CityId}>
                      {city.CityName}
                    </option>
                  ))
              : ""}
          </select>
          <div className="err">{shippingCityError}</div>
        </div>
      </div>
      <div className="row" style={{ marginTop: 20 }}>
        <div className="col">
          <select
            id="address-type"
            name="area"
            value={shippingArea}
            onChange={(e) => {
              setShippingArea(e.target.value);
            }}
            onBlur={validateShippingArea}
          >
            <option value="" disabled>
              Select an Area
            </option>
            {areaList && areaList.length > 0
              ? areaList
                  .slice() // Create a shallow copy of the array to avoid mutating the original array
                  .sort((a, b) => a.AreaName.localeCompare(b.AreaName)) // Sort the array alphabetically
                  .map((area) => (
                    <option key={area.AreaId} value={area.AreaId}>
                      {area.AreaName}
                    </option>
                  ))
              : null}
          </select>

          <div className="err">{shippingAreaError}</div>
        </div>
        <div className="col">
          <input
            type="number"
            placeholder="Pincode"
            name="pinCode"
            value={shippingPinCode}
            onChange={(e) => {
              if (e.target.value.length <= 6) {
                setShippingPinCode(e.target.value);
              }
            }}
            onBlur={validateShippingPinCode}
          />
          <div className="err">{shippingPinCodeError}</div>
        </div>
      </div>
      <div className="row" style={{ marginTop: 20 }}>
        <div className="col">
          <select
            id="address-type"
            name="country" // Add the 'name' attribute for Formik to identify the field
            value={shippingCountry}
            onChange={(e) => {
              setShippingCountry(e.target.value);
            }}
            onBlur={validateShippingCountry}
          >
            <option>Country</option>
            {countryList.length > 0
              ? countryList.map((country) => (
                  <option key={country.CountryId} value={country.CountryId}>
                    {country.CountryName}
                  </option>
                ))
              : ""}
          </select>
          <div className="err">{shippingCountryError}</div>
        </div>
        {/* <div className="col">
          <select
            id="address-type"
            name="chemist"
            value={selectedChemist}
            onChange={(e) => {
              setSelectedChemist(e.target.value);
            }}
            onBlur={validateShippingChemist}
          >
            <option>Nearby Chemist</option>
            {chemistList.length === 0 ? (
              <option value="" disabled>
                No Chemist Found
              </option>
            ) : chemistList.length > 0 ? (
              chemistList.map((chemist) => (
                <option key={chemist.ChemistId} value={chemist.ChemistId}>
                  {chemist.ChemistName +
                    " - " +
                    chemist.Address +
                    "," +
                    chemist.PinCode.replace(/\n/g, "&#10;")}
                </option>
              ))
            ) : (
              ""
            )}
          </select>
          <div className="err">{chemistError}</div>
        </div> */}
      </div>
      <div className="row" style={{ marginTop: 20 }}>
        <div className="col" style={{ width: "100%" }}>
          {shippingArea && (
            <div>
              <h3 style={{ marginBottom: 20 }}>
                Available Chemists in{" "}
                {areaList.filter((x) => x.AreaId == shippingArea)[0].AreaName}
              </h3>
              {chemistList.length > 0 ? (
                <ul>
                  {chemistList.map((chemist) => (
                    <li key={chemist.ChemistId}>
                      <label
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "20px",
                        }}
                      >
                        <input
                          type="checkbox"
                          id="same-as-billing"
                          checked={selectedChemist === chemist.ChemistId}
                          onChange={() =>
                            handleCheckboxChange(chemist.ChemistId)
                          }
                          style={{ margin: "6px", width: "10%" }}
                        />
                        {chemist.ChemistName} - {chemist.Address},{" "}
                        {chemist.PinCode.replace(/\n/g, "\n")}
                      </label>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No Chemists Found</p>
              )}
            </div>
          )}

          <div className="err">{chemistError}</div>
        </div>
      </div>
      {/* <div>
        <h2 id="sipping-address">Billing address</h2>
        <div className="check-box">
          <label>
            <input
              type="checkbox"
              name="sameAsBilling"
              id="same-as-billing"
              checked={sameAsShippingAddress}
              onChange={() => {
                if (!sameAsShippingAddress) {
                  if (validateForm(false)) {
                    console.log("hello");
                    setBillingFirstName(shippingFirstName);
                    setBillingLastName(shippingLastName);
                    setBillingAddress(shippingAddress);
                    setBillingArea(
                      areaList.filter((x) => x.AreaId == shippingArea)[0]
                        .AreaName
                    );
                    setBillingCity(
                      cityList.filter((x) => x.CityId == shippingCity)[0]
                        .CityName
                    );
                    setBillingState(
                      stateList.filter((x) => x.StateId == shippingState)[0]
                        .StateName
                    );
                    setBillingCountry(
                      countryList.filter(
                        (x) => x.CountryId == shippingCountry
                      )[0].CountryName
                    );
                    setBillingPinCode(shippingPinCode);
                    setSameAsShippingAddress(true);
                  } else {
                    toast.error("Please fill shipping address first!!!");
                    setSameAsShippingAddress(false);
                  }
                } else {
                  setSameAsShippingAddress(false);
                }
              }}
            />
            Same as Shipping Address
          </label>
        </div>
        <div className="row" style={{ marginTop: 20 }}>
          <div className="col">
            <input
              type="text"
              placeholder="First Name"
              name="billingFirstName"
              value={billingFirstName}
              onChange={(e) => {
                setBillingFirstName(e.target.value);
              }}
              onBlur={validateBillingFirstName}
            />
            <div className="err">{billingFirstNameError}</div>
          </div>
          <div className="col">
            <input
              type="text"
              placeholder="Last Name"
              name="billingLastName"
              value={billingLastName}
              onChange={(e) => {
                setBillingLastName(e.target.value);
              }}
              onBlur={validateBillingLastName}
            />
            <div className="err">{billingLastNameError}</div>
          </div>
        </div>
        <div
          className="row"
          style={{ marginTop: 20, display: "flex", flexDirection: "column" }}
        >
          <input
            type="text"
            placeholder="Address"
            name="billingAddress"
            value={billingAddress}
            onChange={(e) => {
              setBillingAddress(e.target.value);
            }}
            onBlur={validateBillingAddress}
          />
          <div className="err">{billingAddressError}</div>
        </div>
        <div className="row" style={{ marginTop: 20 }}>
          <div className="col">
            <input
              type="text"
              placeholder="State"
              name="billingState"
              value={billingState}
              onChange={(e) => {
                setBillingState(e.target.value);
              }}
              onBlur={validateBillingState}
            />
            <div className="err">{billingStateError}</div>
          </div>
          <div className="col">
            <input
              type="text"
              placeholder="City"
              name="billingCity"
              value={billingCity}
              onChange={(e) => {
                setBillingCity(e.target.value);
              }}
              onBlur={validateBillingCity}
            />
            <div className="err">{billingCityError}</div>
          </div>
        </div>
        <div className="row" style={{ marginTop: 20 }}>
          <div className="col">
            <input
              type="text"
              placeholder="Area"
              name="billingArea"
              value={billingArea}
              onChange={(e) => {
                setBillingArea(e.target.value);
              }}
              onBlur={validateBillingArea}
            />
            <div className="err">{billingAreaError}</div>
          </div>
          <div className="col">
            <input
              type="number"
              placeholder="Pincode"
              name="billingPinCode"
              value={billingPinCode}
              onChange={(e) => {
                if (e.target.value.length <= 6) {
                  setBillingPinCode(e.target.value);
                }
              }}
              onBlur={validateBillingPinCode}
            />
            <div className="err">{billingPinCodeError}</div>
          </div>
        </div>
        <div className="row" style={{ marginTop: 20 }}>
          <div className="col">
            <input
              type="text"
              placeholder="Country"
              name="billingCountry"
              value={billingCountry}
              onChange={(e) => {
                setBillingCountry(e.target.value);
              }}
              onBlur={validateBillingCountry}
            />
            <div className="err">{billingCountryError}</div>
          </div> 
        </div>
            </div> */}
      {/* <div className="bootom-check">
        <div className="check-box">
          <input
            type="checkbox"
            id="save"
            checked={saveInformation}
            onChange={() => setSaveInformation(!saveInformation)}
          />
          <label htmlFor="save">Save this information for next time</label>
        </div>
      </div> */}
      <div className="btn-section">
        <div className="back" onClick={() => navigate("/shop/cart")}>
          <img src={icons.leftArrow} />
          <span>Return to cart</span>
        </div>
        <button type="submit" onClick={handleSubmit}>
          Confirm Order
        </button>
      </div>
      <Toaster position="top-right" />
    </ShippingLayout>
  );
};

export default Checkout;
