import apiRequest from "../../../apiCall";


const callAdminUsersApi = async () => {
    return new Promise((resolve, reject) => {
      apiRequest('GET', 'admin-users', null, true, true).then(res => {
        sessionStorage.setItem("adminApi", JSON.stringify(res));
        resolve(res);
      
      })
          .catch(error => {
              console.error('Error calling home API:', error);
              reject(error);
          });
  });

}

export default callAdminUsersApi;