import api from "../../../api";

const callShopApi = (ip) => {
  return new Promise((resolve, reject) => {
    api.get(`/shop/item-detail/${ip}`).then((res) => {
      sessionStorage.setItem("shopApi", JSON.stringify(res.data));
      resolve(res.data);
    });
  });
};

export default callShopApi;
