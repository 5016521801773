import React, { useEffect, useState } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import "../../css/shop.css";
import apiRequest from "../../apiCall";
import config from "../../pages/Admin/Config.json";

const Products = ({ excludeId, redirect }) => {
  const [product, setProducts] = useState([]);
  useEffect(() => {
    if (!!excludeId) {
      apiRequest("GET", "recommended-products/", null, false, false)
        .then((res) => {
          if (res) {
            const filteredProducts = res.filter(
              (item) =>
                item.heading.toLowerCase() !== excludeId.toLowerCase().replaceAll("-"," ")
            );
            setProducts(filteredProducts);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  }, [excludeId]);

  document.title = "Shop - keshroot";
  return (
    product &&
    product.length > 0 && (
      <>
        <p className="shop-reco-h3">Recommend products</p>
        <div className="shop" style={{ overflow: "auto" }}>
          <div className="shop-reco-div1">
            {product.map((item) => (
              <>
                <div
                  className="shop-reco-link"
                  key={item.itemId}
                  onClick={() => {
                    const productName = item.heading.replaceAll(" ", "-");
                    redirect(`/shop/${productName}`);
                  }}
                >
                  <div className="shop-reco-div">
                    <img
                      className="shop-reco-img"
                      src={config.awsMediaPath + item.productImage}
                      alt={item.heading}
                    />
                    <p className="shop-p-reco">{item.heading}</p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </>
    )
  );
};

export default Products;
