import React, { useEffect, useState } from "react";
import { callSectionE } from "../../callApi/home";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/slices";
import { fileUpload } from "../../../../fileUpload";
import MediaPicker from "../mediaePicker";
import icons from "../../../../constants/icons";
import config from "../../Config.json";

const SectionE = () => {
  const storedData = localStorage.getItem("adminData") || " {}";
  const adminData = JSON.parse(storedData);
  const AdminId = adminData._id || null;

  const [data, setData] = useState({
    AdminId: AdminId,
    title: "",
    item: [
      {
        title: "",
        image: { image: "", file: "", status: "none" },
      },
    ],
  });


  const _data = data;
  const [option, setOption] = useState(false);

  const updateData = () => {
    setData((data) => ({
      ...data,
      ..._data,
    }));
  };

  const isEmpty = () => {
    let res = false;
    data.item.forEach((item) => {
      if (item.title && item.image) {
        res = true;
      } else {
        res = false;
      }
    });
    return res;
  };

  const dispatch = useDispatch();

  // function for saving the changes
  const handleSave = async () => {
    dispatch(setLoading(true));
    let isValidate = isEmpty();
    if (isValidate) {
      let updatedImages = data.item.reduce((accumulator, item) => {
        if (item.image.status === "updated" && item.image.file != null) {
          return accumulator.concat(item.image);
        }
        return accumulator;
      }, []);

      let media = [];

      if (updatedImages.length > 0) {
        fileUpload(updatedImages).then((uploadImageRes) => {
          if (uploadImageRes?.length > 0) {
            media = data.item.map((x) => {
              if (x?.image?.status === "updated") {
                return uploadImageRes.filter(
                  (y) => y.fileName == x.image.file.name
                )[0]?.key;
              } else {
                return x.image.image;
              }
            });
            saveData(media);
          }
        });
      } else {
        media = data.item.map((x) => x.image.image);
        saveData(media);
      }
    } else {
      dispatch(setLoading(false));
      window.M.toast({ html: "Exact 3 ads have to be added" });
    }
  };

  const saveData = (media) => {
    const params = {
      media: media,
      title: data.title,
      AdminId: AdminId,
      item: data.item.map((item) => {
        return { title: item.title };
      }),
      showinwebsite: option ? 1 : 0,
    };

    callSectionE(params).then((res) => {
      if (res) {
        dispatch(setLoading(false));
        window.M.toast({ html: "Data saved successfully" });
      } else {
        dispatch(setLoading(false));
        window.M.toast({ html: "Something went wrong" });
      }
    });
  };

  useEffect(() => {
    const storedData = JSON.parse(sessionStorage.getItem("homeApi"));
  
    if (storedData?.sectionE) {
      const secEData = storedData.sectionE;
  
      
      _data.title = secEData.heading;
      secEData.content.forEach((item, index) => {
        if (!_data.item) {
          _data.item = []; 
        }
        _data.item[index] = {
          title: item.title,
          image: { image: secEData.media[index] },
          imageName: config.awsMediaPath + secEData.media[index],
        };
      });
      setOption(secEData.showinwebsite === 1);
  
      updateData();
    } else {
      console.error("Data not found or invalid in sessionStorage.");
    }
  }, []);
  
  
  return (
    <div className="sec section-e">
      <h2>Section - E</h2>
      <main>
        <div className="input-field">
          <input
            id="section-e-title"
            type="text"
            className="validate"
            onChange={(text) => {
              text = text.currentTarget.value;
              const _data = data;
              _data.title = text;
              setData((data) => ({
                ...data,
                ..._data,
              }));
            }}
            value={data.title}
          />
          <label for="section-e-title">Title</label>
        </div>
        <div className="videos card">
          <h3>YouTube videos</h3>
          <div className="add">
            <img
              src={icons.pick2}
              style={{ width: "100px", cursor: "pointer" }}
              onClick={() => {
                data.item.length<3 ? 
                _data.item.push({
                  name: "",
                  review: "",
                  date: "",
                  image: { image: "", file: "", status: "none" },
                }) :  window.M.toast({ html: "You can add 3 ads only" });
                ;
                updateData();
              }}
            />
          </div>
          <main>
            {data.item.map((item, index) => (
              <div className="card">
                <div className="input-field">
                  <input
                    id={"section-i-card-title" + index}
                    type="text"
                    className="validate"
                    onChange={(text) => {
                      text = text.currentTarget.value;
                      _data.item[index].title = text;
                      updateData();
                    }}
                    value={item.title}
                  />
                  <label for={"section-i-card-title" + index}>
                    Add YouTube Link
                  </label>
                </div>

                <MediaPicker
                  type="image"
                  source={item.imageName}
                  onChange={(file, image) => {
                    _data.item[index].image.file = file;
                    _data.item[index].image.image = image;
                    _data.item[index].imageName = image;
                    _data.item[index].image.status = "updated";
                    updateData();
                  }}
                />
                <button
                  className="btn"
                  onClick={() => {
                    const newArray = _data.item.filter(
                      (item) => _data.item.indexOf(item) !== index
                    );
                    _data.item = newArray;
                    updateData();
                  }}
                >
                  Remove
                </button>
              </div>
            ))}
          </main>
        </div>
      </main>
      <div className="input-field">
        <label>
          <input
            type="checkbox"
            checked={option}
            onChange={() => setOption(!option)}
          />
          <span>Is Active</span>
        </label>
      </div>
      <button className="save btn" onClick={handleSave}>
        Save
      </button>
    </div>
  );
};

export default SectionE;
