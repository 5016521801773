import apiRequest from "../../../apiCall";


const callBlogCommentApi = async () => {
    return new Promise((resolve, reject) => {
        apiRequest('GET', 'blogcomment', null, true, true).then(res => {
          sessionStorage.setItem("blogApi", JSON.stringify(res));
          resolve(res);
          
        })
            .catch(error => {
                console.error('Error calling home API:', error);
                reject(error);
            });
    });
   
}


export default callBlogCommentApi;