// import React, { useState, useEffect } from 'react';
// import { useSwipeable } from 'react-swipeable';

// import { useSelector } from "react-redux";

// const Carousel = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);

//     const [data, setData] = useState(null);

//     const reduxdata = useSelector(state => state.homeApi.value);

//     useEffect(() => {
//         if (reduxdata) {
//             setData(reduxdata.sectionI);
//         };
//     }, [reduxdata]);

//     console.log(data);

//   const products = [
//     {
//       image: require('../../assets/images/oil.png').default,
//       name: 'Keshroot - Hair oil'
//     },
//     {
//       image: require('../../assets/images/shampoo.png').default,
//       name: 'Keshroot - Shampoo'
//     },
//     // Add more products as needed
//   ];

//   useEffect(() => {
//     const timer = setInterval(() => {
//       if (currentSlide < products.length - 2) {
//         setCurrentSlide(prevSlide => prevSlide + 1);
//       } else {
//         setCurrentSlide(0);
//       }
//     }, 9000); // Change slide every 3 seconds

//     return () => {
//       clearInterval(timer);
//     };
//   }, [currentSlide, products.length]);

//   const handleSwipe = (direction) => {
//     if (direction === 'LEFT' && currentSlide < products.length - 2) {
//       setCurrentSlide(prevSlide => prevSlide + 1);
//     } else if (direction === 'RIGHT' && currentSlide > 0) {
//       setCurrentSlide(prevSlide => prevSlide - 1);
//     }
//   }

//   const swipeHandlers = useSwipeable({
//     onSwipedLeft: () => handleSwipe('LEFT'),
//     onSwipedRight: () => handleSwipe('RIGHT')
//   });

//   return (
//     <>
//      <center><h2>Product</h2></center>
//     <div className="carousel-container" {...swipeHandlers}>
//       <div className="carousel" style={{ transform: `translateX(-${currentSlide * 50}%)` }}>
//         {products.map((product, index) => (
//           <div className="carousel-item" key={index}>
//             <img src={product.image} alt={product.name} />
//             <h3>{product.name}</h3>
//             <a href="#" className="buy-now">Buy Now</a>
//           </div>
//         ))}
//       </div>
//     </div></>
//   );
// }

// export default Carousel;

// import React, { useState, useEffect } from 'react';
// import { useSwipeable } from 'react-swipeable';
// import { useSelector } from "react-redux";

// const Carousel = () => {
//   const [currentSlide, setCurrentSlide] = useState(0);
//   const [data, setData] = useState(null);
//   const reduxdata = useSelector(state => state.homeApi.value);

//   useEffect(() => {
//     if (reduxdata) {
//       setData(reduxdata.sectionI);
//     }
//   }, [reduxdata]);

//   const products = data?.content.map((item, index) => ({
//     image: data.media[index],
//     name: item.title
//   }));

//   useEffect(() => {
//     const timer = setInterval(() => {
//       if (currentSlide < products.length - 2) {
//         setCurrentSlide(prevSlide => prevSlide + 1);
//       } else {
//         setCurrentSlide(0);
//       }
//     }, 9000); // Change slide every 3 seconds

//     return () => {
//       clearInterval(timer);
//     };
//   }, [currentSlide, products.length]);

//   const handleSwipe = (direction) => {
//     if (direction === 'LEFT' && currentSlide < products.length - 2) {
//       setCurrentSlide(prevSlide => prevSlide + 1);
//     } else if (direction === 'RIGHT' && currentSlide > 0) {
//       setCurrentSlide(prevSlide => prevSlide - 1);
//     }
//   }

//   const swipeHandlers = useSwipeable({
//     onSwipedLeft: () => handleSwipe('LEFT'),
//     onSwipedRight: () => handleSwipe('RIGHT')
//   });

//   return (
//     <>
//       <center><h2>Product</h2></center>
//       <div className="carousel-container" {...swipeHandlers}>
//         <div className="carousel" style={{ transform: `translateX(-${currentSlide * 50}%)` }}>
//           {products.map((product, index) => (
//             <div className="carousel-item" key={index}>
//               <img src={product.image} alt={product.name} id='pimage' />
//               <h3>{product.name}</h3>
//               <a href="#" className="buy-now">Buy Now</a>
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );
// }

// export default Carousel;

import React, { useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { useSelector } from "react-redux";
import config from "../../pages/Admin/Config.json";
import apiRequest from "../../apiCall";
import { useNavigate } from "react-router-dom";

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [data, setData] = useState(null);
  const reduxdata = useSelector((state) => state.homeApi.value);

  useEffect(() => {
    apiRequest("GET", "recommended-products/", null, false, false)
      .then((res) => {
        if (res) {
          setData(res);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      const itemsPerSlide = getItemsPerSlide();
      if (currentSlide < data.length - itemsPerSlide) {
        setCurrentSlide((prevSlide) => prevSlide + 1);
      } else {
        setCurrentSlide(0);
      }
    }, 5000); // Change slide every 5 seconds

    return () => {
      clearInterval(timer);
    };
  }, [currentSlide, data]);

  const handleSwipe = (direction) => {
    const itemsPerSlide = getItemsPerSlide();

    if (direction === "LEFT" && currentSlide < data.length - itemsPerSlide) {
      setCurrentSlide((prevSlide) => prevSlide + 1);
    } else if (direction === "RIGHT" && currentSlide > 0) {
      setCurrentSlide((prevSlide) => prevSlide - 1);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleSwipe("LEFT"),
    onSwipedRight: () => handleSwipe("RIGHT"),
  });

  const calculateTranslateX = () => {
    const itemsPerSlide = getItemsPerSlide();
    const translateValue = -(currentSlide * (100 / itemsPerSlide));
    return `translateX(${translateValue}%)`;
  };

  const getItemsPerSlide = () => {
    return window.innerWidth >= 600 ? 2 : 1;
  };

  const navigate = useNavigate();

  return (
    <>
      <center>
        <h2>Product</h2>
      </center>
      <div className="carousel-container" {...swipeHandlers}>
        <div className="carousel" style={{ transform: calculateTranslateX() }}>
          {data &&
            data.map((product, index) => (
              <div className="carousel-item" key={index}>
                <div className="carousel-div-img">
                  <img
                    src={config.awsMediaPath + product.productImage}
                    alt={product.heading}
                    id="pimage"
                  />
                </div>

                <h3>{product.heading}</h3>
                <a
                  className="buy-now"
                  onClick={() => {
                    navigate(`/shop/${product.heading.replaceAll(" ", "-")}`);
                  }}
                >
                  Buy Now
                </a>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Carousel;
