import React, { useEffect, useState } from "react";
import SideBar from "../components/sideBar";
import ReactPaginate from "react-paginate";

import { exportToExcel, exportToCSV } from "./utils/exportUtils";
import callNewsLetterApi from "../callApi/newsletter";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices";
import formatDate from "../../../components/dateFormatter";

const ITEMS_PER_PAGE = 10;
const Newsletter = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [usersData, setUsersData] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoading(true));
    callNewsLetterApi().then((res) => {
      dispatch(setLoading(false));
      setUsersData(res);
    });
  }, []);

  const handleExportExcel = () => {
    exportToExcel(usersData, "newsletter"); // Call the exportToExcel utility function
  };

  const handleExportCSV = () => {
    exportToCSV(usersData, "newsletter"); // Call the exportToCSV utility function
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  const paginatedData = usersData.slice(
    currentPage * ITEMS_PER_PAGE,
    (currentPage + 1) * ITEMS_PER_PAGE
  );

  return (
    <div className="page contact-us">
      <SideBar />
      <div className="container">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <h2>News Letter</h2>
          <div style={{ marginLeft: "auto" }}>
            <button className="btn save" onClick={handleExportExcel}>
              Export to Excel
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button className="btn save" onClick={handleExportCSV}>
              Export to CSV
            </button>
          </div>
        </div>
        <br />
        <table className="card">
          <tbody>
            <tr>
              <th>Email</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
            {Array.isArray(paginatedData) && paginatedData.length > 0 ? (
            paginatedData.map((item) => (
              <tr key={item.id}>
                {" "}
                {/* Make sure to replace 'id' with your actual unique identifier */}
                <td>{item.email}</td>
                <td>{formatDate(item.date)}</td>
                <td>{item.status}</td>
              </tr>
            ))
            ):(
              <tr>
                <td colSpan="5">No Record Found</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="newsletterPagination">
        <ReactPaginate
          pageCount={Math.ceil(usersData.length / ITEMS_PER_PAGE)}
          pageRangeDisplayed={5}
          marginPagesDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        />
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
