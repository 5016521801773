import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import config from "../../pages/Admin/Config.json";


const Collabs = () => {
  const [data, setData] = useState(null);
  const reduxdata = useSelector((state) => state.homeApi.value);

  useEffect(() => {
    if (reduxdata) {
      setData(reduxdata.sectionD);
    }
  }, [reduxdata]);

  const collabs = data?.content.map((item, index) => ({
    image: data.media[index],
    name: item.title,
  }));

  // const collab = [
  //   {
  //     image: img1,
  //     link: "https://www.instagram.com/reel/CqkMOkiP61v/?igshid=NjIwNzIyMDk2Mg%3D%3D",
  //   },
  //   {
  //     image: img2,
  //     link: "https://www.instagram.com/reel/CqkMOkiP61v/?igshid=NjIwNzIyMDk2Mg%3D%3D",
  //   },
  //   {
  //     image: img3,
  //     link: "https://www.instagram.com/reel/CqkMOkiP61v/?igshid=NjIwNzIyMDk2Mg%3D%3D",
  //   },
  //   {
  //     image: img4,
  //     link: "https://www.instagram.com/reel/CqkMOkiP61v/?igshid=NjIwNzIyMDk2Mg%3D%3D",
  //   },
   
  // ]
  


  const handleImageClick = (name)=>{
    window.open(name,'_blank');
  };

  return (
    <div className='collabs'>
      <h2>Influencer Collabs</h2>
      <br />
      <br />
      <div className='collab-images-container' style={{ overflowX: 'auto', whiteSpace: 'nowrap', width: '100%', padding: '10px 0', margin: '0 auto', textAlign: 'center' }}>
  {
    collabs &&
    collabs.map((item, index) => (
      <div key={index} style={{ display: 'inline-block', margin: '0 10px', cursor: 'pointer', maxWidth: '250px' }} onClick={() => handleImageClick(item.name)}>
        <img className="collab-thumbnail" src={config.awsMediaPath + item.image} style={{ width: '100%', height: 'auto', borderRadius: '8px', objectFit: 'fill', paddingBottom: '56.25%' }} />
      </div>
    ))
  }
</div>

    </div>
  );
};

export default Collabs;
