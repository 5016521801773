import * as React from "react";
import { useEffect, useState } from "react";

import images from '../../../constants/images';

import MediaPicker from "../components/mediaePicker";

import api from '../../../api';

import SideBar from "../components/sideBar";

import Axios from "axios";
import TextField from "@mui/material/TextField";

function Links() {
  const [name, setName] = useState("");
  const [link, setLink] = useState("");
  const [list, setList] = useState([]);
  const [newReview, setNewReview] = useState("");

  // read
  useEffect(() => {
    api.get("/links").then((response) => {
     
      console.error(response);
      setList(response.data);
    });
  }, []);

  //create
  const submit = () => {
    if(name!="" && link !=""){
      api.post("/links", {
      name: name,
      link: link,
    });

    setList([
      ...list,
      {
        name: name,
        link: link,
      },
    ]);

    window.location.reload(true);
    }else{
      window.M.toast({html:'Please fill this field'});
    }
    

    
  };

  //delete
  const deleteReview = (id) => {
    api.delete(`/links/del/${id}`)
      .then((response) => {
      })
      .catch((error) => {
        console.log(error.message);
      });
    window.location.reload(true);
  };

  // update
  const updateReview = (id) => {
    api.put(`/links/update`, {
      id: id,
      link: newReview,
    })
      .then((response) => {
        
      })
      .catch((error) => {
        console.log(error.message);
      });
    setNewReview("");
    window.location.reload(true);
  };

  return (
    <div className="page home">
      <SideBar />
      <div className="container">
        <h4>Social media</h4>
        <div>
          <h5 style={{ marginTop: "2rem" }}> Heading</h5>
          <input
            type="text"
            
            onChange={(e) => {
              setName(e.target.value);
            }}
          ></input>
          <h5> content</h5>
          <input
            type="text"
            onChange={(e) => {
              setLink(e.target.value);
            }}
          ></input>
          <button
            style={{
              marginBottom: "2rem",
              backgroundColor: "#9c4727",
              color: "white",
              width: "10rem",
              height: "3rem",
              margin: "0 20px",
              cursor: "pointer",
              border: 'none'
            }}
            onClick={submit}
          >
            Add
          </button>
        </div>

        <div
          style={{
            display: "flex",
            gap: "1.5rem",
            flexWrap: "wrap",
            marginTop: "2rem",
          }}
        >
          {list.map((val) => {
            return (
              <div
                key={val._id}
                style={{
                  width: "23%",
                  minHeight: "15rem",
                  border: "1px solid white",
                  borderRadius: "5%",
                  background: "#ffffff",
                  marginBottom: "1rem",
                }}
              >
                <p style={{ margin: "1rem" }}>Heading: </p>
                <h6
                  style={{
                    margin: "1rem",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {val.heading}{" "}
                </h6>

                <hr />
                <div style={{ margin: "1rem 0" }}>
                  <p style={{ margin: "1rem" }}> content: </p>
                  {/* <h6 style={{margin:"1rem", textOverflow:"ellipsis", overflow:"hidden" , wordBreak:"break-word"}}> {val.content}</h6> */}
                </div>
                {/* <p style={{margin:"0 1rem"}}> update content: </p>
       <input onChange={(e) => {setNewReview(e.target.value)}} type='text'/> */}
                <TextField
                  style={{ width: "100%" }}
                  // label="Multiline"
                  multiline
                  rows={3}
                  defaultValue={val.content}
                  variant="standard"
                  onChange={(e) => {
                    setNewReview(e.target.value);
                  }}
                />
                <button
                  style={{ backgroundColor: "white", color: "black" }}
                  className="btn-link"
                  onClick={() => {
                    updateReview(val._id);
                  }}
                >
                  Update
                </button>
                <button
                  className="btn-link"
                  onClick={() => {
                    deleteReview(val._id);
                  }}
                >
                  Remove
                </button>
                <input
                  onChange={(e) => {
                    setNewReview(e.target.value);
                  }}
                  type="text"
                />
                {/* <button onClick={() => {updateReview(val._id)}}>Update</button>   */}
              </div>
            );
          })}
        </div>
        <div className="">
          <button
            style={{
              margin: "1rem",
              backgroundColor: "#9c4727",
              color: "white",
              width: "10rem",
              height: "3rem",
              cursor: "pointer",
              border: 'none',
            }}
            onClick={() => {
              // console.log("hello");
              window.M.toast({ html: "Data saved successfully" });
              window.location.reload(true);
            }}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

export default Links;
