import React, { useEffect, useState } from "react";
import axios, { Axios } from "axios";
import icons from "../../../../constants/icons";
import Autocompletes from "./Autocomplete";
import MediaPicker from "../mediaePicker";
import apiRequest from "../../../../apiCall";
import { fileUpload } from "../../../../fileUpload";
import config from "../../Config.json";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/slices";

const Slider = ({ autoComplete, onValueChange }) => {
  const storedData = localStorage.getItem("adminData") || " {}";
  const adminData = JSON.parse(storedData);
  const AdminId = adminData._id || null;
  const [data, setData] = useState({});

  const _data = data;

  const updateData = () => {
    setData((data) => ({
      ...data,
      ..._data,
    }));
  };
  const dispatch = useDispatch();
  const handleSave = async () => {
    dispatch(setLoading(true));
    const uploadImages = data.slider.filter(
      (x) => x.status === "updated" && x.file != null
    );
    const uploadedImages = data.slider.filter((x) => x.status == null);
    if (uploadImages.length > 0) {
      fileUpload(uploadImages).then((uploadImageRes) => {
        if (uploadImageRes?.length > 0) {
          const newUploadedImages = [
            ...uploadedImages.map((x) => x.imageName),
            ...uploadImageRes.map((x) => x.key),
          ];
          dispatch(setLoading(false));
          saveData(newUploadedImages);
        }
      });
    } else {
      saveData([...uploadedImages.map((x) => x.imageName)]);
    }
  };

  const saveData = (uploadedImages) => {
    if (uploadedImages.length == 0) {
      dispatch(setLoading(false));
      window.M.toast({ html: "Please fill the all values" });
      return;
    }

    const params = {
      itemId: JSON.parse(sessionStorage.getItem("shopApi")).itemId,
      productCode: JSON.parse(sessionStorage.getItem("shopApi")).productCode,
      images: uploadedImages,
      AdminId: AdminId,
    };

    apiRequest("POST", "shop/ShopSlider", params, true, true).then((res) => {
      dispatch(setLoading(false));
      window.M.toast({ html: "Data saved successfully" });
      onValueChange(JSON.parse(sessionStorage.getItem("shopApi")).productCode);
    });
  };

  useEffect(() => {
    if (autoComplete) {
      const sliderData = JSON.parse(sessionStorage.getItem("shopApi")).slider;
      _data.slider =
        sliderData && sliderData.length > 0
          ? sliderData?.map((x) => {
              return { image: config.awsMediaPath + x, imageName: x };
            })
          : [];
      updateData();
    } else {
      _data.slider = [];
      updateData();
    }
  }, [autoComplete]);

  return (
    <div className="sec slider">
      <h2 style={{ marginTop: "1rem" }}>Slider</h2>
      <div
        className="add"
        onClick={() => {
          _data.slider.push({ file: null, status: "updated" });
          updateData();
        }}
      >
        <img src={icons.pick2} />
      </div>

      <main style={{ display: "flex", flexWrap: "wrap" }}>
        {data?.slider?.length > 0 &&
          data?.slider?.map((item, index) => (
            <MediaPicker
              onChange={(file, image) => {
                _data.slider[index] = { file, image, status: "updated" };
                updateData();
              }}
              source={data.slider[index].image}
              Delete
              onDelete={() => {
                const newData = _data.slider.filter(
                  (item) => _data.slider.indexOf(item) !== index
                );
                _data.slider = newData;
                updateData();
              }}
            />
          ))}
      </main>
      <button className="save btn" onClick={handleSave}>
        Save
      </button>
    </div>
  );
};

export default Slider;
