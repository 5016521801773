import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import api from "../api";
import icons from "../constants/icons";
import ytlogo from "../assets/images/ytlogo.png";
import wapp from "../assets/images/wapp.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchContactApi } from "../redux/slices";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Please enter valid email")
    .required("Please Enter Your Email"),
});

const Footer = () => {
  const location = useLocation();
  const activepage =
    location.pathname === "/" ? "home" : location.pathname.replace("/", "");

  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();
  const data = useSelector((state) => state.contactApi.value);


  const handleFormSubmit = (values, { resetForm }) => {
    // Show the popup
    setShowPopup(true);

    // Set the email value
    setEmail(values.email);

    // Reset the form
    resetForm();
  };

  const handleSubmit = async () => {
    try {
      const res = await api.post("/newsletter", { email });
      if (res.status === 200) {
        // Handle success
        setShowPopup(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(fetchContactApi());
  }, []);

  return (
    <div className="footer">
      {showPopup && (
        <div className="popup">
          <div className="popup-content">
            <h4>Thank you for subscribing to the Keshroot News letter</h4>
            <br />
            <button onClick={handleSubmit}>Subscribe</button>&nbsp;&nbsp;
            <button onClick={() => setShowPopup(false)}>Close</button>
          </div>
        </div>
      )}
      {data ? (
        <div className="main-footer">
          <section>
            <Link to="/" className="footer-logo-link">
              <img src={icons.logoOrange} className="footer-logo" />
            </Link>
            <a href={`tel:${data.phone}`}>
              <img src={icons.phoneOrange} />
              <span>{data.phone}</span>
            </a>
            <a href={`mailto:${data.email}`} >
              <img src={icons.emailOrange} />
              <span className="email">{data.email}</span>
            </a>
            <a href={`tel:${data.whatsapp}`}>
              <img src={wapp} />
              <span>{data.whatsapp}</span>
            </a>
            <div className="social">
              <a href="https://www.facebook.com/keshroot" target="_blank">
                <img src={icons.facebookOrange} />
              </a>
              {/* <a href='#' target='_blank'>
                        <img src={icons.twitterOrange} />
                    </a> */}
              <a href="https://www.youtube.com/@KESHROOT" target="_blank">
                <img src={ytlogo} />
              </a>
              <a href="https://www.instagram.com/keshroot/" target="_blank">
                <img src={icons.instagramOrange} />
              </a>
            </div>
          </section>
          <section className="links">
            <h2>Useful links</h2>
            <ul>
              <li>
                <Link
                  to="/"
                  className={activepage === "home" ? "active-link" : ""}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/shop"
                  className={activepage === "shop" ? "active-link" : ""}
                >
                  Shop
                </Link>
              </li>
              <li>
                <Link
                  to="/about-us"
                  className={activepage === "about-us" ? "active-link" : ""}
                >
                  About us
                </Link>
              </li>
              <li>
                <Link
                  to="/blog"
                  className={activepage === "blog" ? "active-link" : ""}
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  to="/contact-us"
                  className={activepage === "contact-us" ? "active-link" : ""}
                >
                  Contact us
                </Link>
              </li>
            </ul>
          </section>

          {/*  <section className='links'>
                <h2>Customer Service</h2>
                <ul>
                    <li>
                        <Link to='#'>Help & FAQs</Link>
                    </li>
                    <li>
                        <Link to='#'>Returns Policy</Link>
                    </li>
                    <li>
                        <Link to='#'>Terms & Conditions</Link>
                    </li>
                    <li>
                        <Link to='#'>Privacy Policy</Link>
                    </li>
                    <li>
                        <Link to='#'>Support Center</Link>
                    </li>
                </ul>
            </section>*/}
          <section className="news-letter">
            <h2>News Letter</h2>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}
            >
              <Form>
                <ErrorMessage
                  name="email"
                  component="div"
                  className="erroremail"
                />
                <br />
                <Field type="email" name="email" placeholder="Email address" />
                <br />
                <button type="submit">Submit</button>
              </Form>
            </Formik>
          </section>
        </div>
      ) : (
        <></>
      )}
      <div className="copyright">© 2023 Keshroot. All Rights Reserved</div>
    </div>
  );
};

export default Footer;
