import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import icons from "../constants/icons";
import images from "../constants/images";
import "../App.css";
import Offer from "./offer";

import { authPage, cartUpdate, isCartUpdate, showAuth } from "../redux/slices";
import api from "../api";
import { useState } from "react";
import { setCartUpdate } from "../redux/slices";

const Header = ({ activepage, isAddedToCart }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storedData = localStorage.getItem("userData") || " {}";
  const userData = JSON.parse(storedData);
  const uuid = userData.uuid || null;
  const firstName = userData.FirstName || null;
  const [showPopup, setShowPopup] = useState(false);
  const [quantitycount, setQuantitycount] = useState({ TotalQuantity: 0 });

  const handleClose = () => {
    document.querySelector(".cover").style.display = "none";
    document.querySelector(".side-menu").style.left = "-500px";
  };

  const handleOpen = () => {
    document.querySelector(".cover").style.display = "flex";
    document.querySelector(".side-menu").style.left = "0";
  };

  const cartUpdate = useSelector(isCartUpdate);

  useEffect(() => {
    handleClose();
    window.scrollTo(0, 0);
    dispatch(setCartUpdate(true))
  }, []);

  useEffect(() => {
    if (cartUpdate) {
      api
        .get(`/quantitycount/${uuid}`)
        .then((response) => {
          setQuantitycount(response.data[0]);
          dispatch(setCartUpdate(false))
          // console.log(quantitycount);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [cartUpdate]);

  const handleLogout = () => {
    localStorage.removeItem("userData");
    localStorage.removeItem("userToken");
    // window.location.reload();
    setShowPopup(!showPopup);
    navigate("/");
  };

  return (
    <div className="header">
      <Offer />
      <div className="cover" onClick={handleClose}></div>
      {/* top section */}
      <div className="main-header-section">
        {/* <span className="line"></span> */}
        <div className="header-section">
          {/* nav bar */}
          <div className="nav-bar">
            <section className="brand-logo">
              <Link to="/">
                <img src={images.logo} className="logo" />
              </Link>
            </section>
            <section className="navigations">
              <Link
                to="/"
                className={activepage === "home" ? "active-page" : null}
              >
                Home
              </Link>
              <Link
                to="/shop"
                className={activepage === "shop" ? "active-page" : null}
              >
                Shop
              </Link>
              <Link
                to="/about-us"
                className={activepage === "about-us" ? "active-page" : null}
              >
                About us
              </Link>
              <Link
                to="/blog"
                className={activepage === "blog" ? "active-page" : null}
              >
                Blog
              </Link>
              <Link
                to="/contact-us"
                className={activepage === "contact-us" ? "active-page" : null}
              >
                Contact us
              </Link>
            </section>
            <section className="third-section">
              <div className="icons">
                <Link to="/shop/cart">
                  {" "}
                  <img
                    src={icons.shoppingBag}
                    title="Cart"
                    // onClick={() => dispatch(showCart(true))}
                  />
                  {/* <p>{quantitycount.TotalQuantity}</p> */}{" "}
                  {quantitycount.TotalQuantity >= 1 && (
                    <span className="red-dot">
                      {quantitycount.TotalQuantity}
                    </span>
                  )}
                </Link>
                {localStorage.getItem("userToken") === null ? (
                  <img
                    src={icons.user}
                    title="Login"
                    onClick={() => {
                      dispatch(showAuth(true));
                      dispatch(authPage("login"));
                    }}
                  />
                ) : (
                  <span
                    className="headerfirstname"
                    onClick={() => {
                      setShowPopup(!showPopup);
                    }}
                  >
                    {firstName}
                  </span>
                )}
              </div>
            </section>
            {showPopup && (
              <div className="user-popup">
                <button
                  style={{
                    cursor: "pointer",
                    float: "right",
                    fontSize: "20px",
                  }}
                  className="delete-cart"
                  onClick={() => setShowPopup(!showPopup)}
                >
                  <b>X</b>
                </button>
                <ul>
                  <li>
                    <Link
                      to="/myprofile"
                      className={
                        activepage === "myprofile" ? "active-page" : null
                      }
                    >
                      My Profile
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/myorders"
                      className={
                        activepage === "myaccount" ? "active-page" : null
                      }
                    >
                      My Orders
                    </Link>
                  </li>
                  {/* <li><Link to="/resetPassword">
                    Change Password</Link></li> */}
                  <li onClick={handleLogout} style={{ cursor: "pointer" }}>
                    <Link>Logout</Link>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mobile-nav">
        <section id="menu-section">
          <div className="menu-icon" onClick={handleOpen}>
            <img src={icons.menu} />
          </div>
        </section>
        <section className="mobile-logo">
          <Link to="/">
            <img src={images.logo} className="mo-logo" />
          </Link>
        </section>
        <section className="third-section">
          <div className="icons">
            <Link to="/shop/cart">
              {" "}
              <img
                src={icons.shoppingBag}
                title="Cart"
                // onClick={() => dispatch(showCart(true))}
              />
              {/* <p>{quantitycount.TotalQuantity}</p> */}{" "}
              {quantitycount.TotalQuantity >= 1 && (
                <span className="red-dot">{quantitycount.TotalQuantity}</span>
              )}
            </Link>
            {localStorage.getItem("userToken") === null ? (
              <img
                src={icons.user}
                title="Login"
                onClick={() => {
                  dispatch(showAuth(true));
                  dispatch(authPage("login"));
                }}
              />
            ) : (
              <span
                className="headerfirstname"
                onClick={() => {
                  setShowPopup(!showPopup);
                }}
              >
                {firstName}
              </span>
            )}
          </div>
        </section>
        {showPopup && (
          <div className="user-popup">
            <button
              style={{
                cursor: "pointer",
                float: "right",
                fontSize: "20px",
              }}
              className="delete-cart"
              onClick={() => setShowPopup(!showPopup)}
            >
              <b>X</b>
            </button>
            <ul>
              <li>
                <Link
                  to="/myprofile"
                  className={activepage === "myprofile" ? "active-page" : null}
                >
                  My Profile
                </Link>
              </li>
              <li>
                <Link
                  to="/myorders"
                  className={activepage === "myaccount" ? "active-page" : null}
                >
                  My Orders
                </Link>
              </li>
              {/* <li><Link to="/resetPassword">
                    Change Password</Link></li> */}
              <li onClick={handleLogout} style={{ cursor: "pointer" }}>
                <Link>Logout</Link>
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className="side-menu">
        <main>
          <Link to="/">Home</Link>
          <Link to="/shop">Shop</Link>
          <Link to="/about-us">About us</Link>
          <Link to="/blog">Blog</Link>
          <Link to="/contact-us">Contact us</Link>
        </main>
        <div className="close" onClick={handleClose}>
          X
        </div>
      </div>
    </div>
  );
};

export default Header;
