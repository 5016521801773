import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import { setCartUpdate, showAuth } from "../../redux/slices";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import icons from "../../constants/icons";
import { FaTrash } from "react-icons/fa";
import Footer from "../../components/footer";
import Header from "../../components/header";
import "../../css/cart.css";
import api from "../../api";
import config from "../../pages/Admin/Config.json";

const ProductCart = () => {
  document.title = "Cart - keshroot";
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [contries, setContries] = useState([]);
  const [selectedContry, setSelectedContry] = useState("India");
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedStates] = useState("Gujarat");
  const [isMobile, setIsMobile] = useState(false);
  const [isNavigate, setIsNavigate] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [bindData, setBindData] = useState(false);
  const [zipCode, setZipCode] = useState("");
  const [cartItems, setCartItems] = useState([]);
  const [total, setTotal] = useState();
  const [clickedProductIds, setClickedProductIds] = useState([]);
  const [isErr, setErr] = useState(false);

  const navigate = useNavigate();

  const storedData = localStorage.getItem("userData") || " {}";
  const userData = JSON.parse(storedData);
  const CustomerId = userData.id || null;

  const fetchCartItems = async () => {
    try {
      const response = await api.get(`/api/cart/${CustomerId}`);
      setCartItems(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchCartItems();
  }, []);

  const handleQuantityAdd = async (itemId, itemPack) => {

    const updatedCartItems = cartItems.map((item) => {
      if (item.Brand === itemId && item.Pack === itemPack) {
        const updatedItem = { ...item, Quantity: item.Quantity + 1 };
        return updatedItem;
      }
      return item;
    });


    const updatedQuantity = updatedCartItems.find(
      (item) => item.Brand === itemId && item.Pack === itemPack
    )?.Quantity;
    console.log(updatedQuantity,"ert")

    try {
      await api.patch(`/api/cart/${CustomerId}/${itemId}/${itemPack}`, {
        Quantity: updatedQuantity, // Send the updated quantity to the server
      });
      dispatch(setCartUpdate(true))
      setCartItems(updatedCartItems);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleQuantitySubtract = async (itemId, itemPack) => {
    const updatedCartItems = cartItems.map((item) => {
      if (
        item.Brand === itemId &&
        item.Pack === itemPack &&
        item.Quantity > 1
      ) {
        const updatedItem = { ...item, Quantity: item.Quantity - 1 };
        return updatedItem;
      }
      return item;
    });

    const updatedQuantity = updatedCartItems.find(
      (item) => item.Brand === itemId && item.Pack === itemPack
    )?.Quantity;
    try {
      await api.patch(`/api/cart/${CustomerId}/${itemId}/${itemPack}`, {
        Quantity: updatedQuantity, // Send the updated quantity to the server
      });
      setCartItems(updatedCartItems);
      dispatch(setCartUpdate(true))
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getTotalCartValue = () => {
    let totalValue = 0;
    cartItems.forEach((item) => {
      totalValue += item.Quantity * item.Price;
    });
    return totalValue;
  };

  const handleDeleteItem = async (itemId, itemPack) => {
    try {
      await api.delete(`/api/cart/${CustomerId}/${itemId}/${itemPack}`);
      dispatch(setCartUpdate(true))
      fetchCartItems(); // Refresh the cart items after deleting an item
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClick = (productId) => {
    setClickedProductIds((prevClickedIds) => {
      if (prevClickedIds.includes(productId)) {
        return prevClickedIds.filter((id) => id !== productId);
      } else {
        return [...prevClickedIds, productId];
      }
    });
  };

  useEffect(() => {
    getContries();
    resize();
  }, []);

  useEffect(() => {
    getStates();
  }, [selectedContry]);

  useEffect(() => {
    // debugger;
    if (suggestions.length > 0) {
      setBindData(true);
    }
  }, [suggestions]);

  const initialValues = {
    zipCode: "",
  };

  const validationSchema = yup.object({
    zipCode: yup
      .string()
      .required("Plaese enter valid postal/ zip code")
      .min(6, "Plaese enter valid postal/ zip code")
      .max(6, "Plaese enter valid postal/ zip code")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Plaese enter valid postal/ zip code"
      ),
  });

  const handleChange = (event) => {
    setZipCode(event.target.value);
  };

  const errorsz = () => toast.error("Please Login.");
  const errorsz2 = () => toast.error("Your Cart is Empty");

  const handleSubmit = () => {
    // Handle form submission or API request
    var bodyFormData = new FormData();
    bodyFormData.append("pinCode", zipCode);
    const options2 = {
      method: "POST",
      url: "http://192.168.1.62:789/chemistMaster/getDataByPincode",
      headers: {
        "accept-version": "4.0.0",
      },
      data: bodyFormData,
    };
    // Rest of the code for handling the request...

    axios
      .request(options2)
      .then(function (response) {
        // console.log(response.data.data.chemistList);
        setSuggestions(response.data.data.chemistList);
        setErr(false);
        // debugger;
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const handleSubmit2 = () => {
    if (CustomerId == null) {
      setIsNavigate(false);
      errorsz();
    } else {
      if (zipCode != "") {
        setIsNavigate(true);
        navigate("/shop/checkout");
      } else {
        setErr(true);
      }
    }
  };

  const getContries = async () => {
    const headers = {
      headers: {
        "X-CSCAPI-KEY":
          "ek5ON1NSQnB2cFhUdnViaVpNUUNGTUhhTVhBVVJCSWlISHg2YUkwVw==",
      },
    };

    const { data } = await axios.get(
      "https://api.countrystatecity.in/v1/countries/",
      headers
    );

    const result = [];
    data.forEach((item) => {
      result.push({ value: item.name, code: item.iso2 });
    });
    setContries(result);
  };

  const headers = {
    headers: {
      "X-CSCAPI-KEY":
        "ek5ON1NSQnB2cFhUdnViaVpNUUNGTUhhTVhBVVJCSWlISHg2YUkwVw==",
    },
  };

  const getStates = async () => {
    const contryCode = contries.find((x) => x.value === selectedContry)
      ? contries.find((x) => x.value === selectedContry).code
      : "IN";

    const { data } = await axios.get(
      "https://api.countrystatecity.in/v1/countries/" + contryCode + "/states",
      headers
    );

    const result = [];
    data.forEach((item) => {
      result.push({ value: item.name });
    });
    setStates(result);
  };

  const resize = () => {
    if (document.body.offsetWidth <= 660) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const { values, errors } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: () => {
      if (isNavigate) {
        navigate("/shop/checkout");
      }
    },
  });

  window.addEventListener("resize", resize);

  return (
    <div style={{width:'100%'}}>
      <Header activepage="shop" />
      <div className="product-cart">
        <h2>Your Cart</h2>
        <div className="main-container">
          <div className="section-a">
            {!isMobile && (
              <>
                <div className="header">
                  <span>Product</span>
                  <span>Price</span>
                  <span>Quantity</span>
                  <span>Total</span>
                </div>

                {cartItems.length > 0 ? (
                  <ul>
                    {cartItems.map((item, index) => {
                      const fetchedImages =
                        config.awsMediaPath + item?.media[0];

                      return (
                        <div
                          className={`product ${
                            clickedProductIds.includes(item.productname)
                              ? "clicked"
                              : ""
                          }`}
                          key={item.id}
                          onClick={() => handleClick(item.productname)}
                        >
                          <section className="first">
                            <img
                              // style={{ width: "2rem", height: "2rem" }}
                              src={fetchedImages}
                              alt={item.Brand}
                            />
                            <span>
                              {" "}
                              {item.Brand} ({item.Pack} {item.UOMValue})
                            </span>
                          </section>
                          <section className="second">
                            <span>{"₹" + item.Price}</span>
                          </section>
                          <section className="third">
                            <div className="quantity">
                              <span
                                onClick={(e) => {
                                  handleQuantitySubtract(item.Brand, item.Pack);
                                  e.stopPropagation();
                                }}
                              >
                                ─
                              </span>
                              {item.Quantity}
                              <span
                                style={{ fontWeight: "bold" }}
                                onClick={(e) => {
                                  handleQuantityAdd(item.Brand, item.Pack);
                                  e.stopPropagation();
                                }}
                              >
                                +
                              </span>
                            </div>
                          </section>
                          <section className="fourth">
                            <span>RS. {item.Quantity * item.Price}</span>
                          </section>
                          <button
                            style={{ cursor: "pointer" }}
                            className="delete-cart"
                            onClick={() =>
                              handleDeleteItem(item.Brand, item.Pack)
                            }
                          >
                            <FaTrash />
                          </button>
                        </div>
                      );
                    })}
                  </ul>
                ) : (
                  <center>No items in the cart</center>
                )}
              </>
            )}
            <br />
          </div>

          {isMobile && (
            <>
              {cartItems.length > 0 ? (
                <>
                  {cartItems.map((item, index) => {
                    const productImage = config.awsMediaPath + item?.media[0];

                    return (
                      <>
                        <div className="product mobile" key={item.id}>
                          {productImage && (
                            <img
                              style={{
                                margin: "0",
                                width: "5rem",
                                height: "5rem",
                              }}
                              src={productImage}
                              alt={item.name}
                            />
                          )}
                          <section>
                            <span >
                              {" "}
                              {item.Brand}
                            </span>
                            <span>{"₹" + item.Price}</span>

                            <div className="quantity">
                              <div
                                className="desc"
                                onClick={() =>
                                  handleQuantitySubtract(
                                    item.productname,
                                    item.Pack
                                  )
                                }
                              >
                                ─
                              </div>
                              <div className="amount">{item.Quantity}</div>
                              <div
                                className="enc"
                                style={{ fontWeight: "bold" }}
                                onClick={() =>
                                  handleQuantityAdd(item.productname, item.Pack)
                                }
                              >
                                +
                              </div>
                            </div>
                            <button
                              style={{ cursor: "pointer", marginLeft: "1rem" }}
                              className="delete-cart"
                              onClick={() =>
                                handleDeleteItem(item.productname, item.Pack)
                              }
                            >
                              <FaTrash />
                            </button>
                          </section>
                        </div>

                        <div className="total">
                          <span>Total</span>
                          <span>RS. {item.total_price}.00</span>
                        </div>
                      </>
                    );
                  })}
                </>
              ) : (
                <p>No items in the cart</p>
              )}
            </>
          )}

          <div className="actions">
            <span onClick={() => navigate("/shop")}>
              <img src={icons.leftArrowCircle} />
              Continue shopping
            </span>

            {/* <span
              onClick={() => {
                window.location.reload(true);
              }}
            >
              <img src={icons.retry} />
              Update
            </span> */}
          </div>
          <main>
            <section className="third">
              <div className="roww">
                <span>Subtotal</span> <span>RS. {getTotalCartValue()}.00</span>
              </div>
              <div
                className="roww"
                style={{ display: "flex", flexDirection: "row-reverse" }}
              >
                {/* <span>Shipping & taxes calculated at checkout</span> */}
                <button
                  onClick={() => {
                    if (CustomerId == null) {
                      dispatch(showAuth(true));
                    } else if (cartItems.length === 0) {
                      errorsz2();
                    } else {
                      navigate("/shop/checkout");
                    }
                  }}
                >
                  Proceed to Checkout
                </button>
              </div>
            </section>
          </main>
        </div>
      </div>
      <Footer />
      <Toaster position="top-right" />
    </div>
  );
};

export default ProductCart;
