import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import api from "../../api";
import Footer from "../../components/footer";
import Header from "../../components/header";
import formatDate from "../../components/dateFormatter";
// import { useFormik } from "formik";
// import * as Yup from "yup";

import "../../css/myaccount.css";
import apiRequest from "../../apiCall";

const MyAccount = () => {
  document.title = "My account - Keshroot";

  const navigate = useNavigate();
  const [orderHistory, setorderHistory] = useState(false);
  const [selectedOrderDate, setSelectedOrderDate] = useState(
    new Date().getFullYear()
  );
  const [showPopup, setShowPopup] = useState(false);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(-1);
  const [orderDetails, setOrderDetails] = useState();
  const [selectedOrderMasterId, setSelectedOrderMasterId] = useState(null); // Add this state
  const storedData = localStorage.getItem("userData") || " {}";
  const userData = JSON.parse(storedData);
  const FirstName = userData.FirstName;
  const LastName = userData.LastName;
  const uuid = userData.id || null;
  const [prevSelectedOrderDate, setPrevSelectedOrderDate] = useState(null);
  // const [showAllOrders, setShowAllOrders] = useState(false);
  const [showSecondTable, setShowSecondTable] = useState(false);
  const [showCancelOrder, setShowCancelOrder] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [buttonCaption, setButtonCaption] = useState("Show All Orders");

  const handleRowLeave = () => {
    setHoveredRowIndex(-1);
  };

  // ... (previous code)

  const handleButtonClick = () => {
    setShowTop10Orders((prevState) => !prevState);
    setButtonCaption((prevCaption) =>
      prevCaption === "Show All Orders"
        ? "Show Top 10 Orders"
        : "Show All Orders"
    );
  };

  // const { values, errors, handleChange, handleSubmit } = formik;
  // const validationSchema = Yup.object().shape({
  //   reason: Yup.string()
  //     .required("Please enter message")
  //     .min(10, "Message is too short"),
  // })

  // const formik = useFormik({
  //   initialValues: {
  //     reason: "",
  //   },
  //   validationSchema,
  //     onSubmit: async (values, { resetForm }) => {
  //     const formData = new FormData();
  //     formData.append("content", JSON.stringify(values));
  //     try {
  //       const res = await api.post("/onlineOrder", formData);
  //       if (res.data.status === 200) {
  //         showCancelOrder(false);
  //         resetForm();
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   },
  // });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showTop10Orders, setShowTop10Orders] = useState(true);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getOrderData = () => {
    api
      .get(`/orderhistory/${uuid}/${selectedOrderDate}`)
      .then((response) => {
        // Sort the data in descending order based on CreatedDate
        const sortedOrderHistory = response.data.sort(
          (a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)
        );
        setorderHistory(sortedOrderHistory);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if (
      selectedOrderDate !== prevSelectedOrderDate &&
      selectedOrderDate !== "disabled"
    ) {
      getOrderData();
      setPrevSelectedOrderDate(selectedOrderDate);
    }
  }, [selectedOrderDate, prevSelectedOrderDate, uuid]);

  const cancelOrderPopup = (orderMasterId) => {
    setShowCancelOrder(true);
    setCancelReason("");
    setSelectedOrderMasterId(orderMasterId);
  };

  const closeOrderPopup = () => {
    setShowCancelOrder(false);
    setSelectedOrderMasterId("");
  };

  const cancelOrder = () => {
    if (cancelReason != "") {
      const onlineOrderMaster = {
        CustomerId: uuid,
        onlineOrderMasterId: selectedOrderMasterId,
        updatedDate: orderHistory.filter(
          (x) => x.OnlineOrderMasterId == selectedOrderMasterId
        )[0]?.UpdatedDate,
        remarks: cancelReason,
      };
      apiRequest(
        "POST",
        "cancelOnlineOrderData",
        onlineOrderMaster,
        false,
        false
      )
        .then((res) => {
          if (res.isSuccess) {
            setShowCancelOrder(false);
            setSelectedOrderMasterId("");
            setCancelReason("");
            window.M.toast({ html: "Order cancelled!!!" });
            getOrderData();
          } else {
            window.M.toast({
              html: "Something went wrong. Please Try Again!!!",
            });
          }
        })
        .catch((error) => {
          console.log("Cancel order error", error);
        });
    }
  };

  const openPopup = (orderMasterId) => {
    setShowPopup(true);
    setSelectedOrderMasterId(orderMasterId);
    api
      .get(`/orderdetails/${orderMasterId}`)
      .then((response) => {
        setOrderDetails(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleRowHover = (index) => {
    setHoveredRowIndex(index);
  };

  return (
    <div className="my-account">
      <Header activepage="myaccount" />
      <div className="cont">
        <div
          className="cont-order"
        >
          <h1>My Orders</h1>
          
          <div style={{}}>
            <button
              onClick={handleButtonClick}
              className="btn save"
              style={{ textAlign: "center", marginLeft: "auto" }}
            >
              {buttonCaption}
            </button>
          </div>
        </div>
        <br />
        <main>
          <div className="rs">
            {/* <br />
            <br /> */}
            {selectedOrderDate && (
              <>
                {/* First Table: Order History */}
                {windowWidth > 900 && (
                  <>
                    {!showSecondTable && (
                      <>
                        {orderHistory.length > 0 ? (
                          <table>
                            <tbody>
                              <tr>
                                <th style={{ width: "10%" }}>Order On</th>
                                <th>Ship To</th>
                                <th>Contact Person</th>
                                <th>Order Number</th>
                                <th>Order Status</th>
                                <th>Reason</th>
                              </tr>
                              {orderHistory
                                .slice(0, showTop10Orders ? 10 : undefined)
                                .map((item, index) => (
                                  <tr
                                    className="table-row"
                                    key={index}
                                    onMouseEnter={() => handleRowHover(index)}
                                    onMouseLeave={handleRowLeave}
                                  >
                                    <td>
                                      {formatDate(item.CreatedDate)}
                                      <br />
                                    </td>

                                    <td>
                                      {item.CustomerDetails && (
                                        <p>
                                          {
                                            JSON.parse(item.CustomerDetails)
                                              ?.address
                                          }
                                        </p>
                                      )}
                                    </td>
                                    <td>
                                      {item.CustomerDetails && (
                                        <h4>
                                          {
                                            JSON.parse(item.CustomerDetails)
                                              ?.firstName
                                          }{" "}
                                          {
                                            JSON.parse(item.CustomerDetails)
                                              ?.lastName
                                          }
                                        </h4>
                                      )}
                                    </td>
                                    <td>
                                      <h4>{item.OrderNumber}</h4>
                                      <br />
                                      <h4
                                        onClick={() =>
                                          openPopup(item.OnlineOrderMasterId)
                                        }
                                        style={{
                                          cursor: "pointer",
                                          color: "green",
                                        }}
                                      >
                                        Order Details
                                      </h4>
                                    </td>
                                    <td>
                                      <h4>{item.StatusName}</h4>
                                      {(item.StatusName === "Pending" ||
                                        item.StatusName === "Accepted") && (
                                        <button
                                          className="btn save"
                                          style={{ width: "125px" }}
                                          onClick={() =>
                                            cancelOrderPopup(
                                              item.OnlineOrderMasterId
                                            )
                                          }
                                        >
                                          Cancel
                                        </button>
                                      )}
                                    </td>
                                    <td>{item.Reason}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        ) : (
                          <div>
                            <h1>No Order History</h1>
                          </div>
                        )}
                      </>
                    )}

                    {showSecondTable && (
                      <>
                        {orderHistory.length > 0 ? (
                          <table>
                            <tbody>
                              <tr>
                                <th style={{ width: "10%" }}>Order On</th>
                                <th>Ship To</th>
                                <th>Contact Person</th>
                                <th>Order Number</th>
                                <th>Order Status</th>
                                <th>Reason</th>
                              </tr>
                              {orderHistory
                                .slice(showTop10Orders ? 10 : 0)
                                .map((item, index) => (
                                  <tr
                                    className="table-row"
                                    key={index}
                                    onMouseEnter={() => handleRowHover(index)}
                                    onMouseLeave={handleRowLeave}
                                  >
                                    <td>
                                      {formatDate(item.CreatedDate)}
                                      <br />
                                    </td>

                                    <td>
                                      {item.CustomerDetails && (
                                        <p>
                                          {
                                            JSON.parse(item.CustomerDetails)
                                              ?.address
                                          }
                                        </p>
                                      )}
                                    </td>
                                    <td>
                                      {item.CustomerDetails && (
                                        <h4>
                                          {
                                            JSON.parse(item.CustomerDetails)
                                              ?.firstName
                                          }{" "}
                                          {
                                            JSON.parse(item.CustomerDetails)
                                              ?.lastName
                                          }
                                        </h4>
                                      )}
                                    </td>
                                    <td>
                                      <h4>{item.OrderNumber}</h4>
                                      <br />
                                      <h4
                                        onClick={() =>
                                          openPopup(item.OnlineOrderMasterId)
                                        }
                                        style={{
                                          cursor: "pointer",
                                          color: "green",
                                        }}
                                      >
                                        Order Details
                                      </h4>
                                    </td>
                                    <td>
                                      <h4>{item.StatusName}</h4>

                                      {(item.StatusName === "Pending" ||
                                        item.StatusName === "Accepted") && (
                                        <button
                                          className="btn save"
                                          style={{ width: "125px" }}
                                          onClick={() =>
                                            cancelOrderPopup(
                                              item.OnlineOrderMasterId
                                            )
                                          }
                                        >
                                          Cancel
                                        </button>
                                      )}
                                    </td>
                                    <td>{item.Reason}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        ) : (
                          <div>
                            <h1>No Order History</h1>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}

                {windowWidth <= 900 && (
                  <>
                    {/* Card View for First Table */}
                    {orderHistory.length > 0 && !showSecondTable && (
                      <div className="my-order-card-container">
                        {orderHistory
                          .slice(0, showTop10Orders ? 10 : undefined)
                          .map((item, index) => (
                            <div className="my-order-card" key={index}>
                              <div className="my-order-card-header">
                                <h3>Order On</h3>
                                <h4>{formatDate(item.CreatedDate)}</h4>
                                <h4>Status: {item.StatusName}</h4>
                              </div>
                              <div className="my-order-card-details">
                                {item.CustomerDetails && (
                                  <p>
                                    Ship To:{" "}
                                    {JSON.parse(item.CustomerDetails)?.address}
                                  </p>
                                )}
                                {item.CustomerDetails && (
                                  <p>
                                    Contact Person:{" "}
                                    {
                                      JSON.parse(item.CustomerDetails)
                                        ?.firstName
                                    }{" "}
                                    {JSON.parse(item.CustomerDetails)?.lastName}
                                  </p>
                                )}
                                <p>Order Number: {item.OrderNumber}</p>
                                <p>Reason: {item.Reason}</p>
                              </div>
                              <div className="my-order-card-actions">
                                <button
                                  className="btn save"
                                  onClick={() =>
                                    openPopup(item.OnlineOrderMasterId)
                                  }
                                >
                                  Order Details
                                </button>
                                {["Pending", "Accepted"].includes(
                                  item.StatusName
                                ) && (
                                  <button
                                    className="btn save"
                                    style={{ width: "125px" }}
                                    onClick={() =>
                                      cancelOrderPopup(item.OnlineOrderMasterId)
                                    }
                                  >
                                    Cancel
                                  </button>
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                    {orderHistory.length === 0 && !showSecondTable && (
                      <h1>No orders available</h1>
                    )}

                    {/* Card View for Second Table */}
                    {showSecondTable && (
                      <div className="my-order-card-container">
                        {orderHistory
                          .slice(showTop10Orders ? 10 : 0)
                          .map((item, index) => (
                            <div className="my-order-card" key={index}>
                              {/* Existing card view code */}
                              {/* Example: */}
                              <div className="my-order-card-header">
                                <h3>Order On</h3>
                                <h4>{formatDate(item.CreatedDate)}</h4>
                                <h4>Status: {item.StatusName}</h4>
                              </div>
                              <div className="my-order-card-details">
                                {item.CustomerDetails && (
                                  <p>
                                    Ship To:{" "}
                                    {JSON.parse(item.CustomerDetails)?.address}
                                  </p>
                                )}
                                {item.CustomerDetails && (
                                  <p>
                                    Contact Person:{" "}
                                    {
                                      JSON.parse(item.CustomerDetails)
                                        ?.firstName
                                    }{" "}
                                    {JSON.parse(item.CustomerDetails)?.lastName}
                                  </p>
                                )}
                                <p>Order Number: {item.OrderNumber}</p>
                                <p>Reason: {item.Reason}</p>
                              </div>

                              <div className="my-order-card-actions">
                                <button
                                  className="btn save"
                                  onClick={() =>
                                    openPopup(item.OnlineOrderMasterId)
                                  }
                                >
                                  Order Details
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </main>
      </div>

      {showPopup && selectedOrderMasterId && orderDetails && (
        <div className="orderDeatils">
          <button className="orderDetailsClose" onClick={closePopup}>
            X
          </button>
          <br />
          <table>
            <tbody>
              <tr>
                <th>Brand</th>
                <th>Price Per Unit</th>
                <th>Quantity</th>
              </tr>
              {Object.entries(orderDetails).map(([key, item], index) => (
                <tr
                  className="table-row"
                  key={key}
                  onMouseEnter={() => handleRowHover(index)}
                  onMouseLeave={handleRowLeave}
                >
                  <td>
                    {item.Brand} ({item.Pack} {item.StaticName})
                  </td>
                  <td>₹ {item.PricePerUnit}</td>
                  <td>{item.quantity}</td>
                </tr>
              ))}
            </tbody>
            <br />
          </table>
          <span>Total Price: ₹ {orderDetails[0].TotalPrice}</span>
        </div>
      )}

      {showCancelOrder && selectedOrderMasterId && (
        <div className="orderDeatils">
          <button className="orderDetailsClose" onClick={closeOrderPopup}>
            X
          </button>
          <br />
          <p style={{ fontSize: "20px", fontWeight: "900" }}>
            Write a reason for cancel order
          </p>
          <div>
            <div style={{ marginBottom: "10px" }}>
              <textarea
                style={{
                  width: "100%",
                  boxSizing: "border-box",
                  height: "100px",
                  resize: "none",
                }}
                id="message"
                placeholder="Write a reason"
                name="message"
                maxLength={400}
                required
                value={cancelReason}
                onChange={(e) => {
                  setCancelReason(e.target.value);
                }}
              />
              {/* {errors.message && <div className="err">{errors.message}</div>} */}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button className="btn save" onClick={cancelOrder}>
                Cancel Order 
              </button>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default MyAccount;
