import React, { useEffect, useState } from "react";

import { callSectionG } from "../../callApi/home";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/slices";
import MediaPicker from "../mediaePicker";

import { fileUpload } from "../../../../fileUpload";
import config from "../../Config.json";
import { isUtf8 } from "buffer";

const SectionG = () => {
  const storedData = localStorage.getItem("adminData") || " {}";
  const adminData = JSON.parse(storedData);
  const AdminId = adminData._id || null;

  const [data, setData] = useState({
    AdminId: AdminId,
    title: "",
    cards: [
      {
        url: "",
        image: { file: "", image: "", status: "none" },
      },
      {
        url: "",
        image: { file: "", image: "", status: "none" },
      },
      {
        url: "",
        image: { file: "", image: "", status: "none" },
      },
      {
        url: "",
        image: { file: "", image: "", status: "none" },
      },
    ],
  });

  const _data = data;
  // console.log("data-GGG", _data);

  // console.log("data_data", _data)
  const [option, setOption] = useState(false);

  const updateData = () => {
    setData((data) => ({
      ...data,
      ..._data,
    }));
  };

  const dispatch = useDispatch();

  const isEmpty = () => {
    let res = false;

    data.cards.forEach((item) => {
      if (item.url !== "") {
        res = true;
      } else {
        res = false;
      }
    });
    return res;
  };

  // console.log(isEmpty())

  const handleSave = async () => {
    if (isEmpty()) {
      dispatch(setLoading(false));
      // array for storing the images that have been updated
      let updatedImages = data.cards.reduce((accumulator, item) => {
        if (item.image.status === "updated" && item.image.file != null) {
          return accumulator.concat(item.image);
        }
        return accumulator;
      }, []);

      let media = [];

      if (updatedImages.length > 0) {
        fileUpload(updatedImages).then((uploadImageRes) => {
          if (uploadImageRes?.length > 0) {
            const image1 =
              data.cards[0]?.image?.status === "updated"
                ? uploadImageRes.filter(
                    (x) => x.fileName == data.cards[0].image.file.name
                  )[0]?.key
                : data.cards[0].image.image;
            const image2 =
              data.cards[1]?.image?.status === "updated"
                ? uploadImageRes.filter(
                    (x) => x.fileName == data.cards[1].image.file.name
                  )[0]?.key
                : data.cards[1].image.image;
            const image3 =
              data.cards[2]?.image?.status === "updated"
                ? uploadImageRes.filter(
                    (x) => x.fileName == data.cards[2].image.file.name
                  )[0]?.key
                : data.cards[2].image.image;
            const image4 =
              data.cards[3]?.image?.status === "updated"
                ? uploadImageRes.filter(
                    (x) => x.fileName == data.cards[3].image.file.name
                  )[0]?.key
                : data.cards[3].image.image;
            media = [image1, image2, image3, image4];
            saveData(media);
          }
        });
      } else {
        media = [
          data.cards[0].image.image,
          data.cards[1].image.image,
          data.cards[2].image.image,
          data.cards[3].image.image,
        ];
        saveData(media);
      }
    } else {
      window.M.toast({ html: "Please fill the all values" });
    }
  };

  const saveData = (media) => {
    const params = {
      title: data.title,
      cards: data.cards.map((item) => {
        return { url: item.url };
      }),

      showinwebsite: option ? 1 : 0,
      media: media,
      AdminId: AdminId,
    };
    console.log("params", params);
    callSectionG(params).then((res) => {
      if (res) {
        dispatch(setLoading(false));
        window.M.toast({ html: "Data saved successfully" });
      } else {
        dispatch(setLoading(false));
        window.M.toast({ html: "Something went wrong" });
      }
    });
  };

  useEffect(() => {
    try {
      const storedData = JSON.parse(sessionStorage.getItem("homeApi"));
  

      const secGData = storedData?.sectionG;
  
      if (secGData) {
       
        _data.title = secGData.heading;
        _data.videos = secGData.media;
  
       
        _data.cards = secGData.content?.map((item, index) => ({
          image: { image: secGData.media[index] },
          imageName: config.awsMediaPath + secGData.media[index],
          url: item.url,
        }));
  
        setOption(secGData.showinwebsite === 1);
  
        updateData();
      } else {
        console.error("Data not found or invalid in sessionStorage.");
      }
    } catch (error) {
      console.error("Error parsing or accessing sessionStorage data:", error);
    }
  }, []);
  

  return (
    <div className="sec section-e section-g">
      <h2>Section - G</h2>
      <main>
        <div className="input-field">
          <label for="section-g-title">Title</label>
        </div>
        <h3>{data.title}</h3>
        <div className="videos card disFlex">
          {data.cards.map((item, index) => (
            <div className="input-field-div">
              <MediaPicker
                type="image"
                onChange={(file, image) => {
                  _data.cards[index].image.image = image;
                  _data.cards[index].imageName = image;
                  _data.cards[index].image.file = file;
                  _data.cards[index].image.status = "updated";
                  setData((data) => ({
                    ...data,
                    ..._data,
                  }));
                }}
                source={item.imageName}
              />
              <label for={"section-g-video-" + index}>YouTube URL</label>

              <input
                id={"section-g-video-" + index}
                type="text"
                className="validate"
                onChange={(text) => {
                  text = text.currentTarget.value;

                  const _data = data;
                  _data.cards[index].url = text;
                  console.log("text", text);

                  setData((data) => ({
                    ...data,
                    ..._data,
                  }));
                }}
                value={data.cards[index].url}
              />
              {/* <label for={"section-g-video-" + index}>Video URL</label> */}
            </div>
          ))}
        </div>
      </main>
      <div className="input-field">
        <label>
          <input
            type="checkbox"
            checked={option}
            onChange={() => setOption(!option)}
          />
          <span>Is Active</span>
        </label>
      </div>
      <button className="save btn" onClick={handleSave}>
        Save
      </button>
    </div>
  );
};

export default SectionG;
