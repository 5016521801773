import axios from "axios";

const apiUrl = process.env.REACT_APP_API_BASE_URL;

const apiRequest = async (
  method,
  url,
  data = null,
  isPrivate = false,
  isAdmin = false
) => {
  let headers = {};
  if (isPrivate) {
    let token = "";
    if (isAdmin) {
      token = localStorage.getItem("adminToken");
    } else {
      token = localStorage.getItem("userToken");
    }
    if (!token) {
      localStorage.clear();
      window.location.reload();
      return;
    }
    headers = {
      Authorization: `Bearer ` + token,
    };
  }

  try {
    const response = await axios({
      method: method,
      url: `${apiUrl}${url}`,
      data: data,
      headers: headers,
    });
    return response.data;
  } catch (error) {
    console.error(
      `Error in ${method} request to ${url}:`,
      error.response || error
    );
    if (error.response.status == 401) {
      localStorage.clear();
      window.M.toast({ html: "Session Expired. Please Login !!!" });
      window.location.reload();
    }
    if (error.response.status == 500) {
      window.M.toast({
        html: error?.response?.data
          ? error?.response?.data
          : "Somthing Went Wrong!!!",
      });
    }
    if (error.response.status == 400) {
      window.M.toast({
        html: error?.response?.data?.message
          ? error?.response?.data?.message
          : "Somthing Went Wrong!!!",
      });
    }
    // throw error;
  }
};

export default apiRequest;
