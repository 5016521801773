import React, { useEffect, useState } from "react";
import SideBar from "../components/sideBar";

import { Checkbox } from "@mui/material";

import callEditBlogCommentApi, {
  callUpdateBlogCommentApi,
  callDeleteBlogCommentApi,
} from "../callApi/blogcommentedit";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices";

const Blogcommentedit = () => {
  const [usersData, setUsersData] = useState([]);

  useEffect(() => {
    callApi();
  }, []);

  const callApi = async () => {
    dispatch(setLoading(true));
    callEditBlogCommentApi().then((res) => {
      dispatch(setLoading(false));
      setUsersData(res);
    });
  };
const dispatch = useDispatch();
  const handlePublish = async (commentId) => {
    try {
      dispatch(setLoading(true));
      // Call the API to update the comment status to "Published"
      callUpdateBlogCommentApi(commentId).then((res) => {
        dispatch(setLoading(false));
        // Update the usersData state to reflect the updated status locally
        setUsersData((prevUsersData) =>
          prevUsersData.map((item) =>
            item.BlogCommentID === commentId
              ? { ...item, Status: "Published" }
              : item
          )
        );
      });
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error publishing comment:", error);
    }
  };

  const handleDelete = async (commentId) => {
    try {
       dispatch(setLoading(true));
      // Call the API to delete the comment with the given commentId
      callDeleteBlogCommentApi(commentId).then((res) => {
        dispatch(setLoading(false));
        // Update the usersData state to remove the deleted comment from the local data
        setUsersData((prevUsersData) =>
          prevUsersData.filter((item) => item.BlogCommentID !== commentId)
        );
      });
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error deleting comment:", error);
    }
  };

  return (
    <div className="page contact-us">
      <SideBar />
      <div className="container">
        <h2>Blog Comments Status</h2>
        <br />
        <table className="card">
          <tbody>
            <tr>
              <th>Title</th>
              <th>Message</th>
              <th>Status</th>
              <th>Edit</th>
            </tr>
            {Array.isArray(usersData) && usersData.length > 0 ? (
            usersData.map((item) => (
              <tr key={item.BlogCommentID}>
                <td>{item.blogTitle}</td>
                <td>{item.message}</td>
                <td>{item.Status}</td>
                <td>
                  {/* Conditionally show the Publish button */}
                  {item.Status !== "Published" ? (
                    <button
                      className="btn"
                      onClick={() => handlePublish(item.BlogCommentID)}
                    >
                      Publish
                    </button>
                  ) : null}
                  &nbsp;
                  <button
                    className="btn"
                    onClick={() => handleDelete(item.BlogCommentID)}
                  >
                    Discard
                  </button>
                </td>
              </tr>
            ))
            ):(
              <tr>
                <td colSpan="5">No Record Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Blogcommentedit;
