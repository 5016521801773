import React, { useEffect, useState } from "react";
import axios from "axios";
import icons from "../../../../constants/icons";
import MediaPicker from "../mediaePicker";
import { callSlider } from "../../callApi/home";
import { fileUpload } from "../../../../fileUpload";
import config from "../../Config.json";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/slices";

const Slider = () => {
  const storedData = localStorage.getItem("adminData") || " {}";
  const adminData = JSON.parse(storedData);
  const AdminId = adminData._id || null;

  const [data, setData] = useState({});

  const _data = data;
  const [option, setOption] = useState(false);

  const updateData = () => {
    setData((data) => ({
      ...data,
      ..._data,
    }));
  };
  const dispatch = useDispatch();
  const handleSave = async () => {
    dispatch(setLoading(true));
    const uploadImages = data.slider.filter(
      (x) => x.status === "updated" && x.file != null
    );
    const uploadedImages = data.slider.filter((x) => x.status == null);
    if (uploadImages.length > 0) {
      fileUpload(uploadImages).then((uploadImageRes) => {
        if (uploadImageRes?.length > 0) {
          const newUploadedImages = [
            ...uploadedImages.map((x) => x.imageName),
            ...uploadImageRes.map((x) => x.key),
          ];
          saveData(newUploadedImages);
        }
      });
    } else {
      saveData([...uploadedImages.map((x) => x.imageName)]);
    }
  };

  const saveData = (uploadedImages) => {
    if (uploadedImages.length == 0) {
      dispatch(setLoading(false));
      window.M.toast({ html: "Please fill the all values" });
      return;
    }
    const params = {
      images: uploadedImages,
      showinwebsite: option ? 1 : 0,
      AdminId: AdminId,
    };
    callSlider(params).then((res) => {
      if (res) {
        dispatch(setLoading(false));
        window.M.toast({ html: "Data saved successfully" });
      }
    });
  };

  useEffect(() => {
    const sliderData = JSON.parse(sessionStorage.getItem("homeApi")).slider;
    _data.slider = sliderData.slider
      ? sliderData.slider?.map((x) => {
          return { image: config.awsMediaPath + x, imageName: x };
        })
      : [];
    setOption(sliderData.showinwebsite == 1 ? true : false);
    updateData();
  }, []);

  return (
    <div className="sec slider">
      <h2>Slider</h2>

      <div
        className="add"
        onClick={() => {
          _data.slider.push({ file: null, status: "updated" });
          updateData();
        }}
      >
        <img src={icons.pick2} />
      </div>

      <main>
        {data?.slider?.map((item, index) => (
          <MediaPicker
            onChange={(file, image) => {
              _data.slider[index] = { file, image, status: "updated" };
              updateData();
            }}
            source={data.slider[index].image}
            Delete
            onDelete={() => {
              const newData = _data.slider.filter(
                (item) => _data.slider.indexOf(item) !== index
              );
              _data.slider = newData;
              updateData();
            }}
          />
        ))}
      </main>
      <div className="input-field">
        <label>
          <input
            type="checkbox"
            checked={option}
            onChange={() => setOption(!option)}
          />
          <span>Is Active</span>
        </label>
      </div>
      <button className="save btn" onClick={handleSave}>
        Save
      </button>
    </div>
  );
};

export default Slider;
