import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchsocialMediaApi } from "../../redux/slices";
import config from "../../pages/Admin/Config.json";

const DigitalAds = () => {
  const [data, setData] = useState(null);
  const [currentVideo, setCurrentVideo] = useState(null);
  const dispatch = useDispatch();
  const reduxData = useSelector((state) => state.homeApi.value);

  useEffect(() => {
    if (reduxData) {
      setData(reduxData.sectionE);
    }
  }, [reduxData]);

  useEffect(() => {
    dispatch(fetchsocialMediaApi());
  }, [dispatch]);

  const digital = data?.content.map((item, index) => ({
    videoUrl: item.title, // Assuming the YouTube video URL is directly provided
    image: data.media[index],
    name: item.title,
  }));


  const handleImageClick = (videoUrl) => {
    setCurrentVideo(videoUrl);
  };

  return (
    <>
      {data && data.showinwebsite ? (
        <div className="digital-ads-container">
          <h2>{data.heading}</h2>
          <div className="digital-ads-images-container">
            {digital &&
              digital.map((item, index) => (
                <div
                  key={index}
                  className="digital-ads-item"
                  onMouseOver={() => handleImageClick(item.videoUrl)}
                >
                  

                  {currentVideo === item.videoUrl ? (
                    
                    <iframe
                      className="digital-ads-video"
                      src={item.videoUrl}
                      title={item.name}
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  ) : (
                    
                    <img
                      className="digital-ads-thumbnail"
                      src={config.awsMediaPath + item.image}
                      alt={item.name}
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default DigitalAds;
