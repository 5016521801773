import React, { useEffect } from "react";
import {
  Routes,
  Route,
  HashRouter,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Home from "../pages/app/home";
import Shop from "../pages/app/shop";
import AboutUs from "../pages/app/aboutus";
import Blog from "../pages/app/blog";
import ContactUs from "../pages/app/contactus";
import Login from "../pages/auth/login";
import Signup from "../pages/auth/signup";
import ForgotPassword from "../pages/auth/forgotpassword";
import ResetPassword from "../pages/auth/resetpassword";
import Cart from "../components/cart";
import ProductCart from "../pages/app/productCart";
import Checkout from "../pages/app/orderplacescreen";
import Shipping from "../pages/app/shipping";
import Confirmation from "../pages/app/confirmation";
import MyAccount from "../pages/app/myOrders";
import { showAuth } from "../redux/slices";
import Shop1 from "../pages/app/shop1";
import WhatsAppIcon from "../components/whatsApp";
import MyProfile from "../pages/app/MyProfile";
import AdminLogin from "../pages/Admin/pages/login";
import AdminHome from "../pages/Admin/pages/home";
import AdminAboutUs from "../pages/Admin/pages/aboutUs";
import AdminBlog from "../pages/Admin/pages/blog";
import AdminLinks from "../pages/Admin/pages/links";
import AddBlogPost from "../pages/Admin/pages/addBlogPost";
import AdminContactUs from "../pages/Admin/pages/contactus";
import AdminSignup from "../pages/Admin/pages/signup";
import Newsletter from "../pages/Admin/pages/newsletter";
import CreateUser from "../pages/Admin/pages/createUser";
import Products from "../pages/Admin/pages/products";
import AdminShop from "../pages/Admin/pages/shop";
import Order from "../pages/Admin/pages/Order";
import Orderselect from "../pages/Admin/pages/orderSelect";
import ChangePassword from "../pages/Admin/pages/changePassword";
import AdminUsers from "../pages/Admin/pages/adminUsers";
import Logout from "../pages/Admin/pages/logout";
import Feedback from "../pages/Admin/pages/Feedback";
import Blogcomment from "../pages/Admin/pages/blogcomment";
import Blogcommentedit from "../pages/Admin/pages/blogcommentedit";
import ShopComment from "../pages/Admin/pages/shopcomment";
import ShopCommentStatus from "../pages/Admin/pages/shopcommentstatus";
import PageNotFound from "../pages/PageNotFound";
import UserShop from "../components/shop/userShop";

const Navigation = () => {
  const auth = useSelector((state) => state.showAuth.value);
  const authPage = useSelector((state) => state.authPage.value);
  const storedData = localStorage.getItem("userData") || " {}";
  const userData = JSON.parse(storedData);
  const uuid = userData.uuid || null;

  const adminStoredData = localStorage.getItem("adminData") || null;
  const adminData = adminStoredData ? JSON.parse(adminStoredData) : null;
  const role = adminData ? adminData.role : null;
debugger
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!userData && window.location.pathname.split("/")[1] === "shop") {
      setTimeout(() => {
        dispatch(showAuth(true));
      }, 1000);
    }
    if (window.location.href.includes("admin") && !adminData) {
      navigate("/admin/login");
    }
  }, []);

  const renderRoleBasedComponent = (Component, roles) => {
    return roles.includes(role) ? (
      <Component />
    ) : (
      <Navigate to="/admin/login" />
    );
  };

  return (
    <>
      {auth && (
        <div
          className="auth-cover"
          // onClick={() => {
          //   dispatch(showAuth(false));
          //   // window.location.reload();
          // }}
        />
      )}
      {auth ? (
        authPage === "login" ? (
          <Login />
        ) : authPage === "signup" ? (
          <Signup />
        ) : authPage === "forgotPassword" ? (
          <ForgotPassword />
        ) : (
          <ResetPassword />
        )
      ) : null}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/resetPassword" element={<ResetPassword />}></Route>
        {/*
        <Route path="/shop/:id" element={<Shop1 />} /> */}
        <Route path="/shop" element={<Shop />} />
        <Route path="/shop/:id" element={<UserShop />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/blog" element={<Blog post={false} />} />
        <Route path="/blog/:permalink" element={<Blog post />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/shop/cart" element={<ProductCart />} />
        <Route path="/shop/checkout" element={<Checkout />} />
        <Route path="/shop/shipping" element={<Shipping />} />
        <Route path="/shop/confirmation" element={<Confirmation />} />
        <Route path="/myorders" element={<MyAccount />} />
        <Route path="/myprofile" element={<MyProfile />} />
        <Route path="/admin/login" element={<AdminLogin />} />

        <Route
          path="/admin/"
          element={
            role === "Admin" || role === "SuperAdmin" ? (
              <AdminHome />
            ) : role === "Blogger" ? (
              <AdminBlog />
            ) : (
              <Navigate to="/admin/login" />
            )
          }
        />
        <Route
          path="/admin/blog"
          element={renderRoleBasedComponent(AdminBlog, [
            "Admin",
            "Blogger",
            "SuperAdmin",
          ])}
        />
        <Route
          path="/admin/blog/add"
          element={renderRoleBasedComponent(AddBlogPost, [
            "Admin",
            "Blogger",
            "SuperAdmin",
          ])}
        />
        <Route
          path="/admin/blog/edit/:postId"
          element={renderRoleBasedComponent(AddBlogPost, [
            "Admin",
            "Blogger",
            "SuperAdmin",
          ])}
        />
        <Route
          path="/admin/logout"
          element={renderRoleBasedComponent(Logout, [
            "Admin",
            "Blogger",
            "SuperAdmin",
          ])}
        />
        <Route
          path="/admin/blogcomment"
          element={renderRoleBasedComponent(Blogcomment, [
            "Admin",
            "Blogger",
            "SuperAdmin",
          ])}
        />
        <Route
          path="/admin/about-us"
          element={renderRoleBasedComponent(AdminAboutUs, [
            "Admin",
            "SuperAdmin",
          ])}
        />

        <Route
          path="/admin/newsletter"
          element={renderRoleBasedComponent(Newsletter, [
            "Admin",
            "SuperAdmin",
          ])}
        />
        <Route
          path="/admin/contactus"
          element={renderRoleBasedComponent(AdminContactUs, [
            "Admin",
            "SuperAdmin",
          ])}
        />
        <Route
          path="/admin/admin-users"
          element={renderRoleBasedComponent(AdminUsers, [
            "Admin",
            "SuperAdmin",
          ])}
        />
        <Route
          path="/admin/shopcommentstatus"
          element={renderRoleBasedComponent(ShopCommentStatus, [
            "Admin",
            "SuperAdmin",
          ])}
        />
        <Route
          path="/admin/shopcomment"
          element={renderRoleBasedComponent(ShopComment, [
            "Admin",
            "SuperAdmin",
          ])}
        />
        <Route
          path="/admin/blogcommentedit"
          element={renderRoleBasedComponent(Blogcommentedit, [
            "Admin",
            "SuperAdmin",
          ])}
        />
         <Route
          path="/admin/prods"
          element={renderRoleBasedComponent(AdminShop, ["Admin", "SuperAdmin"])}
        />
        <Route
          path="/admin/users/create"
          element={renderRoleBasedComponent(CreateUser, ["SuperAdmin"])}
        />
        <Route
          path="/admin/users/edit/:id"
          element={renderRoleBasedComponent(CreateUser, ["SuperAdmin"])}
        />
  
{/* 
        {(role === "Admin" || role === "SuperAdmin" || role === "Blogger") && (
          <>
            <Route
              path="/admin/"
              element={role === "Blogger" ? <AdminBlog /> : <AdminHome />}
            />
            <Route path="/admin/blog" element={<AdminBlog />} />
            <Route path="/admin/blog/add" element={<AddBlogPost />} />
            <Route path="/admin/blog/edit/:postId" element={<AddBlogPost />} />
            <Route path="/admin/logout" element={<Logout />} />
            <Route path="/admin/blogcomment" element={<Blogcomment />} />
            <Route
              path="/admin/blogcommentedit"
              element={<Blogcommentedit />}
            />
          </>
        )}
        {(role === "Admin" || role === "SuperAdmin") && (
          <>
            <Route path="/admin/about-us" element={<AdminAboutUs />} />
            <Route path="/admin/links" element={<AdminLinks />} />
            <Route path="/admin/contactus" element={<AdminContactUs />} />
            <Route path="/admin/signup" element={<AdminSignup />} />
            <Route path="/admin/newsletter" element={<Newsletter />} />
            <Route path="/admin/users/create" element={<CreateUser />} />
            <Route path="/admin/users/edit/:id" element={<CreateUser />} />
            <Route path="/admin/shop" element={<Products />} />
            <Route path="/admin/prods" element={<AdminShop />} />
            <Route path="/admin/order" element={<Order />} />
            <Route path="/admin/orderselect" element={<Orderselect />} />
            <Route path="/admin/change-password" element={<ChangePassword />} />
            <Route path="/admin/admin-users" element={<AdminUsers />} />
            <Route path="/admin/enquiry" element={<Feedback />} />
            <Route path="/admin/shopcomment" element={<ShopComment />} />
            <Route
              path="/admin/shopcommentstatus"
              element={<ShopCommentStatus />}
            />
          </>
        )} */}
        <Route path="*" element={<PageNotFound />}></Route>
      </Routes>
      <WhatsAppIcon />
    </>
  );
};

export default Navigation;
