import React, { useEffect, useState } from "react";

import images from "../constants/images";

import "../css/shippinglayout.css";
import api from "../api";
import config from "../pages/Admin/Config.json";

const ShippingLayout = ({ children, className }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [products, setProducts] = useState([]);

  const storedData = localStorage.getItem("userData") || " {}";
  const userData = JSON.parse(storedData);
  const uuid = userData.id || null;

  useEffect(() => {
    resize();
  }, []);

  useEffect(() => {
    api
      .get(`/api/cart/${uuid}`)
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const resize = () => {
    if (document.body.offsetWidth <= 905) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // const parseImageURL = (imageURL) => {
  //   if (imageURL) {
  //     const parsedURL = JSON.parse(imageURL);
  //     return parsedURL[0]?.image || null;
  //   }
  //   return null;
  // };

  const getTotalCartValue = () => {
    let totalValue = 0;
    products.forEach((item) => {
      totalValue += item.total_price;
    });
    return totalValue;
  };

  window.addEventListener("resize", resize);

  window.onscroll = () => {};

  return (
    <div className="shipping-layout">
      {isMobile && (
        <div className="rs">
          <main>
            {/* <div className="product">
              <section>
                <img src={images.shop1} />
                <div>
                  <span>Keshroot Hair Oil</span>
                  <span>Qtv : 2</span>
                </div>
                <span className="price">Rs. 340.00</span>
              </section>
            </div> */}
            {products.length > 0 ? (
              <>
                {products.map((item) => {
                  const productImage = config.awsMediaPath + item?.media[0];
                  return (
                    <>
                      <div className="product">
                        <section>
                          <img src={productImage} alt={item.name} />
                          <div>
                            <span style={{ margin: "0" }}>{item.name}</span>
                            <span style={{ marginTop: "0" }}>
                              {"Qty : " + item.Quantity}
                            </span>
                            {/* <span style={{ marginTop: "0.5rem" }}>
                              {"Chemist:" + item.chemistname}
                            </span> */}
                          </div>
                          <span className="price">
                            {"₹" + item.total_price}
                          </span>
                        </section>
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              <> </>
            )}
            {/* <div className="discount-code">
              <input
                type="text"
                placeholder="Discount code"
                spellCheck={false}
              />
              <button>Apply</button>
            </div> */}
            <div className="row" style={{ marginTop: 15 }}>
              {/* <div className="col">
                <span>Subtotal</span> <span>RS. {getTotalCartValue()}.00</span>
              </div>

              <div className="col">
                <span>Shipping</span>
                <span>Calculated at next step</span>
              </div> */}
            </div>
            <div className="row second" style={{ marginTop: 15 }}>
              <section>
                <span>Total</span>
                {/* <span>Including Rs.1,036.36 in taxes</span> */}
              </section>
              <p>RS. {getTotalCartValue()}.00</p>
            </div>
          </main>
        </div>
      )}
      <div className="ls">
        <div className={className ? className : "no-className"} id="ls">
          {children}
        </div>
      </div>
      {!isMobile && (
        <div className="rs">
          <main>
            {products.length > 0 ? (
              <>
                {products.map((item) => {
                  const productImage = config.awsMediaPath + item?.media[0];
                  return (
                    <>
                      <div className="product">
                        <section>
                          <img src={productImage} alt={item.Brand} />
                          <div>
                            <span style={{ margin: "0" }}>
                              {item.Brand} ({item.Pack} {item.UOMValue})
                            </span>
                            <p style={{ marginTop: "0.5rem" }}>
                              {"Qty : " + item.Quantity}
                            </p>
                            {/* <span style={{ marginTop: "0.5rem" }}>
                              {"Chemist:" + item.ChemistName}
                            </span> */}
                          </div>
                          <p className="price">{"₹" + item.total_price}</p>
                        </section>
                      </div>
                    </>
                  );
                })}
              </>
            ) : (
              <> </>
            )}
            {/* <div className="discount-code">
              <input
                type="text"
                placeholder="Discount code"
                spellCheck={false}
              />
              <button>Apply</button>
            </div> */}
            {/* <div className="row" style={{ marginTop: 15 }}>
              <div className="col">
                <span>Subtotal</span> <span>RS. {getTotalCartValue()}.00</span>
              </div>

              <div className="col">
                <span>Shipping</span>
                <span>Calculated at next step</span>
              </div>
            </div> */}
            <div className="row second" style={{ marginTop: 15 }}>
              <section>
                <span>Total</span>
                {/* <span>Including Rs.1,036.36 in taxes</span> */}
              </section>
              <span style={{ font: "inherit" }}>
                RS. {getTotalCartValue()}.00
              </span>
            </div>
          </main>
        </div>
      )}
    </div>
  );
};

export default ShippingLayout;
