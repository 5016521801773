import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api";

const { REACT_APP_API_BASE_URL: apiBaseURL } = process.env;

const initialState = {
  options: [],
  products: [],
  loading: false,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState: {
    isLoading: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const selectLoading = (state) => state.loading.isLoading;
export const loadingReducer = loadingSlice.reducer;
export const { setLoading } = loadingSlice.actions;

const cartUpdateSlice = createSlice({
  name: "cartUpdate",
  initialState: {
    isUpdate: false,
  },
  reducers: {
    setCartUpdate: (state, action) => {
      state.isUpdate = action.payload;
    },
  },
});

export const isCartUpdate = (state) => state.cartUpdate.isUpdate;
export const cartUpdateReducer = cartUpdateSlice.reducer;
export const { setCartUpdate } = cartUpdateSlice.actions;

const homeApiSlice = createSlice({
  name: "homeApi",
  initialState: {
    value: null,
  },
  reducers: {
    homeApi: (state, action) => {
      state.value = action.payload;
    },
  },
});

const authSlice = createSlice({
  name: "auth",
  initialState: {
    value: false,
  },
  reducers: {
    showAuth: (state, action) => {
      state.value = action.payload;
    },
  },
});

const authPageSlice = createSlice({
  name: "authPage",
  initialState: {
    value: "login",
  },
  reducers: {
    authPage: (state, action) => {
      state.value = action.payload;
    },
  },
});

const cartShowSlise = createSlice({
  name: "showCart",
  initialState: {
    value: false,
  },
  reducers: {
    showCart: (state, action) => {
      state.value = action.payload;
    },
  },
});

const aboutApiSlice = createSlice({
  name: "aboutApi",
  initialState: {
    value: null,
  },
  reducers: {
    aboutApi: (state, action) => {
      state.value = action.payload;
    },
  },
});

const socialMediaApiSlice = createSlice({
  name: "socialMediaApi",
  initialState: {
    value: null,
  },
  reducers: {
    socialMediaApi: (state, action) => {
      state.value = action.payload;
    },
  },
});

const contactApiSlice = createSlice({
  name: "contactApi",
  initialState: {
    value: null,
  },
  reducers: {
    contactApi: (state, action) => {
      state.value = action.payload;
    },
  },
});

const blogcommentApiSlice = createSlice({
  name: "blogcommentApi",
  initialState: {
    value: null,
  },
  reducers: {
    blogcommentApi: (state, action) => {
      state.value = action.payload;
    },
  },
});

const blogApiSlice = createSlice({
  name: "blogApi",
  initialState: {
    value: null,
  },
  reducers: {
    blogApi: (state, action) => {
      state.value = action.payload;
    },
  },
});

const shopApiSlice = createSlice({
  name: "shopApi",
  initialState: {
    value: null,
  },
  reducers: {
    shopApi: (state, action) => {
      state.value = action.payload;
    },
  },
});

//

// Define async thunk for fetching options
export const fetchOptions = createAsyncThunk(
  "checkout/fetchOptions",
  async (uuid) => {
    const response = await api.get(`/delivery/${uuid}`);
    return response.data;
  }
);

// Define async thunk for fetching products
export const fetchProducts = createAsyncThunk(
  "checkout/fetchProducts",
  async (uuid) => {
    const response = await api.get(`/api/cart/${uuid}`);
    return response.data;
  }
);
// Create the checkout slice
const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    // ... (other reducer functions)
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOptions.fulfilled, (state, action) => {
        state.options = action.payload;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.products = action.payload;
      });
  },
});

// Define the async thunk for fetching API data
export const fetchConfirmationData = createAsyncThunk(
  "confirmation/fetchData",
  async (uuid) => {
    const response = await api.get(`/orderDetailslast/${uuid}`);
    return response.data;
  }
);

export const confirmationSlice = createSlice({
  name: "confirmation",
  initialState: {
    summary: [],
  },
  reducers: {
    // Other reducer functions for handling state updates (if needed)
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConfirmationData.fulfilled, (state, action) => {
      state.summary = action.payload;
    });
  },
});

// Define async thunk for fetching cart items
export const fetchCartItems = createAsyncThunk(
  "cart/fetchCartItems",
  async (uuid) => {
    const response = await api.get(`/api/cart/${uuid}`);
    return response.data;
  }
);

// Define async thunk for updating cart item quantity
export const updateCartItemQuantity = createAsyncThunk(
  "cart/updateCartItemQuantity",
  async ({ itemId, quantity }) => {
    const response = await axios.patch(`/api/cart/${itemId}`, { quantity });
    return response.data;
  }
);

// Define async thunk for deleting cart item
export const deleteCartItem = createAsyncThunk(
  "cart/deleteCartItem",
  async ({ uuid, itemId }) => {
    await api.delete(`/api/cart/${uuid}/${itemId}`);
    return itemId;
  }
);

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: [],
  },
  reducers: {
    // You can define additional reducer functions here if needed
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCartItems.fulfilled, (state, action) => {
        state.cartItems = action.payload;
      })
      .addCase(updateCartItemQuantity.fulfilled, (state, action) => {
        // Find the updated item in the cartItems array and update its quantity
        state.cartItems = state.cartItems.map((item) =>
          item.productname === action.payload.itemId
            ? { ...item, quantity: action.payload.quantity }
            : item
        );
      })
      .addCase(deleteCartItem.fulfilled, (state, action) => {
        // Remove the deleted item from the cartItems array
        state.cartItems = state.cartItems.filter(
          (item) => item.productname !== action.payload
        );
      });
  },
});

// Export actions and reducer

export const cartActions = cartSlice.actions;
export const cartReducer = cartSlice.reducer;

export const { summary } = confirmationSlice.actions;
export const confirmationReducer = confirmationSlice.reducer;
// Export actions and reducers
export const { setContries, setSelectedContry, setStates } =
  checkoutSlice.actions;
export default checkoutSlice.reducer;

const { homeApi } = homeApiSlice.actions;
const { blogApi } = blogApiSlice.actions;
export const { showAuth } = authSlice.actions;
export const { authPage } = authPageSlice.actions;
export const { showCart } = cartShowSlise.actions;

const { aboutApi } = aboutApiSlice.actions;
const { socialMediaApi } = socialMediaApiSlice.actions;

const { shopApi } = shopApiSlice.actions;
const { contactApi } = contactApiSlice.actions;
export const { blogcommentApi } = blogcommentApiSlice.actions;
export const blogcommentApiReducer = blogcommentApiSlice.reducer;

export const homeApiReducer = homeApiSlice.reducer;
export const shopApiReducer = shopApiSlice.reducer;

export const aboutApiReducer = aboutApiSlice.reducer;
export const socialMediaApiReducer = socialMediaApiSlice.reducer;

export const contactApiReducer = contactApiSlice.reducer;
export const blogApiReducer = blogApiSlice.reducer;
export const authReducer = authSlice.reducer;
export const authPageReducer = authPageSlice.reducer;
export const showCartReducer = cartShowSlise.reducer;

export const fetchHomeApi = () => {
  return async function fetchHomeApiThunk(dispatch) {
    dispatch(setLoading(true));
    try {
      let data = await api.get("/home");

      data = data.data;
      dispatch(setLoading(false));
      dispatch(homeApi(data));
    } catch (err) {
      dispatch(setLoading(false));
      console.log(err);
    }
  };
};

export const fetchShopApi = () => {
  return async function fetchShopApiThunk(dispatch) {
    dispatch(setLoading(true));
    try {
      let data = await api.get("/prods");
      data = data.data;
      dispatch(setLoading(false));
      dispatch(shopApi(data));
    } catch (err) {
      dispatch(setLoading(false));
      console.log(err);
    }
  };
};

export const fetchBlogCommentsApi = () => {
  return async function fetchBlogCommentsApiThunk(dispatch) {
    dispatch(setLoading(true));
    try {
      let data = await api.get("/blogcomment");
      data = data.data;
      dispatch(blogcommentApi(data));
      dispatch(setLoading(false));
    } catch (err) {
      dispatch(setLoading(false));
      console.log(err);
    }
  };
};

export const fetchAboutApi = () => {
  return async function fetchHomeApiThunk(dispatch) {
    dispatch(setLoading(true));
    try {
      let data = await api.get("/get-about-us");
      data = data.data;
      dispatch(setLoading(false));
      dispatch(aboutApi(data));
    } catch (err) {
      dispatch(setLoading(false));
      console.log(err);
    }
  };
};

export const fetchsocialMediaApi = () => {
  return async function fetchHomeApiThunk(dispatch) {
    dispatch(setLoading(true));
    // debugger;
    try {
      let data = await api.get("/links");
      data = data.data;
      dispatch(socialMediaApi(data));
      dispatch(setLoading(false));
    } catch (err) {
      dispatch(setLoading(false));
      console.log(err);
    }
  };
};

export const fetchContactApi = () => {
  return async function fetchHomeApiThunk(dispatch) {
    dispatch(setLoading(true));

    try {
      let data = await api.get("/contactus");
      data = data.data;
      dispatch(setLoading(false));
      dispatch(contactApi(data));
    } catch (err) {
      dispatch(setLoading(false));
      console.log(err);
    }
  };
};

export const fetchBlogApi = () => {
  return async function fetchHomeApiThunk(dispatch) {
    dispatch(setLoading(true));
    try {
      let data = await api.get("/blog/admin");
      data = data.data;
      dispatch(setLoading(false));
      dispatch(blogApi(data));
    } catch (err) {
      dispatch(setLoading(false));
      console.log(err);
    }
  };
};
