import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import naturePic from "../../assets/images/naturepic.jpg";
import config from "../../pages/Admin/Config.json";

const YoutubeReviews = () => {
  const [data, setData] = useState(null);
  const [currVideo, setCurrVideo] = useState(null);
  const reduxdata = useSelector((state) => state.homeApi.value);

  useEffect(() => {
    if (reduxdata) {
      setData(reduxdata.sectionG);
    }
  }, [reduxdata]);

  const handleClick = (val) => {
    setCurrVideo(val);
  };

  return (
    <>
      {data && data.showinwebsite ? (
        <div className="youtube-reviews">
          <h2>{data.heading}</h2>
          <div className="videos">
            <section
              className="frame-section"
              onMouseOver={() => handleClick(data.media[0])}
            >
              {currVideo && currVideo === data.media[0] ? (
                <iframe
                  src={data.content[0].url}
                  style={{ height: "70VH" }}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              ) : (
                <img className="digital-ads-thumbnail" src={config.awsMediaPath + data.media[0]} />
              )}
            </section>

            <section className="frame-section">
              <div
                className="frame-div"
                onMouseOver={() => handleClick(data.media[1])}
              >
                {currVideo === data.media[1] ? (
                  <iframe
                    src={data.content[1].url}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <img className="digital-ads-thumbnail" src={config.awsMediaPath + data.media[1]} />
                )}
              </div>
              <div
                className="frame-div"
                onMouseOver={() => handleClick(data.media[2])}
              >
                {currVideo === data.media[2] ? (
                  <iframe
                    src={data.content[2].url}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <img className="digital-ads-thumbnail" src={config.awsMediaPath + data.media[2]} />
                )}
              </div>
              <div
                className="frame-div"
                onMouseOver={() => handleClick(data.media[3])}
              >
                {currVideo === data.media[3] ? (
                  <iframe
                    src={data.content[3].url}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                ) : (
                  <img className="digital-ads-thumbnail" src={config.awsMediaPath + data.media[3]} />
                )}
              </div>
            </section>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default YoutubeReviews;
