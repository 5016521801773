import apiRequest from "../../../apiCall";

const callHomeApi = () => {
    return new Promise((resolve, reject) => {

        apiRequest('GET', 'home', null, true, true).then(res => {
          
            sessionStorage.setItem('homeApi', JSON.stringify(res));
            resolve(res);
        })
            .catch(error => {
                console.error('Error calling home API:', error);
                reject(error);
            });
    });
};


const callSlider = (formData) => {
    return new Promise((resolve, reject)=>{
        apiRequest('POST','home/slider', formData, true, true).then(res => {
         sessionStorage.setItem('sliderApi', JSON.stringify(res)); 
         resolve(res);
        })

        .catch(error => {
            console.log("Error sending slider Data", error);
            reject(error);
        })
    });

}

const callSectionA = (formData) => {
    return new Promise((resolve, reject)=>{
        apiRequest('POST','home/section-a', formData, true, true).then(res => {
         sessionStorage.setItem('section-aApi', JSON.stringify(res)); 
         resolve(res);
        })

        .catch(error => {
            console.log("Error sending Section-A Data", error);
            reject(error);
        })
    });

}


const callSectionB = (formData) => {
    return new Promise((resolve, reject)=>{
        apiRequest('POST','home/section-b', formData, true, true).then(res => {
            console.log("sectionB", res)
         sessionStorage.setItem('section-bApi', JSON.stringify(res)); 
         resolve(res);
        })

        .catch(error => {
            console.log("Error sending Section-B Data", error);
            reject(error);
        })
    });

}

const callSectionC = (formData) => {
    return new Promise((resolve, reject)=>{
        apiRequest('POST','home/section-c', formData, true, true).then(res => {
         sessionStorage.setItem('section-cApi', JSON.stringify(res)); 
         resolve(res);
        })

        .catch(error => {
            console.log("Error sending Section-C Data", error);
            reject(error);
        })
    });

}

const callSectionD = (formData) => {
    return new Promise((resolve, reject)=>{
        apiRequest('POST','home/section-d', formData, true, true).then(res => {
         sessionStorage.setItem('section-dApi', JSON.stringify(res)); 
         resolve(res);
        })

        .catch(error => {
            console.log("Error sending Section-D Data", error);
            reject(error);
        })
    });

}

const callSectionE = (formData) => {
    return new Promise((resolve, reject)=>{
        apiRequest('POST','home/section-e', formData, true, true).then(res => {
         sessionStorage.setItem('section-eApi', JSON.stringify(res)); 
         resolve(res);
        })

        .catch(error => {
            console.log("Error sending Section-E Data", error);
            reject(error);
        })
    });

}


const callSectionF = (formData) => {
    return new Promise((resolve, reject)=>{
        apiRequest('POST','home/section-f', formData, true, true).then(res => {
         sessionStorage.setItem('section-fApi', JSON.stringify(res)); 
         resolve(res);
        })

        .catch(error => {
            console.log("Error sending Section-E Data", error);
            reject(error);
        })
    });

}

const callSectionG = (formData) => {
    return new Promise((resolve, reject)=>{
        console.log("post req started", formData)
        apiRequest('POST','home/section-g', formData, true, true).then(res => {
            console.log("sectionG...call", JSON.stringify(res))
            
            sessionStorage.setItem('section-gApi', JSON.stringify(res)); 
            resolve(res);
        })

        .catch(error => {
            console.log("Error sending Section-G Data", error);
            reject(error);
        })

    });

}

const callSectionH = (formData) => {
    return new Promise((resolve, reject)=>{
        apiRequest('POST','home/section-h', formData, true, true).then(res => {
         sessionStorage.setItem('section-hApi', JSON.stringify(res)); 
         resolve(res);
        })

        .catch(error => {
            console.log("Error sending Section-H Data", error);
            reject(error);
        })
    });

}
const callSectionI = (formData) => {
    return new Promise((resolve, reject)=>{
        apiRequest('POST','home/section-i', formData, true, true).then(res => {
         sessionStorage.setItem('section-iApi', JSON.stringify(res)); 
         resolve(res);
        })

        .catch(error => {
            console.log("Error sending Section-I Data", error);
            reject(error);
        })
    });

}

export default callHomeApi;

export {callSlider,callSectionA, callSectionB, callSectionC, callSectionD, callSectionE, callSectionF, callSectionG, callSectionH, callSectionI};
