import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ShippingLayout from "../../components/shippingLayout";
import "../../css/confirmation.css";
import apiRequest from "../../apiCall";

const Confirmation = () => {
  const [summary, setSummary] = useState([]);
  const [order, setOrder] = useState([]);

  document.title = "Thank You";

  const storedData = localStorage.getItem("userData") || " {}";
  const userData = JSON.parse(storedData);
  const uuid = userData.uuid || null;
  const CustomerID = userData ? userData.id : null;

  useEffect(() => {
    resize();
  }, []);

  const [customerData, setCustomerData] = useState({});
  useEffect(() => {
    apiRequest(
      "GET",
      `orderDetailslast/${CustomerID}`,
      null,
      false,
      false
    ).then((response) => {
      const orderData = response; // No need to parse JSON here
      setSummary(orderData);
      setCustomerData(JSON.parse(orderData.CustomerDetails));
    });
  }, []);

  // useEffect(() => {
  //   apiRequest("GET", `api/cart/${uuid}`, null, false, false)
  //     .then((response) => {
  //       setOrder(response.data[0].chemistname);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  const resize = () => {
    const email = document.getElementsByClassName("email");

    for (let i = 0; i < email.length; i++) {
      if (email[i].offsetWidth > document.body.offsetWidth / 5) {
        email[i].classList.add("overflow");
        email[i].classList.add("overflow");
      } else {
        email[i].classList.remove("overflow");
      }
    }
  };

  window.addEventListener("resize", resize);

  return (
    <div className="confirmation">
      <div className="order-conf">
        <h2>
          Thank you {customerData?.firstName} {customerData?.lastName}
        </h2>
        <br />
        <span>Your order is confirmed.</span> <br />
        {/* <span>
          You’ll receive a confirmation email with your order number shortly.
        </span> */}
      </div>
      <div className="order-conf">
        <h4>Your Order Number is- {summary.OrderNumber} </h4>
      </div>
      <div className="order-updated sec">
        <h3>Your order is Redirected to {summary.ChemistName}</h3>
        <span>
          You’ll receive a confirmation email with your order number shortly.
        </span>
        
        <p style={{fontSize:'15px'}}>Chemist Address: {summary.Address} - {summary.PinCode}</p>
        <p style={{fontSize:'15px'}}> Chemist Mobile Number: {summary.Mobile}</p>
      </div>
      
      {/* <div className="map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235014.2579209994!2d72.43965588570215!3d23.02018176300004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e848aba5bd449%3A0x4fcedd11614f6516!2sAhmedabad%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1676093723738!5m2!1sen!2sin"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div> */}
     
      <div className="sec order-info">
        <h2>Order updates</h2>
        <main>
          <section className="ls">
            <span>Contact information</span>
            <p className="email">Email : {summary.CustomerEmail}</p>
            <p className="email">Mobile No. : {summary.CustomerPhone}</p>
            <span>Shipping address</span>
            <p style={{ width: "50%" }}>
              {customerData?.firstName} {customerData?.lastName}
              <br />
              {customerData?.address}
              <br /> {summary.CustomerPhone}
            </p>
          </section>
          <section className="rs">
            <span>Payment method</span>
            <p>COD (cash on delivery)</p>
            <span>Shipping method</span>
            <p>Standard (cash on delivery)</p>
            {/* <span>Billing address</span> */}
            {/* <p style={{ width: "50%" }}>
              {customerData?.billingFirstName} {customerData?.billingLastName}
              <br />
              {customerData?.billingAddress}
            </p> */}
          </section>
        </main>
        <br />
        <br />
        <div className="last-button">
          <Link className="List" to="/">
            Return to Home
          </Link>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default Confirmation;
