import React, { useEffect, useState } from "react";

import MediaPicker from "../mediaePicker";
import { callSectionB } from "../../callApi/home";
import { fileUpload } from "../../../../fileUpload";
import config from "../../Config.json";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/slices";

const SectionB = () => {
  const storedData = localStorage.getItem("adminData") || " {}";
  const adminData = JSON.parse(storedData);
  const AdminId = adminData._id || null;

  // state for storing cards data
  const [data, setData] = useState({
    AdminId: AdminId,
    title: "",
    cards: [
      {
        title: "",
        content: "",
        image: { file: "", image: "", status: "none" },
      },
      {
        title: "",
        content: "",
        image: { file: "", image: "", status: "none" },
      },
      {
        title: "",
        content: "",
        image: { file: "", image: "", status: "none" },
      },
    ],
  });

  // temp array for updating card data
  const _data = data;
  const [option, setOption] = useState(false);

  const updateData = () => {
    setData((data) => ({
      ...data,
      ..._data,
    }));
  };

  const isEmpty = () => {
    let res = false;
    data.cards.forEach((item) => {
      if (item.title != "" && item.content != "" && item.image != "") {
        res = true;
      } else {
        res = false;
      }
    });
    return res;
  };

  const dispatch = useDispatch();

  const handleSave = async () => {
    if (isEmpty) {
      dispatch(setLoading(true));

      // array for storing the images that have been updated
      let updatedImages = data.cards.reduce((accumulator, item) => {
        if (item.image.status === "updated" && item.image.file != null) {
          return accumulator.concat(item.image);
        }
        return accumulator;
      }, []);

      let media = [];

      if (updatedImages.length > 0) {
        fileUpload(updatedImages).then((uploadImageRes) => {
          if (uploadImageRes?.length > 0) {
            const image1 =
              data.cards[0]?.image?.status === "updated"
                ? uploadImageRes.filter(
                    (x) => x.fileName == data.cards[0].image.file.name
                  )[0]?.key
                : data.cards[0].image.image;
            const image2 =
              data.cards[1]?.image?.status === "updated"
                ? uploadImageRes.filter(
                    (x) => x.fileName == data.cards[1].image.file.name
                  )[0]?.key
                : data.cards[1].image.image;
            const image3 =
              data.cards[2]?.image?.status === "updated"
                ? uploadImageRes.filter(
                    (x) => x.fileName == data.cards[2].image.file.name
                  )[0]?.key
                : data.cards[2].image.image;
            media = [image1, image2, image3];
            saveData(media);
          }
        });
      } else {
        media = [
          data.cards[0].image.image,
          data.cards[1].image.image,
          data.cards[2].image.image,
        ];
        saveData(media);
      }
    } else {
      window.M.toast({ html: "Please fill all values" });
    }
  };

  const saveData = (media) => {
    const params = {
      title: data.title,
      cards: data.cards.map((item) => {
        return { title: item.title, content: item.content };
      }),
      showinwebsite: option ? 1 : 0,
      media: media,
      AdminId: AdminId,
    };
    callSectionB(params).then((res) => {
      if (res) {
        dispatch(setLoading(false));
        window.M.toast({ html: "Data saved successfully" });
      } else {
        dispatch(setLoading(false));
        window.M.toast({ html: "Something went wrong" });
      }
    });
  };

  useEffect(() => {
    const secBData = JSON.parse(sessionStorage.getItem("homeApi")).sectionB;
    _data.title = secBData.heading;
    // _data.cards = secBData.content;
    setOption(secBData.showinwebsite == 1 ? true : false);

    secBData.content.forEach((item, index) => {
      _data.cards[index] = {
        image: { image: secBData.media[index] },
        imageName: config.awsMediaPath + secBData.media[index],
        title: item.title,
        content: item.content,
      };
    });

    updateData();
  }, []);

  return (
    <div className="sec section-b">
      <h2>Section - B</h2>
      <div className="input-field">
        <input
          id="section-b-title"
          type="text"
          className="validate"
          onChange={(text) => {
            text = text.currentTarget.value;
            _data.title = text;
            setData((data) => ({
              ...data,
              ..._data,
            }));
          }}
          value={data.title}
        />
        <label for="section-b-title">Title</label>
      </div>
      <main>
        {data.cards.map((item, index) => (
          <div className="card">
            <MediaPicker
              type="image"
              onChange={(file, image) => {
                _data.cards[index].image.image = image;
                _data.cards[index].imageName = image;
                _data.cards[index].image.file = file;
                _data.cards[index].image.status = "updated";
                setData((data) => ({
                  ...data,
                  ..._data,
                }));
              }}
              source={item.imageName}
            />
            <div className="input-field">
              <input
                id={"section-b-card-title" + index}
                type="text"
                className="validate"
                onChange={(text) => {
                  text = text.currentTarget.value;
                  _data.cards[index].title = text;
                  setData((data) => ({
                    ...data,
                    ..._data,
                  }));
                }}
                value={item.title}
              />
              <label for={"section-b-card-title" + index}>Title</label>
            </div>
            <div className="input-field">
              <textarea
                id={"section-b-card-content-" + index}
                className="materialize-textarea"
                onChange={(text) => {
                  text = text.currentTarget.value;
                  _data.cards[index].content = text;
                  setData((data) => ({
                    ...data,
                    ..._data,
                  }));
                }}
                value={item.content}
              />
              <label for={"section-b-card-content-" + index}>Content</label>
            </div>
          </div>
        ))}
      </main>
      <div className="input-field">
        <label>
          <input
            type="checkbox"
            checked={option}
            onChange={() => setOption(!option)}
          />
          <span>Is Active</span>
        </label>
      </div>
      <button className="btn save" onClick={handleSave}>
        Save
      </button>
    </div>
  );
};

export default SectionB;
