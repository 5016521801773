import apiRequest from "../../../apiCall";

const storedData = localStorage.getItem("adminData") || " {}";
const adminData = JSON.parse(storedData);
const AdminId = adminData._id || null;

const callEditBlogCommentApi = async () => {
  return new Promise((resolve, reject) => {
    apiRequest("GET", "blogcomment", null, true, true)
      .then((res) => {
        sessionStorage.setItem("blogcommentApi", JSON.stringify(res));
        resolve(res);
      })
      .catch((error) => {
        console.error("Error calling home API:", error);
        reject(error);
      });
  });
};

const callUpdateBlogCommentApi = async (commentId) => {
  return new Promise((resolve, reject) => {
    apiRequest(
      "PUT",
      `blogcommentupdate/${commentId}`,
      { AdminId },
      true,
      true
    )
      .then((res) => {
        sessionStorage.setItem("UPDATEblogcommentApi", JSON.stringify(res));
        resolve(res);
      })
      .catch((error) => {
        console.error("Error calling home API:", error);
        reject(error);
      });
  });
};

const callDeleteBlogCommentApi = async (commentId) => {
  return new Promise((resolve, reject) => {
    apiRequest("DELETE", `blogcommentdelete/${commentId}`, null, true, true)
      .then((res) => {
        sessionStorage.setItem("DELETEblogcommentApi", JSON.stringify(res));
        resolve(res);
      })
      .catch((error) => {
        console.error("Error calling home API:", error);
        reject(error);
      });
  });
};

export default callEditBlogCommentApi;
export { callUpdateBlogCommentApi, callDeleteBlogCommentApi };
