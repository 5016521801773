import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import jwtDecode from "jwt-decode";

import { authPage, showAuth } from "../../redux/slices";
import { useState } from "react";
import { decrypt, encrypt } from "../Admin/crypto";
import { setLoading } from "../../redux/slices";
import apiRequest from "../../apiCall";
import toast, { Toaster } from "react-hot-toast";
import api from "../../api";
import { useNavigate } from "react-router-dom";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const Login = () => {
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showSendOTP, setShowSendOTP] = useState(false);

  const initialValues = {
    mobile: "",
    password: "",
  };

  const errorsz = () =>
    toast.error("Internal Sever Error, Please try again later.");

  const validationSchema = yup.object({
    mobile: yup
      .string()
      .required("Please enter Mobile Number")
      .min(10, "Mobile Number shuold be minimum 10 digit .")
      .matches(phoneRegExp, "Phone number is not valid"),

    password: yup
      .string()
      .required("Please enter Password")
      .min(8, "Password is too short - should be 8 chars minimum."),
    OTP: yup.string().when("showSendOTP", {
      is: true,
      then: yup.string().required("Please enter OTP"),
    }),
  });

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async () => {
      const { mobile, password } = values;
      try {
        apiRequest(
          "POST",
          "login",
          { mobile, password: encrypt(password) },
          false,
          false
        ).then((res) => {
          if (res.status === 200 && res.isVerified === 1) {
            localStorage.removeItem("adminData");
            localStorage.removeItem("adminToken");
            localStorage.setItem("userData", JSON.stringify(res.data));
            localStorage.setItem("userToken", res.data.token);
            // window.location.reload();
            setIsLoggedIn(true); // Update login state
            dispatch(setLoading(false));
            dispatch(showAuth(false));
          } else if (res.status === 200 && res.isVerified === 0) {
            setShowSendOTP(true);
          } else if (res.status === 501) {
            toast.error("Wrong password.");
          } else {
            toast.error("Mobile number is not found. Please sign up!");
          }
        });
      } catch (error) {
        dispatch(setLoading(false));
        console.error(error);
        toast.error("Mobile number is not found.");
      }
    },
  });

  useEffect(() => {
    // Check if the user is already logged in
    const token = localStorage.getItem("userTokenF");
    if (token) {
      setIsLoggedIn(true);
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        // Token is expired, log out the user
        console.log(decodedToken.exp);
        handleLogout();
      } else {
        setIsLoggedIn(true);
      }
    }
  }, []);
  // const handleKeyPress = (e) => {
  //   if (e.target.value.length >= 10) {
  //     e.preventDefault();
  //   }
  // };

  const navigate = useNavigate();

  const handleAdminLogin = () =>{
    navigate("/admin/login")
    dispatch(authPage(false));
    dispatch(showAuth(false))

  }

  const handleOTPSubmit = async () => {
    try {
      dispatch(setLoading(true));
      const otpRes = await api.post("/validateOTP", {
        mobile: values.mobile,
        OTP: values.OTP,
      });

      dispatch(setLoading(false));
      if (otpRes.data.mes === "FOUND") {
        dispatch(setLoading(false));
        apiRequest(
          "POST",
          "login",
          {
            mobile: values.mobile,
            password: encrypt(values.password),
          },
          false,
          false
        )
          .then((res) => {
            if (res.status === 200) {
              dispatch(setLoading(false));
              localStorage.removeItem("adminData");
              localStorage.removeItem("adminToken");
              localStorage.setItem("userData", JSON.stringify(res.data));
              localStorage.setItem("userToken", res.data.token);
              setIsLoggedIn(true);
              toast.success("Logged In Successfully");
              dispatch(showAuth(false));
            } else if (res.status === 500) {
              dispatch(setLoading(false));
              window.M.toast({ html: "User not found" });
            } else {
              dispatch(setLoading(false));
              window.M.toast({ html: "Wrong password" });
            }
          })
          .catch((loginError) => {
            dispatch(setLoading(false));
            console.error(loginError);
            setFieldError("field", "message");
          });
      } else {
        dispatch(setLoading(false));
        toast.error(otpRes.data.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      errorsz();
      console.log("Error validating OTP:", error);
    }
  };

  const handleLogout = () => {
    // Perform logout actions here
    localStorage.removeItem("adminData");
    localStorage.removeItem("adminToken");
    setIsLoggedIn(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit(); // Call the handleSubmit function when Enter key is pressed
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      if (!showSendOTP) {
        handleSubmit();
      } else {
        handleOTPSubmit();
      }
    }
  };

  return isLoggedIn ? (
    <>
      <div className="auth">
        <div className="modal-content">
          <h2>Logout</h2>
          <button onClick={handleLogout}>Logout</button>
        </div>
      </div>
    </>
  ) : (
    <>
      {!showSendOTP ? (
        <div className="auth">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <button
              style={{
                cursor: "pointer",
                fontSize: "20px",
                width: "31px",
                padding: "0px",
                marginTop: "0px",
                marginLeft: "auto", // Push the button to the right
              }}
              className="delete-cart"
              onClick={() => dispatch(showAuth(false))}
            >
              <b>X</b>
            </button>
          </div>
          <h2 style={{ margin: "0 auto" }}>Log In</h2>
          <p>Please enter your details</p>
          <input
            type="text"
            placeholder="Mobile Number"
            maxLength={10}
            onKeyDown={handleKeyDown}
            onChange={handleChange("mobile")}
          />
          {errors.mobile && <div className="err">{errors.mobile}</div>}
          <input
            type="password"
            placeholder="Password"
            onKeyDown={handleKeyDown}
            onChange={handleChange("password")}
          />
          {errors.password && <div className="err">{errors.password}</div>}
          <span
            className="forgot"
            onClick={() => dispatch(authPage("forgotPassword"))}
          >
            Forgot Password?
          </span>
          <button onClick={handleSubmit}>Log in</button>
          <div className="bottom-text">
            Don’t have an account ?{" "}
            <span onClick={() => dispatch(authPage("signup"))}>Sign up</span>
          </div>
          <div className="bottom-text">
           Login As Admin ?{" "}
            <span onClick={handleAdminLogin}>Admin</span>
          </div>
        </div>
      ) : (
        <div className="auth">
          <p> Please enter your OTP</p>
          <input
            type="number"
            placeholder="OTP"
            onChange={(e) => setFieldValue("OTP", e.target.value)}
            onKeyPress={handleEnterKeyPress}
          />
          {errors.OTP && <div className="err">{errors.OTP}</div>}
          <button onClick={handleOTPSubmit}>Submit</button>
        </div>
      )}
      <Toaster position="top-right" />
    </>
  );
};

export default Login;

// import React, { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
// import { useFormik } from 'formik';
// import * as yup from 'yup';

// import { authPage, showAuth } from '../../redux/slices';
// import api from '../../api';

// const Login = () => {

//     const dispatch = useDispatch();

//     const initialValues = {
//         username: '',
//         password: '',
//     }

//     const validationSchema = yup.object({
//         username: yup.string().required('Please enter username'),
//         password: yup.string().required('Please enter password'),
//     })

//     const { values, errors, handleSubmit, handleChange } = useFormik({
//         initialValues,
//         validationSchema,
//         validateOnBlur: false,
//         validateOnChange: false,
//         onSubmit: async () => {
//             // localStorage.setItem('loggedin', 'true');

//             const { username, password } = values;

//             const { data } = await api.post('/login', { username, password });

//             if (data.status === 200) {
//                 alert('Logged in');
//                 dispatch(showAuth(false));
//             } else {
//                 alert('Username or password is wrong');
//             }
//         }
//     });

//     return (<div className='auth'>
//         <h2>Log in</h2>
//         <p>Please enter your details</p>
//         <input type='text' placeholder='Username' onChange={handleChange('username')} />
//         {errors.username && (<div className='err'>{errors.username}</div>)}
//         <input type='text' placeholder='Password' onChange={handleChange('password')} />
//         {errors.password && (<div className='err'>{errors.password}</div>)}
//         <span className='forgot' onClick={() => dispatch(authPage('forgotPassword'))}>Forgot Password?</span>
//         <button onClick={handleSubmit}>Log in</button>
//         <div className='bottom-text'>Don’t have an account ? <span onClick={() => dispatch(authPage('signup'))}>Sign up</span></div>
//     </div>);
// }

// export default Login;
