import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from '../../../api';
import { encrypt } from "../crypto";
import SideBar from "../components/sideBar";

const Logout = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true); // Set to true to show the modal initially

  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const handleSubmit = async () => {
    localStorage.clear();
    navigate("/admin/login");
    window.location.reload();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // useEffect hook to handle the logout when the modal is displayed
  // useEffect(() => {
  //   if (showModal) {
  //     handleSubmit();
  //   }
  // }, [showModal]);

  return (
    <div style={{ display: "flex", gap: "3rem" }}>
      <SideBar />
      {showModal && <div className="backdrop" />}
      {showModal && (
        <div className="modal2">
          <div className="modal-content2">
            <h6>Are you sure you want to logout?</h6>
            <div className="modal-buttons">
              <div style={{ marginTop: "1rem" }} className="modal-buttons">
                <button className="btn" onClick={handleCloseModal}>
                  Cancel
                </button>
                <button
                  style={{ marginLeft: "1rem" }}
                  className="btn"
                  onClick={handleSubmit}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Logout;
