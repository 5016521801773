import React, { useState, useEffect } from "react";
import SideBar from "../components/sideBar";
import ProductForm from "../components/shop/sectionA";
import ProductDetailsForm from "../components/shop/sectionB";
import SNRForm from "../components/shop/sectionC";
import callShopApi from "../callApi/shop";
import Slider from "../components/shop/Slider";
import DescImages from "../components/shop/sectionD";
import Autocompletes from "../components/shop/Autocomplete";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices";
import apiRequest from "../../../apiCall";

const Shop = () => {
  const [apiCalled, setApiCalled] = useState(false);
  const [first, setFirst] = useState(false);
  const dispatch = useDispatch();

  const change = () => {
    const inputField = document.getElementsByClassName("input-field");

    for (let i = 0; i < inputField.length; i++) {
      const element = inputField[i].childNodes;
      if (element[0].value !== "") {
        element[1].classList.add("active");
      }
    }
  };

  const handleChange = (inputValue) => {
    dispatch(setLoading(true));
    setFirst(false);
    sessionStorage.removeItem("shopApi");
    callShopApi(inputValue)
      .then((shopData) => {
        dispatch(setLoading(false));
        setApiCalled(true);
        setFirst(true);
        change();
      })
      .catch((error) => {
        console.error("Error calling shop API:", error);
      });
  };

  useEffect(() => {
    sessionStorage.removeItem("shopApi");
    // setApiCalled(true);
    // setFirst(true);
    // setApiCalled(false);
  }, []);

  const handleChildValueChange = (id) => {
    setFirst(false);
    handleChange(id);
  };

  const handleRefreshClick = () => {
    dispatch(setLoading(true));
    setFirst(false);
    apiRequest("POST", "refreshItem", null, true, true)
      .then((res) => {
        dispatch(setLoading(false));
        if (res?.message) {
          window.M.toast({ html: res.message });
        }
      })
      .catch((error) => {
        console.error("Error calling home API:", error);
      });
  };

  return (
    <div className="page shop">
      <SideBar />
      <div className="container">
        <br />
        <Autocompletes
          onChange={handleChange}
          refreshClick={handleRefreshClick}
        />
        {first ? (
          <>
            <Slider
              autoComplete={apiCalled}
              onValueChange={handleChildValueChange}
            />
            <ProductForm
              autoComplete={apiCalled}
              onValueChange={handleChildValueChange}
            />
            <ProductDetailsForm
              autoComplete={apiCalled}
              onValueChange={handleChildValueChange}
            />
            <DescImages
              autoComplete={apiCalled}
              onValueChange={handleChildValueChange}
            />
            {/* <SNRForm autoComplete={apiCalled} /> */}
          </>
        ) : (
          <h6 style={{ margin: "1rem", fontWeight: "bold" }}>Select item</h6>
        )}
      </div>
    </div>
  );
};

export default Shop;
