import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCarousel } from "use-carousel-hook";
import ReactHtmlParser from 'react-html-parser';

import icons from '../../constants/icons';
import images from '../../constants/images';


function Card(props) {

  
    return (
      
        <div className='slide-card-main'>
          <p className='slide-card-num'>{props.num}</p>
          <p className="slide-card-text1">{decodeURI(props.text1)}</p>
          <img className='slide-card-img' src={props.img} />
          <p className="slide-card-text2">
            {props.text2}
          </p>
          <br/>
        <p>{props.date}</p>
        </div>
    
    );
  }

export default function WhatPeople(){

  const [data, setData] = useState(null);

    const reduxdata = useSelector((state) => state.homeApi.value);
  
    useEffect(() => {
      let interval;
      if (data && data.showinwebsite) {
        interval = setInterval(() => {
          next();
        }, 20000); // Change slide every 2 seconds (2000 milliseconds)
      }
      return () => {
        clearInterval(interval); // Clear the interval on component unmount
      };
    }, [data]);
    
    useEffect(() => {
      if (reduxdata) {
        setData(reduxdata.sectionH);
      }




    }, [reduxdata]);



    const { ref, previous, next, setCurrent, reset } = useCarousel();

    return(<>

{ data && data.showinwebsite ? (
        <div className='test-sec'>
            <div className='test-title'> 
                <p>{data.heading}</p>
            </div>

            <div className='test-btn-div'> 
            <button className='prev-btn'
  onClick={() => previous()}
  style={{ background: "none", border: "none",cursor:"pointer" }}
>
 <p>Previous </p> 
 <p style={{fontSize:"2rem", margin:""}}>──</p>
</button>
<button className='next-btn'
  onClick={() => next()}
  style={{ background: "none", border: "none",cursor:"pointer" }}
>
<p style={{fontSize:"2rem", margin:""}}>──</p>
  <p>Next</p>
 
</button>
            </div>
            <div className='test-main-div'>
            <ul ref={ref} className="carousel__list8">

{
 data.content.map((item, index) => (
            <li className="carousel__item8">
            <Card num={"0"+index} key={index} img={images.quote} text1={ReactHtmlParser(item.name)} 
          text2={item.review} date={item.date} />
        </li>))
    
}
            </ul>
            </div>
        </div> ):<></>}
    
    </>)

}





// import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
// import { useCarousel } from "use-carousel-hook";

// import icons from '../../constants/icons';
// import images from '../../constants/images';


// // const WhatPeople = () => {

// //     const [data, setData] = useState(null);
// //     const [index, setIndex] = useState(0);
// //     const [isMobile, setIsMobile] = useState(false);

// //     const reduxdata = useSelector(state => state.homeApi.value);

// //     useEffect(() => {
// //         if (reduxdata) {
// //             setData(reduxdata.sectionE);
// //         };
// //     }, [reduxdata]);

// //     useEffect(() => {
// //         changeView();
// //     }, [])

// //     const handleNext = () => {
// //         if (index < data.reviews.length - 1) {
// //             setIndex(isMobile ? index + 1 : index + 2);
// //         }
// //     };

// //     const handlePrevios = () => {
// //         if (index > 0) {
// //             setIndex(isMobile ? index - 1 : index - 2)
// //         }
// //     };

// //     const changeView = () => {
// //         if (document.body.offsetWidth <= 1180) {
// //             setIsMobile(true);
// //         } else {
// //             setIsMobile(false);
// //         }
// //     };

// //     window.addEventListener('resize', changeView);

// //     return (data ? (
// //         <div className='what-people'>
// //             <h2>{data.heading}</h2>
// //             {/* <p>{data.content}</p> */}
// //             <main>
// //                 <div className='label previos' onClick={handlePrevios}>Previos<span></span></div>
// //                 <div className='label next' onClick={handleNext}><span></span>Next</div>
// //                 <section>
// //                     <div className='sub-section first'>
// //                         <h3>
// //                             {data.reviews[index].personName}
// //                             <span>{'0' + (index + 1)}</span>
// //                         </h3>
// //                         <p>{data.reviews[index].review}</p>
// //                         {/* <span>{data.reviews[index].review}</span> */}
// //                         <div className='time'>September 24, 2017 | 1:43 PM</div>
// //                         <img src={icons.quotes} />
// //                     </div>
// //                 </section>
// //                 {!isMobile && (
// //                     <section>
// //                         {data.reviews[index + 1] && (
// //                             <div className='sub-section'>
// //                                 <h3>
// //                                     {data.reviews[index + 1].personName}
// //                                     <span>{'0' + (index + 2)}</span>
// //                                 </h3>
// //                                 <p>{data.reviews[index + 1].review}</p>
// //                                 {/* <span>{data.reviews[index + 1].review}</span> */}
// //                                 <div className='time'>September 24, 2017 | 1:43 PM</div>
// //                                 <img src={icons.quotes} />
// //                             </div>
// //                         )}
// //                     </section>
// //                 )}
// //             </main>
// //         </div>
// //     ) : <></>)
// // }

// // export default WhatPeople;

// function Card(props) {
//     return (
      
//         <div className='slide-card-main'>
//           <p className='slide-card-num'>{props.num}</p>
//           <p className="slide-card-text1">{props.text1}</p>
//           <img className='slide-card-img' src={props.img} />
//           <p className="slide-card-text2">
//             {props.text2}
//           </p>
//         </div>
    
//     );
//   }

// export default function WhatPeople(){
//     const { ref, previous, next, setCurrent, reset } = useCarousel();

//     return(<>
//         <div className='test-sec'>
//             <div className='test-title'> 
//                 <p>What people are saying about Keshroot</p>
//             </div>

//             <div className='test-btn-div'> 
//             <button className='prev-btn'
//   onClick={() => previous()}
//   style={{ background: "none", border: "none",cursor:"pointer" }}
// >
//  <p>Previous </p> 
//  <p style={{fontSize:"2rem", margin:""}}>──</p>
// </button>
// <button className='next-btn'
//   onClick={() => next()}
//   style={{ background: "none", border: "none",cursor:"pointer" }}
// >
// <p style={{fontSize:"2rem", margin:""}}>──</p>
//   <p>Next</p>
 
// </button>
//             </div>
//             <div className='test-main-div'>
//             <ul ref={ref} className="carousel__list8">
//             <li className="carousel__item8">
//             <Card num='01' img={images.quote} text1="Saloni Acharya" 
//           text2="I've been using Keshroot for a few months now and I am blown
//           away by the results. My hair feels stronger and healthier than ever before. The blend
//           of oils seems to be working wonders on my scalp and roots. I also love that it's all
//           natural and made with ayurvedic ingredients. Highly recommend" />
//         </li>
//         <li className="carousel__item8">
//           <Card num='ㅤ02' img={images.quote} text1="Anjali Joshi" 
//           text2="Main Keshroot ko ek mahine se use kar rahi hoon aur mujhe ye bahut
//           accha lag raha hai. Meri baal aur scalp dono ki condition bahut acchi ho gayi hai. Ye 11
//           oils ka blend bahut faydemand hai meri hair problems ko solve karne ke liye. Aur ye
//           bhi acchi baat hai ki ye bilkul natural hai aur ayurvedic ingredients se bana hua hai.
//           Main iss hair oil ko bahut recommend karti hoon." />
//         </li>
//         <li className="carousel__item8">
//           <Card num='ㅤ03'img={images.quote} text1="Nidhi Patel" 
//           text2="I have really dry and damaged hair, but since using Keshroot, I've
//           noticed a huge difference. My hair is much more hydrated and has a healthy shine to
//           it. I also love the refreshing scent of the oils. Would definitely repurchase" />
//         </li>
//         <li className="carousel__item8">
//           <Card num='ㅤ04' img={images.quote} text1="Shital Nair" 
//           text2="Mere baal bahut dry aur damaged hain, lekin Keshroot se use karne se
//           maine dekha hai ki meri baal bahut hydrated hain aur ek healthy shine hai. Mujhe
//           oils ki refreshing scent bhi bahut pasand hai. Main iss hair oil ko definitely dobara
//           kharidungi." />
//         </li>
//         <li className="carousel__item8">
//           <Card num='05' img={images.quote} text1="Chaitali Shah" 
//           text2="I was a bit sceptical about trying an ayurvedic hair oil, but Keshroot
//           has completely changed my mind. It's been a game changer for my hair. My scalp is
//           no longer dry and flaky, and my hair looks and feels much stronger. I've even noticed
//           some new growth! Highly recommend giving this a try." />
//         </li>
//         <li className="carousel__item8">
//           <Card num='06' img={images.quote} text1="Pooja Purohit"
//            text2="I have really curly hair that can be difficult to manage, but Keshroot
//            has made a huge difference. It helps to define my curls and keeps them looking
//            healthy and hydrated. I also love that it's all-natural and made with ayurvedic
//            ingredients. Will definitely continue to use this product." />
//         </li>
//         <li className="carousel__item8">
//           <Card num='07' img={images.quote} text1="Deepika Mistri"
//            text2="I love the scent of Keshroot and the way it leaves my hair feeling so
//            soft and nourished. It's a great all-natural alternative to some of the harsh chemical
//            products out there. I've noticed a significant improvement in the overall health of my
//            hair and scalp since using this oil. Would repurchase." />
//         </li>

//             </ul>
//             </div>
//         </div> 
    
//     </>)

// }





// <ul ref={ref} className="carousel__list8">
//         <li className="carousel__item8">
//           <Card text1="Saloni Acharya" 
//           text2="I've been using Keshroot for a few months now and I am blown
//           away by the results. My hair feels stronger and healthier than ever before. The blend
//           of oils seems to be working wonders on my scalp and roots. I also love that it's all
//           natural and made with ayurvedic ingredients. Highly recommend" />
//         </li>
        // <li className="carousel__item8">
        //   <Card  text1="Anjali Joshi" 
        //   text2="Main Keshroot ko ek mahine se use kar rahi hoon aur mujhe ye bahut
        //   accha lag raha hai. Meri baal aur scalp dono ki condition bahut acchi ho gayi hai. Ye 11
        //   oils ka blend bahut faydemand hai meri hair problems ko solve karne ke liye. Aur ye
        //   bhi acchi baat hai ki ye bilkul natural hai aur ayurvedic ingredients se bana hua hai.
        //   Main iss hair oil ko bahut recommend karti hoon." />
        // </li>
        // <li className="carousel__item8">
        //   <Card  text1="Nidhi Patel" 
        //   text2="I have really dry and damaged hair, but since using Keshroot, I've
        //   noticed a huge difference. My hair is much more hydrated and has a healthy shine to
        //   it. I also love the refreshing scent of the oils. Would definitely repurchase" />
        // </li>
        // <li className="carousel__item8">
        //   <Card text1="Shital Nair" 
        //   text2="Mere baal bahut dry aur damaged hain, lekin Keshroot se use karne se
        //   maine dekha hai ki meri baal bahut hydrated hain aur ek healthy shine hai. Mujhe
        //   oils ki refreshing scent bhi bahut pasand hai. Main iss hair oil ko definitely dobara
        //   kharidungi." />
        // </li>
        // <li className="carousel__item8">
        //   <Card text1="Chaitali Shah" 
        //   text2="I was a bit sceptical about trying an ayurvedic hair oil, but Keshroot
        //   has completely changed my mind. It's been a game changer for my hair. My scalp is
        //   no longer dry and flaky, and my hair looks and feels much stronger. I've even noticed
        //   some new growth! Highly recommend giving this a try." />
        // </li>
        // <li className="carousel__item8">
        //   <Card text1="Pooja Purohit"
        //    text2="I have really curly hair that can be difficult to manage, but Keshroot
        //    has made a huge difference. It helps to define my curls and keeps them looking
        //    healthy and hydrated. I also love that it's all-natural and made with ayurvedic
        //    ingredients. Will definitely continue to use this product." />
        // </li>
        // <li className="carousel__item8">
        //   <Card text1="Deepika Mistri"
        //    text2="I love the scent of Keshroot and the way it leaves my hair feeling so
        //    soft and nourished. It's a great all-natural alternative to some of the harsh chemical
        //    products out there. I've noticed a significant improvement in the overall health of my
        //    hair and scalp since using this oil. Would repurchase." />
        // </li>
//       </ul>


// <div style={{ marginLeft: "44%", marginTop: "5%" }}>
{/* <button
  onClick={() => previous()}
  style={{ background: "none", border: "none" }}
>
  <img style={{ cursor: "pointer" }} src={images.ytlogo} />
</button> */}

// <button
//   onClick={() => next()}
//   style={{ background: "none", border: "none" }}
// >
//   <img
//     className="sixthline"
//     style={{ marginLeft: "8%", cursor: "pointer" }}
//     src={images.ytlogo}
//     alt="fourthredline"
//   />
//   <img
//     className="sixthcircle"
//     style={{
//       marginTop: "-20%",
//       marginLeft: "-19%",
//       cursor: "pointer",
//       marginBottom: "-15%",
//     }}
//     src={images.ytlogo}
//     alt="fourthredcir"
//   />
// </button>
// </div>