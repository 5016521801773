import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

import { useFormik } from "formik";
import * as yup from "yup";

import images from "../../constants/images";
import icons from "../../constants/icons";

import Footer from "../../components/footer";
import Header from "../../components/header";

import "../../css/contactus.css";
import { fetchContactApi } from "../../redux/slices";
import Form from "../../components/ContactUs/Form";
import config from "../../pages/Admin/Config.json";

const ContactUs = () => {
  document.title = "Contact us - keshroot";

  const dispatch = useDispatch();
  const data = useSelector((state) => state.contactApi.value);

  // console.log(data);

  useEffect(() => {
    dispatch(fetchContactApi());
  }, []);

  return (
    <>
      {data ? (
        <>
          <Header activepage="contact-us" />
          <div className="container-section">
            <section id="form">
              {/* <h2>Contact us</h2> */}
              <h2>{data.title}</h2>
              {/* <p>Get in touch and let us know how we can help. Have a question but aren't sure who to contact? Get in touch and a member of our team will reach out to you.</p> */}
              <p>{data.description}</p>
              <div className="form">
                <Form />
              </div>
            </section>
            <section id="info">
              <img id="logo" src={config.awsMediaPath + data.media} />
              <h3>Address:</h3>
              {/* <p>Smart Laboratories Pvt. Ltd.
                    <br />
                    1004,Brooklyn Tower,
                    <br />
                    Nr. YMCA Club,
                    <br />
                    S. G.
                    Highway, Ahmedabad 380051 (Guj.)
                </p> */}
              <p>{data.address}</p>
              <h3>Phone:</h3>
              <a href={`tel:${data.phone}`}>
                {/* <p>+91 79-49136646</p> */}
                <p>{data.phone}</p>
              </a>
              <h3>Whatsapp:</h3>
              <a href={`tel:${data.whatsapp}`}>
                <p>{data.whatsapp}</p>
              </a>
              <h3>Email</h3>
              <a href={`mailto:${data.email}`}>
                <p>{data.email}</p>
              </a>
              <div className="social">
                <h3>Stay connected</h3>
                <main>
                  <a href="https://www.facebook.com/keshroot" target="_blank">
                    <img src={icons.facebookBlack} />
                  </a>
                  <a href="https://www.instagram.com/keshroot/" target="_blank">
                    <img src={icons.instagramBlack} />
                  </a>
                  <a href="https://www.youtube.com/@KESHROOT" target="_blank">
                    <img src={icons.youtubeBlack} />
                  </a>
                </main>
              </div>
            </section>
          </div>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ContactUs;
