import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import SideBar from "../components/sideBar";

import apiRequest from "../../../apiCall";
import config from "../Config.json";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices";

const Blog = () => {
  const [data, setData] = useState([]);

  const getApiData = async () => {
    return new Promise((resolve, reject) => {
       dispatch(setLoading(true));
      apiRequest('GET', 'blog/admin', null, true, true).then(res => {
        dispatch(setLoading(false));
        sessionStorage.setItem("blogApi", JSON.stringify(res));
        resolve(res);
        setData(res.data);

      })
        .catch(error => {
          dispatch(setLoading(false));
          console.error('Error calling Blog API:', error);
          reject(error);
        });
    });
  };

  const dispatch = useDispatch();

  useEffect(() => {
   
    getApiData();
    
  }, []);

  return (
    <div className="page admin-blog">
      <SideBar />
      <div className="container">
        <h2>Blog posts</h2>
        <button className="add_blog"><Link to="/admin/blog/add" className="add-blog-post">
          + Add blog post
        </Link></button>
        <div className="posts">
          {data.map((item) => (
            <Link className="post card" to={"/admin/blog/edit/" + item._id}>
              <div className="posts-image" style={{width:'30%',height:'150px', marginRight:'15px'}}>
              <img src={config.awsMediaPath + item.feature_image} /></div>
              <section style={{width:'100%'}}>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <p><strong>Created By:</strong> {item.adminFirstName} {item.adminLastName}</p>
                <span>Status: {item.status}</span>
              </section>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Blog;
