import React, { useEffect, useState } from "react";
import icons from "../../../../constants/icons";
import MediaPicker from "../mediaePicker";
import { callSectionD } from "../../callApi/home";
import config from "../../Config.json";
import { fileUpload } from "../../../../fileUpload";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/slices";

const SectionD = () => {
  const storedData = localStorage.getItem("adminData") || " {}";
  const adminData = JSON.parse(storedData);
  const AdminId = adminData._id || null;
  const [data, setData] = useState({
    AdminId: AdminId,
    title: "",
    item: [
      {
        title: "",
        image: { image: "", file: "", status: "none" },
      },
    ],
  });

  const _data = data;
  const [option, setOption] = useState(false);

  const updateData = () => {
    setData((data) => ({
      ...data,
      ..._data,
    }));
  };

  const isEmpty = () => {
    let res = false;
    data.item.forEach((item) => {
      if (item.title && item.image) {
        res = true;
      } else {
        res = false;
      }
    });
    return res;
  };
  const dispatch = useDispatch();
  const handleSave = async () => {
    dispatch(setLoading(true));
    let isValidate = isEmpty();
    if (isValidate) {
      let updatedImages = data.item.reduce((accumulator, item) => {
        if (item.image.status === "updated" && item.image.file != null) {
          return accumulator.concat(item.image);
        }
        return accumulator;
      }, []);

      let media = [];

      if (updatedImages.length > 0) {
        fileUpload(updatedImages).then((uploadImageRes) => {
          if (uploadImageRes?.length > 0) {
            media = data.item.map((x) => {
              if (x?.image?.status === "updated") {
                return uploadImageRes.filter(
                  (y) => y.fileName == x.image.file.name
                )[0]?.key;
              } else {
                return x.image.image;
              }
            });
            saveData(media);
          }
        });
      } else {
        media = data.item.map((x) => x.image.image);
        saveData(media);
      }
    } else {
      dispatch(setLoading(false));
      window.M.toast({ html: "Please fill all values" });
    }
  };

  const saveData = (media) => {
    const params = {
      media: media,
      title: data.title,
      AdminId: AdminId,
      item: data.item.map((item) => {
        return { title: item.title };
      }),
      showinwebsite: option ? 1 : 0,
    };
    callSectionD(params).then((res) => {
      if (res) {
        dispatch(setLoading(false));
        window.M.toast({ html: "Data saved successfully" });
      } else {
        dispatch(setLoading(false));
        window.M.toast({ html: "Something went wrong" });
      }
    });
  };

  useEffect(() => {
    const secDData = JSON.parse(sessionStorage.getItem("homeApi")).sectionD;
    _data.title = secDData.heading;
    secDData.content.forEach((item, index) => {
      _data.item[index] = {
        title: item.title,
        image: { image: secDData.media[index] },
        imageName: config.awsMediaPath + secDData.media[index],
      };
    });
    setOption(secDData.showinwebsite == 1 ? true : false);

    updateData();
  }, []);

  return (
    <div className="section-d section-h sec">
      <h2>Section - D</h2>

      <div className="add">
        <img
          src={icons.pick2}
          onClick={() => {
            _data.item.push({
              name: "",
              review: "",
              date: "",
              image: { image: "", file: "", status: "none" },
            });
            updateData();
          }}
        />
      </div>

      <main>
        {data.item.map((item, index) => (
          <div className="card">
            <div className="input-field">
              <input
                id={"section-i-card-title" + index}
                type="text"
                className="validate"
                onChange={(text) => {
                  text = text.currentTarget.value;
                  _data.item[index].title = text;
                  updateData();
                }}
                value={item.title}
              />
              <label for={"section-i-card-title" + index}>
                Add Instagram Link
              </label>
            </div>

            <MediaPicker
              type="image"
              source={item.imageName}
              onChange={(file, image) => {
                _data.item[index].image.file = file;
                _data.item[index].image.image = image;
                _data.item[index].imageName = image;
                _data.item[index].image.status = "updated";
                updateData();
              }}
            />
            <button
              className="btn"
              onClick={() => {
                const newArray = _data.item.filter(
                  (item) => _data.item.indexOf(item) !== index
                );
                _data.item = newArray;
                updateData();
              }}
            >
              Remove
            </button>
          </div>
        ))}
      </main>
      <div className="input-field">
        <label>
          <input
            type="checkbox"
            checked={option}
            onChange={() => setOption(!option)}
          />
          <span>Is Active</span>
        </label>
      </div>
      <button className="btn save" onClick={handleSave}>
        Save
      </button>
    </div>
  );
};

export default SectionD;
