import React, { useEffect, useState } from "react";

import SideBar from "../components/sideBar";

import callAdminUsersApi from "../callApi/adminUsers";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices";
import ReactPaginate from "react-paginate";

const ITEMS_PER_PAGE = 10;

const AdminUsers = () => {
  const navigate = useNavigate();
  const [usersData, setUsersData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [revealedPasswords, setRevealedPasswords] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoading(true));
    callAdminUsersApi().then((res) => {
      dispatch(setLoading(false));
      setUsersData(res);
    });
  }, []);

  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  const togglePasswordVisibility = (id) => {
    if (revealedPasswords.includes(id)) {
      // If the clicked password is already revealed, close it
      setRevealedPasswords([]);
    } else {
      // Otherwise, toggle the clicked password
      setRevealedPasswords([id]);
    }
  };

  const paginatedData = usersData.slice(
    currentPage * ITEMS_PER_PAGE,
    (currentPage + 1) * ITEMS_PER_PAGE
  );

  return (
    <div className="admin-users page">
      <SideBar />
      <div className="container">
        <br />
        <br />
        <Link
          to="/admin/users/create"
          className="add-user_">
          + Add User
        </Link>
        <br />
        <br />
        <table className="card">
          <tbody>
            <tr>
              <th>First name</th>
              <th>Last name</th>
              <th>Username</th>
              <th>Password</th>
              <th>Role</th>
            </tr>
            {Array.isArray(paginatedData) && paginatedData.length > 0 ? (
            paginatedData.map((item) => (
              <tr
                style={{ cursor: "pointer" }}
                key={item._id}
                onClick={() => navigate("/admin/users/edit/" + item._id)}
              >
                <td>{item.first_name}</td>
                <td>{item.last_name}</td>
                <td>{item.username}</td>
                <td onClick={(e) => e.stopPropagation()}>
                  {revealedPasswords.includes(item._id) ? (
                    item.password
                  ) : (
                    <span
                      onClick={() => togglePasswordVisibility(item._id)}
                      onBlur={() => setRevealedPasswords([])}
                      tabIndex={0}
                    >
                      ********
                    </span>
                  )}
                </td>
                <td>{item.role}</td>
              </tr>
            ))
            ):(
              <tr>
                <td colSpan="5">No Record Found</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="enquiryPagination">
          <ReactPaginate
            pageCount={Math.ceil(usersData.length / ITEMS_PER_PAGE)}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
          />
        </div>
      </div>
    </div>
  );
};

export default AdminUsers;
