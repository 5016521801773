import React, { useEffect, useState } from "react";
import SideBar from "../components/sideBar";
import callBlogCommentApi from "../callApi/blogcomment";
import { Checkbox } from "@mui/material";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices";
import formatDate from "../../../components/dateFormatter";

const Blogcomment = () => {
  const disapatch = useDispatch();
  const [usersData, setUsersData] = useState([]);

  useEffect(() => {
    disapatch(setLoading(true));
    callBlogCommentApi().then((res) => {
      disapatch(setLoading(false));
      setUsersData(res);
    });
  }, []);

  return (
    <div className="page contact-us">
      <SideBar />
      <div className="container">
        <h2>Blog Comments</h2>
        <br />
        <table className="card">
          <tbody>
            <tr>
              <th>Title</th>
              <th>Name</th>
              <th>Email</th>
              <th>Message</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
            {Array.isArray(usersData) && usersData.length > 0 ? (
            usersData.map((item) => (
              <tr>
                <td>{item.blogTitle}</td>
                <td>{item.firstname}</td>
                <td>{item.email}</td>
                <td>{item.message}</td>
                <td>{formatDate(item.date)}</td>
                <td>{item.Status}</td>
              </tr>
            ))
            ):(
              <tr>
                <td colSpan="5">No Record Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Blogcomment;
