const icons = {
    location: require('../assets/icons/location.png'),
    facebookBlack: require('../assets/icons/facebook_black.png'),
    instagramBlack: require('../assets/icons/instagram_black.png'),
    twitterBlack: require('../assets/icons/twitter_black.png'),
    youtubeBlack: require('../assets/icons/youtube_black.png'),
    search: require('../assets/icons/search_black.png'),
    user: require('../assets/icons/user_black.png'),
    shoppingBag: require('../assets/icons/shopping_bag_black.png'),
    rightArrow: require('../assets/icons/right_arrow.png'),
    leave: require('../assets/icons/leave.png'),
    genetic: require('../assets/icons/genetic.png'),
    medical: require('../assets/icons/medical.png'),
    purity: require('../assets/icons/purity.png'),
    ethically: require('../assets/icons/ethically.png'),
    natural: require('../assets/icons/natural.png'),
    authentically: require('../assets/icons/authentically.png'),
    aloevera: require('../assets/icons/aloevera.png'),
    quotes: require('../assets/icons/quotes.png'),
    grofers: require('../assets/icons/grofers.png'),
    amazon: require('../assets/icons/amazon.png'),
    metro: require('../assets/icons/metro.png'),
    bigbasket: require('../assets/icons/bigbasket.png'),
    flipkart: require('../assets/icons/flipkart.png'),
    ratnadeep: require('../assets/icons/ratnadeep.png'),
    foodhall: require('../assets/icons/foodhall.png'),
    paytmmall: require('../assets/icons/paytmmall.png'),
    swiggyinstamart: require('../assets/icons/swiggyinstamart.png'),
    whsmith: require('../assets/icons/whsmith.png'),
    logoOrange: require('../assets/icons/logo_orange.png'),
    phoneOrange: require('../assets/icons/phone_orange.png'),
    emailOrange: require('../assets/icons/email_orange.png'),
    facebookOrange: require('../assets/icons/facebook_orange.png'),
    pinterestOrange: require('../assets/icons/pinterest_orange.png'),
    twitterOrange: require('../assets/icons/twitter_orange.png'),
    instagramOrange: require('../assets/icons/instagram_orange.png'),
    twitterFullBlack: require('../assets/icons/twitter_fullblack.png'),
    arrowUp: require('../assets/icons/arrow_up.png'),
    arrowDown: require('../assets/icons/arrow_down.png'),
    menu: require('../assets/icons/menu.png'),
    check: require('../assets/icons/check.png'),
    aboutLeftArrow: require('../assets/icons/about_left_arrow.png'),
    aboutRightArrow: require('../assets/icons/about_right_arrow.png'),
    retry: require('../assets/icons/retry.png'),
    leftArrowCircle: require('../assets/icons/left_arrow_circle.png'),
    leftArrow: require('../assets/icons/left_arrow.png'),
    pick2: require('../assets/icons/pick_2.png'),
    // instagramId: require("../assets/icons/instagram_id.png"),
};

export default icons;