import React, { useEffect, useState } from "react";
import SideBar from "../components/sideBar";

import apiRequest from "../../../apiCall";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices";
import { fabClasses } from "@mui/material";

const ShopCommentStatus = () => {
  const [shopCommentData, setShopCommentData] = useState([]);

  const storedData = localStorage.getItem("adminData") || " {}";
  const adminData = JSON.parse(storedData);
  const AdminId = adminData._id || null;

  useEffect(() => {
    callApi();
  }, []);

  const dispatch = useDispatch();

  const callApi = async () => {
    try {
      dispatch(setLoading(true));
      apiRequest("GET", "shopcomment", null, true, true).then((res) => {
        dispatch(setLoading(false));
        setShopCommentData(res.data); // Use response.data.data
      });
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error fetching data:", error);
    }
  };

  const handlePublish = async (commentId) => {
    dispatch(setLoading(true));
    try {
      // Call the API to update the comment status to "Published"
      apiRequest(
        "PUT",
        `shopcommentupdate/${commentId}`,
        { Status: "Published", AdminId },
        true,
        true
      ).then((res) => {
        dispatch(setLoading(false));
        // Update the usersData state to reflect the updated status locally
        setShopCommentData((prevUsersData) =>
          prevUsersData.map((comment) =>
            comment.ID === commentId
              ? { ...comment, Status: "Published" }
              : comment
          )
        );
      });
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error publishing comment:", error);
    }
  };

  const handleDelete = async (commentId) => {
    try {
      dispatch(setLoading(true));
      apiRequest(
        "DELETE",
        `shopcommentdelete/${commentId}`,
        null,
        true,
        true
      ).then((res) => {
        dispatch(setLoading(false));

        // Update the usersData state to remove the deleted comment from the local data
        setShopCommentData((prevUsersData) =>
          prevUsersData.filter((comment) => comment.ID !== commentId)
        );
      });
    } catch (error) {
      dispatch(setLoading(false));
      console.error("Error deleting comment:", error);
    }
  };

  return (
    <div className="page contact-us">
      <SideBar />
      <div className="container">
        <h2>Shop Comments Status</h2>
        <br />
        <table className="card" style={{ overflowX: "auto" }}>
          <thead>
            <tr>
              <th>User Name</th>
              <th>Product Name</th>
              <th>Review</th>
              <th>Rating</th>
              <th>Status</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(shopCommentData) && shopCommentData.length > 0 ? (
              shopCommentData.map((comment) => (
                <tr key={comment.ID}>
                  <td>
                    {comment.FirstName} {comment.LastName}
                  </td>
                  <td>{comment.ProductID}</td>
                  <td>{comment.Review}</td>
                  <td>{comment.Rating}</td>
                  <td>{comment.Status}</td>
                  <td>
                    {/* Conditionally show the Publish button */}
                    {comment.Status !== "Published" ? (
                      <button
                        className="btn"
                        onClick={() => handlePublish(comment.ID)}
                      >
                        Publish
                      </button>
                    ) : null}
                    &nbsp;
                    <button
                      className="btn"
                      onClick={() => handleDelete(comment.ID)}
                    >
                      Discard
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No Record Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ShopCommentStatus;
