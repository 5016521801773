import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import images from '../../constants/images';
import api from "../../api";
import Footer from '../../components/footer';
import Header from '../../components/header';

import '../../css/myprofile.css';

const MyProfile = () => {

    document.title = 'My profile - Keshroot';

    const navigate = useNavigate();
    const [addressHistory, setAddressHistory] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [showAddresses, setShowAddresses] = useState(false);
    const storedData = localStorage.getItem('userData') || ' {}';
    const userData = JSON.parse(storedData);
    const uuid = userData.uuid || null;
    const id = userData.id || null;
    const firstName = userData ? userData.FirstName : null;
    const lastName = userData ? userData.LastName : null;
    const email = userData ? userData.email : null;
    const mobile = userData ? userData.Mobile : null;

    useEffect(() => {
        resize();
    }, []);

    const resize = () => {
        if (document.body.offsetWidth <= 950) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    window.addEventListener('resize', resize);

    useEffect(() => {
        api
            .get(`/customerAddress/${id}`)
            .then((response) => {
                setAddressHistory(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    return (<div className='my-account-profile'>
        <Header activepage='myprofile' />
        <div className='cont'>
            <h1>My Profile</h1>
            <div className='profile-sub'>
                <main>
                    <div className='rs'>
                        <h2>Profile Details</h2>
                        <section>
                            <span><b>Name: </b>{firstName} {lastName}</span>
                            <span><b>Email ID: </b>{email}</span>
                            <span><b>Phone Number: </b>{mobile}</span>
                        </section>
                    </div>
                </main>
            {/* <div className='addresses-sec'>
                <h2>My Addresses</h2>
                {
                    addressHistory.message === "Failed" ? (
                        <div className='rs'>
                        <section> <span><b>No address has been added</b></span></section></div>
                    ) : (
                        <div className='card-sec'>
                            {
                                Object.entries(addressHistory).map(([key, item]) => (
                                    <div className='sub-div'>
                                        <h2>{item.AddressType}</h2>
                                        <div className='adres-sec'>
                                            <p>{item.Address}</p>
                                        </div>
                                        <div className='adres-sec'>
                                            <h4>Country </h4>:&nbsp;<p>{item.Country}</p>,&nbsp;
                                            <h4>State </h4>:&nbsp;<p>{item.State}</p>,
                                        </div>
                                        <div className='adres-sec'>
                                            <h4>City</h4>:&nbsp;<p>{item.City}</p>,&nbsp;
                                            <h4>Area</h4>:&nbsp;<p>{item.Area}</p>,
                                        </div>
                                        <div className='adres-sec'>
                                            <h4>Pincode</h4>:&nbsp;<p>{item.PinCode}</p>
                                        </div>

                                    </div>
                                ))}
                        </div>
                    )
                }
            </div> */}
            </div>
        </div>
        <Footer />
    </div>);
}

export default MyProfile;

/* <table style={{display:"flex", justifyContent:"center"}}>
                                <tbody>
                                    <tr>
                                        
                                    </tr>
                                    {
                                        Object.entries(addressHistory).map(([key, item]) => (
                                            <tr>
                                                <td>{item.AddressType}</td>
                                                <td>{item.Address}</td>
                                                <td>{item.ContryId}</td>
                                                <td>{item.StateId}</td>
                                                <td>{item.CityId}</td>
                                                <td>{item.AreaId}</td>
                                                <td>{item.PinCode}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table> */