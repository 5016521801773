import * as XLSX from 'xlsx';

export const exportToExcel = (data, filename) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
  XLSX.writeFile(workbook, `${filename}.xlsx`);
};

export const exportToCSV = (data, filename) => {
  // Extract column titles from the first item in the data array
  const columns = Object.keys(data[0]);

  // Convert the data to CSV format
  const csvData = [columns.join(',')]; // Include column titles as the first row
  data.forEach(item => {
    const row = columns.map(col => item[col]);
    csvData.push(row.join(','));
  });

  const csvContent = csvData.join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${filename}.csv`);
  link.click();
};

