import Backdrop from '@mui/material/Backdrop';
import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
// import { useSelector } from 'react-redux';

export default function Loader(){

  // const loading = useSelector((state)=> state.component.loading);

  // if (!loading){
  //   return null;
  // }
    return (
       
        <div>
            <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
       
        open
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        </div>
    );
}