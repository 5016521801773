import React, { useEffect, useState } from "react";

import apiRequest from "../../../../apiCall";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../../redux/slices";

const SectionA = ({ autoComplete, onValueChange }) => {
  const storedData = localStorage.getItem("adminData") || " {}";
  const adminData = JSON.parse(storedData);
  const AdminId = adminData._id || null;
  const [data, setData] = useState({
    title: "",
    content: "",
  });

  const _data = data;

  const updateData = () => {
    setData((data) => ({
      ...data,
      ..._data,
    }));
  };

  const dispatch = useDispatch();

  const handleSave = async () => {
    dispatch(setLoading(true));
    if (data.title !== "" && data.content !== "") {
      if (data.title.includes("-")) {
        dispatch(setLoading(false));
        window.M.toast({ html: "Title cannot contain a dash(-)" });
        return;
      }
      const params = {
        itemId: JSON.parse(sessionStorage.getItem("shopApi")).itemId,
        productCode: JSON.parse(sessionStorage.getItem("shopApi")).productCode,
        title: data.title,
        content: data.content,
        AdminId: AdminId,
      };
      apiRequest("POST", "shop/sectionA", params, true, true).then((res) => {
        dispatch(setLoading(false));
        window.M.toast({ html: "Data saved successfully" });
        onValueChange(
          JSON.parse(sessionStorage.getItem("shopApi")).productCode
        );
      });
    } else {
      dispatch(setLoading(false));
      window.M.toast({ html: "Please fill all values" });
    }
  };

  useEffect(() => {
    if (autoComplete) {
      const secAData = JSON.parse(sessionStorage.getItem("shopApi")).sectionA;
      _data.title = secAData.heading;
      _data.content = secAData.content;
      updateData();
    } else {
      _data.title = "";
      _data.content = "";
      updateData();
    }
  }, [autoComplete]);

  return (
    <>
      {/* <div className="sec section-a" style={{ marginTop: "100px" }}> */}
      <h2>Section - A</h2>
      <main>
        <section>
          <div className="input-field">
            <input
              id="section-a-title"
              type="text"
              className="validate"
              onChange={(text) => {
                text = text.currentTarget.value;
                _data.title = text;
                setData((data) => ({
                  ...data,
                  ..._data,
                }));
              }}
              value={data.title}
            />
            <label for="section-a-title">Title</label>
          </div>
          <div className="input-field">
            <textarea
              id="section-a-content"
              className="materialize-textarea"
              onChange={(text) => {
                text = text.currentTarget.value;
                _data.content = text;
                setData((data) => ({
                  ...data,
                  ..._data,
                }));
              }}
              value={data.content}
            ></textarea>
            <label for="section-a-content">Content</label>
          </div>
        </section>
      </main>
      <button className="btn save" id="saveshop" onClick={handleSave}>
        Save
      </button>
    </>
  );
};

export default SectionA;
