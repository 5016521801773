import React, { useEffect, useState } from "react";
import SideBar from "../components/sideBar";
import TextEditor from "../components/textEditor";
import MediaPicker from "../components/mediaePicker";
import GetAboutUsData, { PostAboutUsData } from "../callApi/aboutUs";
import { fileUpload } from "../../../fileUpload";
import config from "../Config.json";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/slices";

const AboutUs = () => {

  const storedData = localStorage.getItem("adminData") || " {}";
  const adminData = JSON.parse(storedData);
  const AdminId = adminData._id || null;

  const [data, setData] = useState({
    featureImage: {
      status: "none",
      image: "",
      file: "",
    },
    featureImage1: {
      status: "none",
      image: "",
      file: "",
    },
    content: "",
    sectionAHtmlContent: "",
    sectionCVideo: { status: "none", file: "", image: "" },
  });

  const _data = data;

  const dispatch = useDispatch();

  const handleSave = async () => {
    dispatch(setLoading(true));
    if (data.content != "" && data.sectionAHtmlContent != "") {
      let updatedImages = [];
      let media = { featureImage: null, featureImage1: null, video: null };
      if (data.featureImage.status === "updated") {
        updatedImages.push(data.featureImage);
      }
      if (data.featureImage1.status === "updated") {
        updatedImages.push(data.featureImage1);
      }
      if (data.sectionCVideo.status === "updated") {
        updatedImages.push(data.sectionCVideo);
      }
      if (updatedImages.length > 0) {
        fileUpload(updatedImages)
          .then((uploadImageRes) => {
            if (uploadImageRes?.length > 0) {
              media = {
                featureImage:
                  data.featureImage.status === "updated"
                    ? uploadImageRes.filter(
                        (x) => x.fileName == data.featureImage.file.name
                      )[0]?.key
                    : data.featureImage.imageName,
                featureImage1:
                  data.featureImage1.status === "updated"
                    ? uploadImageRes.filter(
                        (x) => x.fileName == data.featureImage1.file.name
                      )[0]?.key
                    : data.featureImage1.imageName,
                video:
                  data.sectionCVideo.status === "updated"
                    ? uploadImageRes.filter(
                        (x) => x.fileName == data.sectionCVideo.file.name
                      )[0]?.key
                    : data.sectionCVideo.imageName,
              };
              saveData(media);
            }
          })
          .catch((Err) => {
            window.M.toast({ html: "Please Try Again!!!" });
          });
      } else {
        media = {
          featureImage: data.featureImage.imageName,
          featureImage1: data.featureImage1.imageName,
          video: data.sectionCVideo.imageName,
        };
        saveData(media);
      }
    } else {
      dispatch(setLoading(false));
      window.M.toast({ html: "Please fill all values" });
    }
  };

  const saveData = (media) => {
    const params = {
      content: data.content,
      sectionAHtmlContent: data.sectionAHtmlContent,
      media: media,
      AdminId: AdminId
    };
    PostAboutUsData(params).then((res) => {
      if (res) {
        dispatch(setLoading(false));
        window.M.toast({ html: "Data saved successfully" });
      } else {
        dispatch(setLoading(false));
        window.M.toast({ html: "Something went wrong" });
      }
    });
  };

  const updateData = () => {
    setData((data) => ({
      ...data,
      ..._data,
    }));
  };

  useEffect(() => {
    GetAboutUsData().then((res) => {
      _data.content = res.content;
      _data.sectionAHtmlContent = res.sectionAHtmlContent;
      _data.featureImage.image = config.awsMediaPath + res.media?.featureImage;
      _data.featureImage1.image =
        config.awsMediaPath + res.media?.featureImage1;
      _data.sectionCVideo.image = config.awsMediaPath + res.media?.video;
      _data.featureImage.imageName = res.media?.featureImage;
      _data.featureImage1.imageName = res.media?.featureImage1;
      _data.sectionCVideo.imageName = res.media?.video;
      updateData();
    });
  }, []);

  return (
    <div className="page about-us">
      <SideBar />
      <div className="container">
        {/* <h2>About us</h2> */}
        <br />
        <div className="sec">
          <MediaPicker
            type="image"
            onChange={(file, image) => {
              _data.featureImage.file = file;
              _data.featureImage.image = image;
              _data.featureImage.status = "updated";
              updateData();
            }}
            source={data.featureImage.image}
            text="Feature image"
          />
        </div>
        <div className="sec">
          <h2>Section - A</h2>

          <MediaPicker
            type="image"
            onChange={(file, image) => {
              _data.featureImage1.file = file;
              _data.featureImage1.image = image;
              _data.featureImage1.status = "updated";
              updateData();
            }}
            source={data.featureImage1.image}
            text="Feature image"
          />
        </div>
        <div className="sec card" style={{ padding: 10 }}>
          {/* <h2>Section - A</h2> */}
          <div className="input-field col s12">
            <textarea
              id="about_html_content"
              className="materialize-textarea"
              style={{ height: 200, resize: "none" }}
              onChange={(text) => {
                text = text.currentTarget.value;
                _data.sectionAHtmlContent = text;
                updateData();
              }}
              value={data.sectionAHtmlContent}
            />
            <label for="about_html_content" style={{ left: 0, width: "100%" }}>
              HTML Content
            </label>
          </div>
        </div>
        <div className="sec about-content">
          <h2>Section - B</h2>
          <TextEditor
            onChange={(text) => {
              _data.content = text;
              updateData();
            }}
            value={data.content}
          />
        </div>
        <div className="sec">
          <h2>Section - C</h2>
          <MediaPicker
            text="Video"
            type="video"
            onChange={(file, image) => {
              _data.sectionCVideo = { file, image, status: "updated" };
              updateData();
            }}
            source={data.sectionCVideo.image}
          />
        </div>
        <button className="btn save" onClick={handleSave}>
          save
        </button>
      </div>
    </div>
  );
};

export default AboutUs;
