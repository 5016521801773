import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import SideBar from '../components/sideBar';
import TextEditor from '../components/textEditor';
import MediaPicker from '../components/mediaePicker';

import apiRequest from '../../../apiCall';
import { fileUpload } from '../../../fileUpload';
import config from "../Config.json";
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/slices';

const AddBlogPost = () => {
    
  const storedData = localStorage.getItem("adminData") || " {}";
  const adminData = JSON.parse(storedData);
  const AdminId = adminData._id || null;
    const navigate = useNavigate();

    const { postId } = useParams();

    const [data, setData] = useState({
        featureImage: { image: '', file: '', status: 'none' },
        title: '',
        description: '',
        permalink: '',
        content: '',
        author: '',
    });
    const [buttonText, setButtonText] = useState('Save to draft');

    const _data = data;

    const getBlogPost = async () => {
        if (postId) {
            apiRequest("GET", 'blog-post/' + postId, null, true, true).then(res => {
                res = res;
                _data.title = res.title;
                _data.description = res.description;
                _data.author = res.author;
                _data.permalink = res.permalink;
                _data.status = res.status;
                _data.content = res.content;
                _data.featureImage.image = config.awsMediaPath + res.feature_image;
                _data.featureImage.imageName = res.feature_image;
                updateData();
                if (res.status === 'published') {
                    setButtonText('Revert to draft');
                }
            })
        };
    }

    useEffect(() => {
        getBlogPost();
    }, []);

    const updateData = () => {
        setData(data => ({
            ...data,
            ..._data,
        }));
    };
    const dispatch = useDispatch();
    const handleSave = async (status) => {
        dispatch(setLoading(true));
        if (data.title != '' && data.content != '' && data.author != '' && data.featureImage.image != '') {
            if (data.featureImage.status === "updated") {
                fileUpload([data.featureImage]).then(uploadImageRes => {
                    if (uploadImageRes?.length > 0) {
                        const newUploadedImages = uploadImageRes[0].key;
                        saveData(newUploadedImages, status)
                    }
                })
            } else {
                saveData(data.featureImage.imageName, status)
            }
        } else {
            dispatch(setLoading(false));
            window.M.toast({ html: "Please fill all values" });
        }
    };

    const saveData = (imageUrl, status) => {
        const params = {
            content: data.content,
            title: data.title,
            description: data.description,
            author: data.author,
            featureImage: imageUrl,
            permalink: data.permalink,
            status,
            AdminId: AdminId,
        }
        if (!postId) {
            apiRequest('POST', 'blog/add', params, true, true).then(res => {
                if (res) {
                    dispatch(setLoading(false));
                    window.M.toast({ html: "Data saved successfully" });
                    navigate('/admin/blog', { replace: true });
                } else {
                    dispatch(setLoading(false));
                    window.M.toast({ html: "Something went wrong" });
                }
            })
        } else {
            apiRequest('POST', 'blog/edit/' + postId, params, true, true).then(res => {
                if (res) {
                    dispatch(setLoading(false));
                    window.M.toast({ html: "Data saved successfully" });
                    navigate('/admin/blog', { replace: true });
                } else {
                    dispatch(setLoading(false));
                    window.M.toast({ html: "Something went wrong" });
                }
            })
        }
    }


    return (<div className='page add-blog-post'>
        <SideBar />
        <div className='container'>
            <h2>Add blog post</h2>
            <main>
                <div className='top'>
                    <div className='btns'>
                        <button className='btn' onClick={() => handleSave(('draft'))}>{buttonText}</button>
                        <button className='btn' onClick={() => handleSave('published')}>Publish</button>
                        <Link to='/admin/blog'><button className='btn'>Cancel</button></Link>
                    </div>
                </div>
                <section>
                    <TextEditor
                        onChange={text => {
                            _data.content = text;
                            updateData();
                        }}
                        value={data.content}
                    />
                    <div className='right-side'>
                        <div className='sec add-blog'>
                            <div className='input-field'>
                                <input id='title' type='text' className='validate'
                                    onChange={text => {
                                        text = text.currentTarget.value;
                                        _data.title = text;
                                        updateData();
                                    }}
                                    value={data.title}
                                />
                                <label for='title'>Title</label>
                            </div>
                        </div>
                        <div className='sec add-blog'>
                            <div className='input-field'>
                                <textarea id='description' className='materialize-textarea' style={{ height: 100 }}
                                    onChange={text => {
                                        text = text.currentTarget.value;
                                        _data.description = text;
                                        updateData();
                                    }}
                                    value={data.description}
                                />
                                <label for='description'>Description</label>
                            </div>
                        </div>
                        <div className='sec add-blog'>
                            <div className='input-field'>
                                <input id='permalink' type='text' className='validate'
                                    onChange={text => {
                                        text = text.currentTarget.value;
                                        _data.permalink = text.replaceAll(' ', '-');
                                        updateData();
                                    }}
                                    value={data.permalink}
                                />
                                <label for='permalink'>Permalink</label>
                            </div>
                        </div>
                        <div className='sec add-blog'>
                            <div className='input-field'>
                                <input id='author' type='text' className='validate'
                                    onChange={text => {
                                        text = text.currentTarget.value;
                                        _data.author = text;
                                        updateData();
                                    }}
                                    value={data.author}
                                />
                                <label for='author'>Author</label>
                            </div>
                        </div>
                        <div className='sec add-blog'>
                            <MediaPicker
                                text='Feature image'
                                onChange={(file, image) => {
                                    _data.featureImage = { file, image, status: 'updated' };
                                    updateData();
                                }}
                                type='image'
                                source={data.featureImage.image}
                            />
                        </div>
                    </div>
                </section>
            </main>
        </div>
    </div>);
}

export default AddBlogPost;