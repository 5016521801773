import React from "react";

import Footer from "../../components/footer";
import Header from "../../components/header";
import SectionA from "../../components/shop/sectiona";
import SectionB from "../../components/shop/sectionb";

import "../../css/shop.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import apiRequest from "../../apiCall";

const Shop = () => {
  const navigate = useNavigate();

  useEffect(() => {
    apiRequest("GET", "recommended-products/", null, false, false)
      .then((res) => {
        if (res?.length > 0) {
          const productName = res[0].heading.replaceAll(" ", "-");
          navigate("/shop/" + productName);
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  document.title = "Shop - keshroot";
  return (
    <div className="shop">
      <Header activepage="shop" />
      <div>No records found.</div>
      <Footer />
    </div>
  );
};

export default Shop;
