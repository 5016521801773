import React from 'react';
import { useFormik } from 'formik';
import { useDispatch } from "react-redux";
import * as yup from 'yup';
import api from "../../api";
import {  encrypt } from "../Admin/crypto";
import toast, { Toaster } from "react-hot-toast";
import { setLoading, showAuth } from "../../redux/slices";
import { authPage } from "../../redux/slices";

const ResetPassword = () => {

    const dispatch = useDispatch();

    const mobileNo = localStorage.getItem("mobileNo") || null;

    const initialValues = {
        password: '',
        reEnterPassword: '',
    }

    const validationSchema = yup.object({
        password: yup.string()
            .required("Please enter a password")
            .min(8, "Please enter at least 8 characters"),
        reEnterPassword: yup.string().required('Please re enter password').oneOf([yup.ref('password'), null], 'Password does not matched'),
    })

    const { values, errors, handleSubmit, handleChange } = useFormik({
        initialValues,
        validationSchema,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async () => {
            try {
                if (mobileNo !== null) {
                    dispatch(setLoading(true));
                    const res = await api.post("/resetPassword", { password: encrypt(values.password), mobileNo });
                    if (res.data.mes === "FOUND") {
                        dispatch(setLoading(false));
                        localStorage.removeItem("mobileNo");
                        toast.success(res.data.message);
                      setTimeout(() => {
                    dispatch(showAuth(false));  
                }, 2000);
            }
                else if (res.data.mes === "Password Matched") {
                    dispatch(setLoading(false));
                    localStorage.removeItem("mobileNo");
                    localStorage.removeItem("password");
                    toast.error(res.data.message);
                    showAuth(false);        
                    } else {
                        dispatch(setLoading(false));
                        localStorage.removeItem("mobileNo");
                        toast.error(res.data.message);
                        dispatch(showAuth(false));
                    }
                } else {
                    toast.error("There was an issue, Please try again later");
                }
            } catch (error) {
                dispatch(setLoading(false));
                toast.error("An error occurred, Please try again later");
            }
        }
    });
    const handleEnterKeyPress = (event) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    };

    const handleclose = ()=>{
        dispatch(showAuth(false))
        dispatch(authPage("login"))
    }

    return (
        <>
            <div className='auth'>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                    <button
                        style={{
                            cursor: "pointer",
                            fontSize: "20px",
                            width: "31px",
                            padding: "0px",
                            marginTop: "0px",
                            marginLeft: "auto", // Push the button to the right
                        }}
                        className="delete-cart"
                        onClick={handleclose}
                    >
                        <b>X</b>
                    </button>
                </div>
                <h2>Reset Password</h2>
                <p>Please enter your new password</p>
                <input type='password' placeholder='New Password' onChange={handleChange('password')} onKeyPress={handleEnterKeyPress} />
                {errors.password && <div className='err'>{errors.password}</div>}
                <input type='password' placeholder='Re-enter Password' onChange={handleChange('reEnterPassword')} onKeyPress={handleEnterKeyPress} />
                {errors.reEnterPassword && <div className='err'>{errors.reEnterPassword}</div>}
                <button onClick={handleSubmit}>Update</button>
            </div>
            <Toaster position="top-right" />
        </>
    );
}

export default ResetPassword;