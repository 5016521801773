import apiRequest from "../../../apiCall";

const callNewsLetterApi = async () => {
    return new Promise((resolve, reject) => {
        apiRequest('GET', 'newsletter', null, true, true).then(res => {
          sessionStorage.setItem("newsletterApi", JSON.stringify(res));
          resolve(res);
        
        })
            .catch(error => {
                console.error('Error calling home API:', error);
                reject(error);
            });
    });
}

export default callNewsLetterApi;