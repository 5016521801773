import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import api from "../../api";
import toast, { Toaster } from "react-hot-toast";
import "react-phone-number-input/style.css"; // Import the CSS for styling
import PhoneInput from "react-phone-number-input";
import "../../css/contactus.css";
import Loader from "../../pages/Loader";
import {  setLoading } from "../../redux/slices";

const Form = () => {

  const dispatch = useDispatch();

  const [notification, setNotification] = useState(null);
  const errorsz = () => toast.success("Thanks for contacting us, We will revert back shortly.");
  const errorac = () => toast.error("There is a problem. Try after sometime.");

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Please enter your first name"),
    lastName: Yup.string().required("Please enter your last name"),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Please enter your email"),
    mobileNumber: Yup.string()
      .required("Please enter your mobile number")
      .matches(/^\+[0-9]{1,3}[0-9]{4,14}$/, "Invalid mobile Number")
      .min(13, "Mobile number is too short")
      .max(17, "Mobile number is too long"),
    subject: Yup.string().required("Please enter subject"),
    message: Yup.string()
      .required("Please enter message")
      .min(10, "Message is too short"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      subject: "",
      message: "",
      website: "2",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("content", JSON.stringify(values));
      try {
        dispatch(setLoading(true));
        const res = await api.post("/sitecontactus", formData);
        if (res.status === 200) {
          dispatch(setLoading(false));
          errorsz();
          resetForm();
        }
      } catch (error) {
        dispatch(setLoading(false));
        errorac();
      }
    },
  });

  const { values, errors, handleChange, handleSubmit } = formik;

  return (
    <>
      <div className="form-section">
        <div className="row">
          <input
            id="firstName"
            type="text"
            placeholder="First name"
            name="firstName"
            onChange={handleChange}
            value={values.firstName}
            maxLength={30}
          />
          {errors.firstName && <div className="err">{errors.firstName}</div>}
        </div>
        <div className="row">
          <input
            id="lastName"
            type="text"
            placeholder="Last name"
            name="lastName"
            onChange={handleChange}
            value={values.lastName}
            maxLength={30}
          />
          {errors.lastName && <div className="err">{errors.lastName}</div>}
        </div>
      </div>
      <div className="form-section">
        <div className="row">
          <input
            id="email"
            type="email"
            placeholder="Email"
            name="email"
            onChange={handleChange}
            value={values.email}
            maxLength={30}
          />
          {errors.email && <div className="err">{errors.email}</div>}
        </div>
        <div className="row">
          <PhoneInput
            id="mobileNumber"
            placeholder="Mobile number"
            name="mobileNumber"
            onChange={(value) => formik.setFieldValue("mobileNumber", value)}
            value={formik.values.mobileNumber}
            maxLength={15}
          />
          {formik.errors.mobileNumber && (
            <div className="err">{formik.errors.mobileNumber}</div>
          )}
        </div>
      </div>
      <div className="form-section">
        <div className="row">
          <input
            id="subject"
            type="text"
            placeholder="Subject"
            name="subject"
            onChange={handleChange}
            value={values.subject}
            maxLength={50}
          />
          {errors.subject && <div className="err">{errors.subject}</div>}
        </div>
      </div>
      <div className="form-section">
        <div className="row">
          <textarea
            id="message"
            placeholder="Message"
            name="message"
            onChange={handleChange}
            value={values.message}
            maxLength={300}
          />
          {errors.message && <div className="err">{errors.message}</div>}
        </div>
      </div>
      {notification && <div className="notification">{notification}</div>}
      <div className="form-section">
        <button type="submit" onClick={handleSubmit}>
          Submit
        </button>
      </div>
      <Toaster position="top-right" />
    </>
  );
};
export default Form;
