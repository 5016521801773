import React, { useState } from "react";

import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import api from "../../api";
import toast, { Toaster } from "react-hot-toast";
import { setLoading } from "../../redux/slices";
import { showAuth, authPage } from "../../redux/slices";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const [showSendOTP, setShowSendOTP] = useState(false);

  const errorsz = () => toast.error("Internal Sever Error, Please try again later.");

  const initialValues = {
    mobileNo: "",
    OTP: "",
  };

  const validationSchema = yup.object({
    mobileNo: yup
      .string()
      .required("Please enter Mobile Number")
      .min(10, "Mobile Number shuold be minimum 10 digit .")
      .matches(phoneRegExp, "Phone number is not valid"),
    OTP: yup.string().when('showSendOTP', {
      is: true,
      then: yup.string().required("Please enter OTP"),
    }),
  });

  const { values, errors, handleSubmit, handleChange, setFieldValue } = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async () => {
      try {
        dispatch(setLoading(true));
        const res = await api.post("/checkEmail", { mobileNo: values.mobileNo });
        if (res.data.mes === "FOUND") {
          setShowSendOTP(true);
          dispatch(setLoading(false));
          toast.success(res.data.message);
        } else {
          dispatch(setLoading(false));
          toast.error(res.data.message);
        }
      } catch (error) {
        toast.error("An error occurred while checking email.");
      }
    },
  });

  const handleOTPSubmit = async () => {
    try {
      dispatch(setLoading(true));
      const otpRes = await api.post("/validateOTP", {
        mobile: values.mobileNo,
        OTP: values.OTP,
      });

      dispatch(setLoading(false));
      if (otpRes.data.mes === "FOUND") {
        dispatch(setLoading(false));
        localStorage.setItem("mobileNo", values.mobileNo);
        dispatch(authPage(""));
      } else {
        dispatch(setLoading(false));
        toast.error(otpRes.data.message);
      }
    } catch (error) {
      dispatch(setLoading(false));
      errorsz();
      console.log("Error validating OTP:", error);
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      if (!showSendOTP) {
        handleSubmit();
      } else {
        handleOTPSubmit();
      }
    }
  };

  const handleClose = () => {
    dispatch(showAuth(false));
    dispatch(authPage("login"))
  }

  return (
    <>
      {!showSendOTP ? (
        <div className="auth">
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <button
              style={{
                cursor: "pointer",
                fontSize: "20px",
                width: "31px",
                padding: "0px",
                marginTop: "0px",
                marginLeft: "auto", // Push the button to the right
              }}
              className="delete-cart"
              onClick={handleClose}
            >
              <b>X</b>
            </button>
          </div>
          <h2>Forgot Password</h2>
          <p> Please enter your mobile number</p>
          <input
            type="text"
            placeholder="Mobile Number"
            onChange={(e) => handleChange("mobileNo")(e)}
            onKeyPress={handleEnterKeyPress}
          />
          {errors.mobileNo && <div className="err">{errors.mobileNo}</div>}
          <button onClick={handleSubmit}>SEND OTP</button>
        </div>
      ) : (
        <div className="auth">
          <p> Please enter your OTP</p>
          <input
            type="number"
            placeholder="OTP"
            onChange={(e) => setFieldValue("OTP", e.target.value)}
            onKeyPress={handleEnterKeyPress}
          />
          {errors.OTP && <div className="err">{errors.OTP}</div>}
          <button onClick={handleOTPSubmit}>Submit</button>
        </div>
      )}
      <Toaster position="top-right" />
    </>
  );
};

export default ForgotPassword;
