import React, { useEffect, useState } from 'react';
import SideBar from '../components/sideBar';
import callShopCommentApi from '../callApi/shopcomment';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/slices';

const ShopComment = () => {
  const [shopCommentData, setShopCommentData] = useState([]);
   const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLoading(true));
    callShopCommentApi()
    .then( res=>{
      dispatch(setLoading(false));
      setShopCommentData(res);
    })
  }, []);

  // const callApi = async () => {
  //   return new Promise((resolve, reject) => {
  //     apiRequest('GET', 'shopcomment', null, true, true).then(res => {
  //       sessionStorage.setItem("shopcommentApi", JSON.stringify(res));
  //       resolve(res.data);
  //       setShopCommentData(res.data);
  //     })
  //         .catch(error => {
  //             console.error('Error calling home API:', error);
  //             reject(error);
  //         });
  // });
  // };

  return (
    <div className="page contact-us">
      <SideBar />
      <div className='container'>
        <h2>Shop Comments</h2>
        <br />
        <table className='card' style={{overflowX:"auto"}}>
          <thead>
            <tr>
              <th>User Name</th>
              <th>Product Name</th>
              <th>Review</th>
              <th>Rating</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(shopCommentData) && shopCommentData.length > 0 ? (
              shopCommentData.map((comment) => (
                <tr key={comment.ID}>
                  <td>{comment.FirstName} {comment.LastName}</td>
                  <td>{comment.ProductID}</td>
                  <td>{comment.Review}</td>
                  <td>{comment.Rating}</td>
                  <td>{comment.Date}</td>
                  <td>{comment.Status}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No Record Found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ShopComment;
