import React, { useEffect, useState } from "react";
import SideBar from "../components/sideBar";
import api from '../../../api';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import MyDatePicker from "./select";
// import PickerWithState from "./select";
// import { DatePicker, Space } from "antd";

function SelectLabels() {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 120 }}>
        <Select
          value={age}
          onChange={handleChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value={0}>successfull</MenuItem>
          <MenuItem value={1}>Pending</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

function Orderselect() {
  const [order, setOrder] = useState([]);
  const [isclicked, setClicked] = useState(false);

  const onChange = (date, dateString) => {
  };

  useEffect(() => {
    api.get(`/orderDetails`).then((response) => {
      setOrder(response.data);
    });
  }, []);

  const renderRows = () => {
    const groupedData = {};
    order &&
      order.forEach((item) => {
        const { username, orderDate, orderId, ...rest } = item;
        const key = `${username}-${orderDate}-${orderId}`;
        if (groupedData[key]) {
          groupedData[key].data.push(rest);
        } else {
          groupedData[key] = {
            username,
            orderDate,
            orderId,
            data: [rest],
          };
        }
      });

    return Object.values(groupedData).map(
      ({ username, orderDate, orderId, data }) => (
        <React.Fragment key={`${username}-${orderDate}-${orderId}`}>
          <tr>
            <td rowSpan={data.length}>{username}</td>
            <td rowSpan={data.length}>{orderDate}</td>
            <td rowSpan={data.length}>{orderId}</td>
            <td>{data[0].productname}</td>
            <td>{data[0].quantity}</td>
            <td>{data[0].total_price}</td>
            <td>{data[0].chemistname}</td>
          </tr>
          {data.slice(1).map((row, index) => (
            <tr key={`${username}-${orderDate}-${orderId}-${index}`}>
              <td>{row.productname}</td>
              <td>{row.quantity}</td>
              <td>{row.total_price}</td>
              <td>{row.chemistname}</td>
            </tr>
          ))}
        </React.Fragment>
      )
    );
  };

  return (
    <>
      <div className="page contact-us">
        <SideBar />
        <div className="container">
          <h5>Keshroot Orders</h5>
          <br />
          <div>
            <p>Order status</p>
            <SelectLabels />
            {/* <DatePicker onChange={onChange} />
            <DatePicker onChange={onChange} /> */}
          </div>
          <button>Export to Excel</button>
          {isclicked ? (
            <table className="card">
              <tbody>
                <tr>
                  <th>User name</th>
                  <th>Date</th>
                  <th>OrderID</th>
                  <th>Items</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Chemist</th>
                </tr>
                {renderRows()}
              </tbody>
            </table>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default Orderselect;
