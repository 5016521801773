import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from '../../../api';
import { encrypt } from "../crypto";


const Login = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const handleSubmit = async () => {
    if (!username || !password) {
      window.M.toast({ html: "Please fill in all values" });
    } else {
      try {
        const { data } = await api.post("/admin-user/", { username, password: encrypt(password) });
        if (data.status === 200) {
          localStorage.removeItem("userData");
          localStorage.removeItem("userToken");
          localStorage.setItem("adminData", JSON.stringify(data.data));
          localStorage.setItem("adminToken", data.data.token);
          // Redirect to the routes page and pass the user's role as a prop
          navigate("/admin/", { state: { role: data.data.role } });
          // window.location.reload();
        } else if (data.status === 500) {
          window.M.toast({ html: "User not found" });
        } else {
          window.M.toast({ html: "Wrong password" });
        }
      } catch (error) {
        console.error("Error logging in:", error);
        window.M.toast({ html: "An error occurred while logging in" });
      }
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(); // Call the handleSubmit function when Enter key is pressed
    }
  };

  return (
    <div className="login">
      <main className="auth">
        <h2>Admin Log in</h2>
        <p>Please enter your details</p>
        <input
          type="text"
          placeholder="Username"
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />
        <input
          type="password"
          placeholder="Password"
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        <button onClick={handleSubmit}>Log in</button>
      </main>
    </div>
  );
};

export default Login;
