import React, { useEffect, useState } from "react";
import { Link, useActionData, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const SideBar = () => {
  const [showMenu, setShowMenu] = useState(false);

  const location = useLocation();
  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };
  const navigate = useNavigate();

  const storedData = localStorage.getItem("adminData") || " {}";
  const adminData = JSON.parse(storedData);
  const role = adminData.role || null;

  useEffect(() => {
    const inputField = document.getElementsByClassName("input-field");

    for (let i = 0; i < inputField.length; i++) {
      const element = inputField[i].childNodes;
      if (element[0].value !== "") {
        if (element[1]) {
          element[1].classList.add("active");
        }
      }
    }
  });

  return (
    <div className="side-bar">
      <div className="links">
        {role === "Admin" || role === "SuperAdmin" ? (
          <>
          <button onClick={()=>navigate("/admin/")}  className={
                location.pathname === "/admin/" ? "nav-link active" : "nav-link"
              }> Home</button>
            {/* <Link
              to="/admin/"
              className={
                location.pathname === "/admin/" ? "nav-link active" : "nav-link"
              }
            >
              Home
            </Link> */}
            {/* <Link to="/shop">Shop</Link> */}
            <button onClick={()=>navigate("/admin/about-us")}  className={
                location.pathname === "/admin/about-us"
                  ? "nav-link active"
                  : "nav-link"
              }
            > About us</button>
            {/* <Link
              to="/admin/about-us"
              className={
                location.pathname === "/admin/about-us"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              About us
            </Link> */}

            <button onClick={()=> navigate("/admin/blog")}   className={
                location.pathname === "/admin/blog"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Blog</button>
            {/* <Link
              to="/admin/blog"
              className={
                location.pathname === "/admin/blog"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Blog
            </Link> */}
            <button onClick={()=>navigate("/admin/blogcomment")} className={
                location.pathname === "/admin/blogcomment"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Blog Comments</button>
            {/* <Link
              to="/admin/blogcomment"
              className={
                location.pathname === "/admin/blogcomment"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Blog Comments
            </Link> */}
            <button onClick={()=>navigate("/admin/blogcommentedit")}  className={
                location.pathname === "/admin/blogcommentedit"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Blog Comment Status</button>
            {/* <Link
              to="/admin/blogcommentedit"
              className={
                location.pathname === "/admin/blogcommentedit"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Blog Comment Status
            </Link> */}
            {/* <Link to="/admin/links" className={location.pathname === '/admin/links' ?  'nav-link active' : 'nav-link'}>Social Media</Link> */}
           <button onClick={()=>navigate("/admin/prods")} className={
                location.pathname === "/admin/prods"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Shop
              </button>
           
            {/* <Link
              to="/admin/prods"
              className={
                location.pathname === "/admin/prods"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Shop
            </Link> */}
<button onClick={()=>navigate("/admin/shopcomment")}  className={
                location.pathname === "/admin/shopcomment"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Shop Comments</button>

            {/* <Link
              to="/admin/shopcomment"
              className={
                location.pathname === "/admin/shopcomment"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Shop Comments
            </Link> */}

            <button onClick={()=>navigate("/admin/shopcommentstatus")} className={
                location.pathname === "/admin/shopcommentstatus"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Shop Comments Status </button>
            {/* <Link
              to="/admin/shopcommentstatus"
              className={
                location.pathname === "/admin/shopcommentstatus"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Shop Comments Status
            </Link> */}

            <button onClick={()=>navigate("/admin/contactus")} className={
                location.pathname === "/admin/contactus"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Contact Us</button>
            {/* <Link
              to="/admin/contactus"
              className={
                location.pathname === "/admin/contactus"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Contact Us
            </Link> */}
            <button onClick={()=>navigate("/admin/newsletter")} className={
                location.pathname === "/admin/newsletter"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              News Letter</button>
            {/* <Link
              to="/admin/newsletter"
              className={
                location.pathname === "/admin/newsletter"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              News Letter
            </Link> */}
            {/* <Link
              to="/admin/enquiry"
              className={
                location.pathname === "/admin/enquiry"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Enquiry
            </Link> */}
            {/* <Link to="/admin/signup" className={location.pathname === '/admin/signup' ?  'nav-link active' : 'nav-link'}>Customer Info</Link> */}
            {/* <Link to="/order">Order Details</Link> */}
            {/* <Link to="/orderselect">Order select</Link> */}
            {role === "SuperAdmin" ? (
              <>
              <button onClick={()=>navigate("/admin/admin-users")} className={
                    location.pathname === "/admin/admin-users"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  Admin users</button>
                {/* <Link
                  to="/admin/admin-users"
                  className={
                    location.pathname === "/admin/admin-users"
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  Admin users
                </Link> */}
              </>
            ) : (
              <> </>
            )}
            <button onClick={()=>navigate("/admin/logout")} className={
                location.pathname === "/admin/logout"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Log out </button>
            {/* <Link
              to="/admin/logout"
              className={
                location.pathname === "/admin/logout"
                  ? "nav-link active"
                  : "nav-link"
              }
            >
              Log out
            </Link> */}
            {/* <Link to="/change-password">Change password</Link> */}
          </>
        ) : role == "Blogger" ? (
          <>
           <button onClick={()=>navigate("/admin/blog")}>Blog </button>
           <button onClick={()=>navigate("/admin/blogcomment")}>Blog Comments</button>
           <button onClick={()=>navigate("/admin/logout")}>Log out</button>
            {/* <Link to="/admin/blog">Blog</Link>
            <Link to="/admin/blogcomment">Blog Comments</Link>
            <Link to="/admin/logout">Log out</Link> */}
          </>
        ) : (
          <button onClick={()=>navigate("/admin/logout")}>Log out</button>
        )}
      </div>
    </div>
  );
};

export default SideBar;
