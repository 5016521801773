import { configureStore } from "@reduxjs/toolkit";

import { homeApiReducer, 
    blogcommentApiReducer, 
    aboutApiReducer,
    socialMediaApiReducer, 
    shopApiReducer, 
    contactApiReducer , 
    blogApiReducer, 
    authReducer, 
    authPageReducer, 
    showCartReducer,
    confirmationReducer,
    cartReducer,
    loadingReducer,
    cartUpdateReducer
 } from "./slices";

export default configureStore({
    reducer: {
        homeApi: homeApiReducer,
        aboutApi: aboutApiReducer,
        socialMediaApi: socialMediaApiReducer,
        contactApi: contactApiReducer,
        blogcommentApi: blogcommentApiReducer,
        showAuth: authReducer,
        authPage: authPageReducer,
        showCart: showCartReducer,
        blogApi: blogApiReducer,
        shopApi: shopApiReducer,
        confirmation: confirmationReducer,
        cart: cartReducer, 
        loading: loadingReducer,
        cartUpdate:cartUpdateReducer
    }
})