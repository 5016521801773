import apiRequest from "./apiCall";

const fileUpload = (data) => {
    return new Promise((resolve, reject) => {
        const params = data?.map((x, index) => {
            return { type: x.file.name.split(".")[1], index }
        })
        apiRequest('POST', 'getawsfilename', { images: params, screen: "keshroot" }, true, true).then(async (res) => {
            const responses = await Promise.all(res?.imageUrls?.map(async (x, index) => {
                const fileUploadResult = await fetch(x.url, {
                    method: 'PUT',
                    body: data[x.index].file
                });
                const { ok = false, status = null } = fileUploadResult;
                if (ok && status == "200") {
                    return { key: x.Key, index: x.index,fileName: data[x.index].file.name };
                }
            }));
            if (data.length == responses.length) {
                resolve(responses);
            } else {
                reject();
            }
        })
            .catch(error => {
                // console.log("Error sending slider Data", error);
                reject(error);
            })
    });
}

export { fileUpload };
