import React, { useEffect, useState } from "react";
import { Provider as ReduxProvider, useSelector } from "react-redux";

import Navigation from "./navigation";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import "./materialize.min.css";

import { selectLoading } from "./redux/slices";
import Loader from "./pages/Loader";

const App = () => {
  window.onscroll = () => {
    const scrollTop =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop;
    if (scrollTop >= 400) {
      document.querySelector(".nav-bar").classList.add("fixed-nav");
      document.querySelector(".mobile-nav").classList.add("fixed-mobile-nav");
    } else {
      document.querySelector(".nav-bar").classList.remove("fixed-nav");
      document
        .querySelector(".mobile-nav")
        .classList.remove("fixed-mobile-nav");
    }
  };

  return (
    <ReduxProvider store={store}>
      <AppContainer />
    </ReduxProvider>
  );
};

const AppContainer = () => {
  const loading = useSelector(selectLoading);
  // console.log("hello",loading)
  return (
    <Router>
      {loading && <Loader />}
      <Navigation />
    </Router>
  );
};

export default App;
