import apiRequest from "../../../apiCall";


const callCreateUserApi = async (id) => {
    return new Promise((resolve, reject) => {
      apiRequest('GET', `get-user/${id}`, null, true, true).then(res => {
        sessionStorage.setItem("createUserApi", JSON.stringify(res));
        resolve(res);
      
      })
          .catch(error => {
              console.error('Error calling home API:', error);
              reject(error);
          });
  });

}


// const callHandleUserApi = async (id) => {
//     return new Promise((resolve, reject)=>{
//         if (!id){
//             apiRequest('POST','create-user',null, true, true).then(res=>{
//                 sessionStorage.setItem("createuser", JSON.stringify(res));
//                 resolve(res);
//             })
//         }
//         else {
//             apiRequest('POST', 'update-admin-user', data, true, true).then(res=>{
//                 sessionStorage.setItem("updateUser", JSON.stringify(res));
//                 resolve(res);
//             })

//         }
//     })

// }


export const AdmincreateUser = async (userdata) => {
    return new Promise((resolve, reject) => {
        apiRequest('POST', 'create-user', userdata,true, true).then(res => {
          sessionStorage.setItem("createUser", JSON.stringify(res));
          resolve(res);
        
        })
            .catch(error => {
                console.error('Error calling home API:', error);
                reject(error);
            });
    });
}


export const AdminupdateUser = async (userdata) => {
    return new Promise((resolve, reject) => {
        apiRequest('POST', 'update-admin-user', userdata,true, true).then(res => {
          sessionStorage.setItem("updateuser", JSON.stringify(res));
          resolve(res);
        
        })
            .catch(error => {
                console.error('Error calling home API:', error);
                reject(error);
            });
    });
}

export default callCreateUserApi;
// export {AddnewUser, AdminupdateUser};