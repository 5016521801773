import React from "react";

const Offer = () => {
  return (
    // <div className="top-offer">
    //   Yay!! Get 10% off on your first order, Use Code: KESHROOT10
    // </div>
    <></>
  );
};

export default Offer;
