// import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation, Pagination, Autoplay } from 'swiper';

// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/autoplay';

// const Slider = () => {

//     const [images, setImages] = useState([]);

//     const homeApi = useSelector(state => state.homeApi.value);
//     console.log(homeApi);

//     useEffect(() => {
//         if (homeApi) {
//             const imgs = [];
//             homeApi.slider.slider.forEach((item, index) => {
//                 if (index !== 0) {
//                     imgs.push({ url: item });
//                 }
//             });
//             setImages(imgs);
//         };
//     }, [homeApi]);
//     console.clear();
// console.log("result",images)

//     useEffect(() => {
//         if (images.length > 0) {
//             changeSliderHeight();
//         }
//     }, [images]);

//     const changeSliderHeight = () => {
//         const slider = document.querySelector('.slider');
//         slider.style.height = (slider.offsetWidth / 2.3) + 'px';
//     }

//     const getExtension = (filename) => {
//         var parts = filename.split('.');
//         return parts[parts.length - 1];
//     }

//     const isVideo = (url) => {
//         var ext = getExtension(url);
//         switch (ext.toLowerCase()) {
//             case 'm4v':
//             case 'avi':
//             case 'mpg':
//             case 'mp4':
//                 // etc
//                 return true;
//         }
//         return false;
//     };

//     window.onresize = () => {
//         changeSliderHeight();
//     };

//     return (images.length > 0 ? (
//         <Swiper
//             spaceBetween={50}
//             slidesPerView={1}
//             navigation
//             controller
//             modules={[Navigation, Pagination, Autoplay]}
//             pagination={{ clickable: true }}
//             autoplay
//             className='slider'
//             rewind
//         >
//             {images.map(item => (
//                 <SwiperSlide>
//                     {isVideo(item.url) ? <div className='video'><video muted autoPlay loop><source src={item.url} ></source></video></div> : <div className='slider-item' style={{ background: `url(${item.url})` }} />}
//                 </SwiperSlide>
//             ))}
//         </Swiper>
//     ) : <></>);
// }

// export default Slider;

// import React, { useEffect, useState } from 'react';
// import { useSelector } from 'react-redux';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation, Pagination, Autoplay } from 'swiper';

// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/autoplay';

// const Slider = () => {
//   const [images, setImages] = useState([]);

//   const homeApi = useSelector(state => state.homeApi.value);
//   useEffect(() => {
//     if (homeApi) {
//       const imgs = homeApi.slider.slider.map(item => ({ url: item.image }));
//       setImages(imgs);
//     }
//   }, [homeApi]);



// //   useEffect(() => {
// //     if (homeApi) {
// //       const imgs = [];
// //       homeApi.slider.slider.forEach((item, index) => {
// //         if (index !== 0) {
// //           imgs.push({ url: item });
// //         }
// //       });
// //       setImages(imgs);
// //     }
// //   }, [homeApi]);

//   useEffect(() => {
//     if (images.length > 0) {
//       changeSliderHeight();
//     }
//   }, [images]);

//   console.clear();
//   console.log(images)

//   const changeSliderHeight = () => {
//     const slider = document.querySelector('.slider');
//     slider.style.height = slider.offsetWidth / 2.3 + 'px';
//   };

//   const getExtension = filename => {
//     var parts = filename.split('.');
//     return parts[parts.length - 1];
//   };

//   const isVideo = url => {
//     var ext = getExtension(url);
//     switch (ext.toLowerCase()) {
//       case 'm4v':
//       case 'avi':
//       case 'mpg':
//       case 'mp4':
//         return true;
//     }
//     return false;
//   };

//   window.onresize = () => {
//     changeSliderHeight();
//   };

//   return images.length > 0 &&  ? (
//     <Swiper
//       spaceBetween={50}
//       slidesPerView={1}
//       navigation
//       controller
//       modules={[Navigation, Pagination, Autoplay]}
//       pagination={{ clickable: true }}
//       autoplay
//       className='slider'
//       rewind
//     >
//       {images.map((item, index) => (
//         <SwiperSlide key={index}>
//           {isVideo(item.url) ? (
//             <div className='video'>
//               <video muted autoPlay loop>
//                 <source src={item.url} />
//               </video>
//             </div>
//           ) : (
//             <div
//               className='slider-item'
//               style={{ background: `url(${item.url})` }}
//             />
//           )}
//         </SwiperSlide>
//       ))}
//     </Swiper>
//   ) : (
//     <></>
//   );
// };

// export default Slider;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import config from "../../pages/Admin/Config.json";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

const Slider = () => {
  const [images, setImages] = useState([]);
  const [showSlider, setShowSlider] = useState(0);

  const homeApi = useSelector(state => state.homeApi.value);

  useEffect(() => {
    if (homeApi) {
      const imgs = homeApi.slider.slider ? homeApi.slider.slider?.map(item => ({ url: config.awsMediaPath + item })) : [];
      // console.clear();
      setImages(imgs);
      setShowSlider(homeApi.slider.showinwebsite === 1); // Update showSlider based on the value of showinwebsite
    }
  }, [homeApi]);

  useEffect(() => {

    if (images.length > 0) {
      changeSliderHeight();
    }
  }, [images]);

  const changeSliderHeight = () => {
    const slider = document.querySelector('.slider');
    slider.style.height = slider.offsetWidth / 2.3 + 'px';
  };

  const getExtension = filename => {
    if (typeof filename === 'string') {
      const parts = filename.split('.');
      return parts[parts.length - 1];
    }
    // Handle the case where filename is not a string, e.g., null or undefined.
    return null; // Or you can return an appropriate value or throw an error.
  };


  const isVideo = url => {
    var ext = getExtension(url);


    if (ext) {
      switch (ext.toLowerCase()) {
        case 'm4v':
        case 'avi':
        case 'mpg':
        case 'mp4':
          return true;
      }
    }
    return false;
  };


  window.onresize = () => {
    changeSliderHeight();
  };

  return (
    <>
      {showSlider ? (
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          navigation
          controller
          modules={[Navigation, Pagination, Autoplay]}
          pagination={{ clickable: true }}
          autoplay={{ delay: 20000 }}
          className='slider'
          rewind
        >
          {images.map((item, index) => {
            return <SwiperSlide key={index}>
              {isVideo(item.url) ? (
                <div className='video'>
                  <video muted autoPlay loop>
                    <source src={item.url} />
                  </video>
                </div>
              ) : (
                <div
                  className='slider-item'
                  style={{ backgroundImage: `url('${item.url}')` }}
                />
              )}
            </SwiperSlide>

          })}
        </Swiper>
      ) : null}
    </>
  );
};

export default Slider;
