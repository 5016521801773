import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import Footer from "../../components/footer";
import Header from "../../components/header";
import "../../css/aboutus.css";
import { fetchAboutApi } from "../../redux/slices";
import config from "../Admin/Config.json";


const AboutUs = () => {

  document.title = "About us - keshroot";

  const dispatch = useDispatch();
  const data = useSelector((state) => state.aboutApi.value);

  useEffect(() => {
    dispatch(fetchAboutApi());
  
  },[] );
 
  // console.log(data);
  return (
    <>
      {data ? (
        <div className="about-us">
          <Header activepage="about-us" />
          <div className="about_us_feature">
          <img
            src={config.awsMediaPath + data.media.featureImage}
            className="feature"
          /></div>

          <div className="about-section">
            <div className="about_us_Section_image" style={{width:'100%', height:'200px', marginRight:'20px'}}>
            <img src={config.awsMediaPath + data.media.featureImage1} /></div>
            <p> {ReactHtmlParser(data.sectionAHtmlContent)}</p>
          </div>

          <div className="about-text-conteznt">
            <div className="about-text-data">
              {ReactHtmlParser(data.content)}
            </div>
          </div>

          <video className="about-video" controls>
            <source
              src={config.awsMediaPath + data.media.video}
              type="video/mp4"
            />
          </video>
          <Footer />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AboutUs;
