import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import config from "../../pages/Admin/Config.json";

const SedUt = () => {
  const [data, setData] = useState(null);

  const reduxdata = useSelector((state) => state.homeApi.value);

  useEffect(() => {
    if (reduxdata) {
      setData(reduxdata.sectionC);
    }
  }, [reduxdata]);

  return data && data.showinwebsite ? (
    <div className="sedut">
      <div className="ls">
        <h2>{data.heading}</h2>
        {ReactHtmlParser(data.content)}
        {/* <div className='images'>
                    <img src={icons.purity} />
                    <img src={icons.ethically} />
                    <img src={icons.natural} />
                    <img src={icons.authentically} />
                </div> */}
      </div>
      <div className="rs">
        <video controls>
          <source src={config.awsMediaPath + data.media} type="video/mp4" />
        </video>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SedUt;
